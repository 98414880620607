import { all, call, fork, take, put, } from 'redux-saga/effects';

import API from 'src/service/api';
import { getApiErrorText, } from 'src/helpers/errors-api';

import { AppActions, } from '../../actions';

import actions from './actions';


function* sagaFetchEvents() {
  while (true) {
    const { payload } = yield take(actions.fetchEvents);

    let rs = yield call(API.fetchEvTruckEvents, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
      yield put(actions.fetchEventsError());
    }
    else {
      yield put(actions.fetchEventsSuccess(rs.data));
    }
  }
}

function* sagaFetchParams() {
  while (true) {
    const { payload } = yield take(actions.fetchParams);

    let rs = yield call(API.fetchEvTruckParams, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
      yield put(actions.fetchParamsError());
    }
    else {
      yield put(actions.fetchParamsSuccess(rs.data));
    }
  }
}

const sagas = [
  sagaFetchEvents,
  sagaFetchParams,
];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
