import _ from 'lodash';
import { createActions } from 'redux-actions';

import API from 'src/service/api';

import { createSuccessErrorActions } from '../helpers';

let asyncActions = {
  'FETCH_SUBTRUCK_TRANSACTIONS': 'fetchSubtruckTransactions',
  'FETCH_SUBTRUCK_FAILED_TRANSACTIONS': 'fetchSubtruckFailedTransactions',
};

asyncActions = _.mapValues(asyncActions, (item) => (
  async (...args) => {
    const rs = await API[item](...args);
    if (rs instanceof Error) throw rs;
    return rs;
  }
));

export default createActions(

  { ...asyncActions },

  ...createSuccessErrorActions('FETCH_SUBTRUCK_TRANSACTION'),
  'CLEAR_SUBTRUCK_TRANSACTION',

  ...createSuccessErrorActions('FETCH_SUBTRUCK_TRANSACTION_EVENTS'),
  'CLEAR_SUBTRUCK_TRANSACTION_EVENTS',

  ...createSuccessErrorActions('FETCH_SUBTRUCK_TRANSACTION_EVENT'),
  'CLEAR_SUBTRUCK_TRANSACTION_EVENT',

  ...createSuccessErrorActions('SEND_SUBTRUCK_FACEBOOK_PIXEL_TEST_EVENT'),
  ...createSuccessErrorActions('SEND_SUBTRUCK_SNAPCHAT_PIXEL_TEST_EVENT'),

);
