import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';

import { AuthActions } from 'src/redux/actions';

import { Divider, Avatar, List, Menu, Popover, } from 'src/components/ui';

import TopbarDropdownWrapper from './topbarDropdown.style';


const { logout } = AuthActions;

class TopbarUser extends Component {

  state = {
    visible: false
  }

  handleVisibleChange = () => {
    this.setState({ visible: !this.state.visible });
  }

  handleNav = () => {
    setTimeout(() => {
      this.handleVisibleChange(false);
    }, 320);
  }

  handleLogoutClick = (e) => {
    e.preventDefault();
    this.props.logout();
  }

  render() {
    const { user, app, } = this.props;

    const content = (
      <TopbarDropdownWrapper
        style={{ width: 280 }}
      >
        <div style={{ padding: 16, }}>
          <List.Item.Meta
            avatar={<Avatar size={40} src={user.avatar} icon={!user.avatar ? <UserOutlined /> : null} />}
            title={user.name}
            description={
              <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: 200, }}>
                <span title={user.email}>{user.email}</span>
              </div>
            }
          />
        </div>
        <Divider style={{ margin: '4px 0 0 0' }} />
        <Menu
          onClick={this.handleClick}
          mode={'inline'}
          selectable={false}
          selectedKeys={app.current}
          inlineIndent={28}
          onOpenChange={this.onOpenChange}
        >
          <Menu.Item key="profile">
            <Link
              to={`/profile`}
              onClick={this.handleNav}
            >My Profile</Link>
          </Menu.Item>
          <Menu.Item key="companies">
            <Link
              to={`/companies`}
              onClick={this.handleNav}
            >My Companies</Link>
          </Menu.Item>
          <Menu.Item key="logout">
            <Link
              to={'/logout'}
              onClick={this.handleLogoutClick}
            >Log Out</Link>
          </Menu.Item>
        </Menu>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <Avatar size={40} src={user.avatar} icon={!user.avatar ? <UserOutlined /> : null} />
          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}
export default connect(
  state => ({
    user: state.auth.user,
    app: state.app,
  }),
  { logout }
)(TopbarUser);
