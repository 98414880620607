import ChartsActions from './charts/actions';
import DashboardsActions from './dashboards/actions';
import EventsActions from './events/actions';
import SegmentsActions from './segments/actions';

export default {
  ChartsActions,
  DashboardsActions,
  EventsActions,
  SegmentsActions,
}
