import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose, withHandlers, withState, withProps, } from 'recompose';

import { mapActions, AuthActions, } from 'src/redux/actions';

import { Select, SelectMultiple, Button, Divider, Row, } from 'src/components/ui';

import { serializeCompany } from 'src/redux/auth/utils';
import { DownOutlined } from '@ant-design/icons';


export default compose(
  withRouter,
  connect(
    state => ({
      companies: _.map(state.auth.companies, 'id'),
      options: state.magnus.companies,
    }),
    mapActions([
      'changeCompany',
    ], AuthActions),
  ),
  withState('value', 'setValue', ({ companies }) => companies),
  withState('open', 'setOpen', false),
  withProps(({
    value,
    options,
  }) => ({
    label: value.length ? _.map(value, i => _.find(options, { id: i })?.name).join(' & ') : 'All Companies',
  })),
  withHandlers({
    handleOpenClick:
      ({
        setOpen,
      }) =>
        () => {
          setOpen(true);
        },
    handleChange:
      ({
        setValue,
      }) =>
        (value) => {
          setValue(value);
        },
    handleApplyClick:
      ({
        location,
        value,
        companies,
        changeCompany,
      }) =>
        () => {
          if (!_.isEqual(value, companies)) {
            const arr = location.pathname.split('/');
            if (arr[1] === serializeCompany(companies)) {
              arr[1] = serializeCompany(value);
              window.location = arr.join('/');
            }
            else {
              changeCompany({ companies: value, });
            }
          }
        },
    handleBlur:
      ({
        handleApplyClick,
      }) =>
        () => {
          handleApplyClick();
        },
    handleDropdownVisibleChange:
      ({
        setOpen,
      }) =>
        (open) => {
          setOpen(open);
        },
  })
)(({
  value,
  label,
  open,
  options,
  handleOpenClick,
  handleChange,
  handleBlur,
  handleDropdownVisibleChange,
  handleApplyClick,
}) => (
  <div style={{ padding: '16px 16px 0 16px', }}>
    {
      options.length === 1
        ? (
          <Select
            value={options[0].id}
            style={{ width: '100%', }}
          >
            <Select.Option value={options[0].id} key={options[0].id}>{options[0].name}</Select.Option>
          </Select>
        )
        : (
          <>
            <SelectMultiple
              open={open}
              value={value}
              mode={'multiple'}
              style={{ width: '100%', height: 0, }}
              onChange={handleChange}
              onBlur={handleBlur}
              onDropdownVisibleChange={handleDropdownVisibleChange}
              tagRender={({ label }) => label + ' &'}
              dropdownRender={(n) => (
                <>
                  {n}
                  <Divider style={{ margin: '4px 0', }} />
                  <Row justify={'end'}>
                    <Button
                      size={'small'}
                      onClick={handleApplyClick}
                    >
                      <span>Apply</span>
                    </Button>
                  </Row>
                </>
              )}
            >
              {(options || []).filter(i => i.status === 'active').map(item => (
                <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
              ))}
            </SelectMultiple>
            <Button
              style={{ width: '100%', position: 'relative', height: 'auto', whiteSpace: 'break-spaces', }}
              onClick={handleOpenClick}
            >
              <span style={{ paddingRight: 8 }}>{label}</span>
              <span
                style={{
                  position: 'absolute',
                  right: 4,
                  top: 0,
                  height: '100%',
                  display: 'flex',
                }}
              >
                <DownOutlined style={{ margin: 'auto', }} />
              </span>
            </Button>
          </>
        )
    }
  </div>
));
