import { handleActions } from 'redux-actions';

import actions from './actions';

const defaultState = {
  popup: null,
  publishers: [],
  linkedAccounts: [],
  adsAccounts: [],
};

export default handleActions(
  {
    [actions.fetchLinkedAccountsSuccess]: (state, { payload }) => ({ ...state, linkedAccounts: payload }),
    [actions.fetchAdsAccountsSuccess]: (state, { payload }) => ({ ...state, adsAccounts: payload }),
    [actions.fetchPublishersSuccess]: (state, { payload }) => ({ ...state, publishers: payload }),
    [actions.openPopup]: (state, { payload }) => ({ ...state, popup: payload }),
    [actions.clearData]: state => ({ ...state, linkedAccounts: defaultState.dlinkedAccountsata, adsAccounts: defaultState.adsAccounts }),
  },
  defaultState
)
