import { createActions, } from 'redux-actions';

import { createSuccessErrorActions, } from '../../helpers';

export default createActions(

  ...createSuccessErrorActions('FETCH_PLACEMENTS'),

  'CREATE_PLACEMENT',
  'UPDATE_PLACEMENT',
  'DELETE_PLACEMENT',

  { prefix: 'VARIATOR/PLACEMENTS', }

);
