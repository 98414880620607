import styled from 'styled-components';
import { compose, setStatic, } from 'recompose';
import { Tabs, } from 'antd';


export default compose(
  setStatic('TabPane', Tabs.TabPane),
)(styled(Tabs)`
  &.ant-tabs-top > .ant-tabs-nav {
    padding-left: 8px;
    &::before {
      border-bottom-color: #e8e8e8;
    }
  }
`);
