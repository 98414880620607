import _ from 'lodash';
import React from 'react';
import { compose, lifecycle, toClass, mapProps, withState, withProps, withHandlers, } from 'recompose';
import styled from 'styled-components';

import { Select, Button, Divider, Row, Col } from './';


const Styles = styled.div`
  display: flex;
  flex-direction: column;
`;

const S = withSelectFiltered()(Select);

export default compose(
  toClass,
  withMultipleModeProcessor(),
)(({ options, title, children, ...rest }) => {
  return (
    <Styles>
      {title && <span>{title}</span>}
      <S
        mode="tags"
        style={{ minWidth: '100px', width: '100%' }}
        placeholder="All"
        {...rest}
      >
        {options ? options.map(item => (
          <Select.Option
            key={item.key || item.value}
            value={item.value}
            name={item.name}
            label={item.label}
            disabled={item.disabled}
            title={item.label || item.name || item.value}
          >
            {item.label || item.name || item.value}
          </Select.Option>
        )) : children}
      </S>
    </Styles>
  );
});

export function withMultipleModeProcessor() {
  return lifecycle({
    componentDidUpdate(prev) {
      if (prev.options !== this.props.options) {
        if (this.props.mode === 'multiple') {
          if (this.props.value && this.props.value.length && this.props.options) {
            let v = this.props.value.filter(item => this.props.options.find(o => o === item || o.value === item));
            if (v.length !== this.props.value.length) {
              this.props.onChange(v);
            }
          }
        }
      }
    },
  });
}

function withSelectFiltered() {
  return compose(
    withState('sv', 'setSV'),
    withHandlers({
      handleSelectFilteredClick:
        ({
          sv,
          children,
          optionFilterProp,
          filterOption,
          value,
          onChange,
        }) =>
          () => {
            let f = filterOptions(sv, children, { optionFilterProp, filterOption, });
            let v = _.union(value, f);
            onChange && onChange(v);
          },
      handleUnselectFilteredClick:
        ({
          sv,
          children,
          optionFilterProp,
          filterOption,
          value,
          onChange,
        }) =>
          () => {
            let f = filterOptions(sv, children, { optionFilterProp, filterOption, });
            let v = _.difference(value, f);
            onChange && onChange(v);
          },
      handleSelectAllClick:
        ({
          children,
          optionFilterProp,
          filterOption,
          value,
          onChange,
        }) =>
          () => {
            let f = filterOptions('', children, { optionFilterProp, filterOption, });
            let v = _.union(value, f);
            onChange && onChange(v);
          },
    }),
    withProps(
      ({
        onBlur,
        onSearch,
        sv,
        setSV,
        dropdownRender,
        handleSelectFilteredClick,
        handleUnselectFilteredClick,
        handleSelectAllClick,
      }) => ({
        dropdownRender: (n) => (
          <div style={{ padding: '4px 8px 0', }}>
            {sv ?
              <>
                <Row gutter={8}>
                  <Col>
                    <Button size="small" onClick={handleSelectFilteredClick}>
                      <span>Select Filtered</span>
                    </Button>
                  </Col>
                  <Col>
                    <Button size="small" onClick={handleUnselectFilteredClick}>
                      <span>Unselect Filtered</span>
                    </Button>
                  </Col>
                </Row>
              </>
              :
              <Button size="small" onClick={handleSelectAllClick}>
                <span>Select All</span>
              </Button>
            }
            <Divider style={{ margin: '4px 0', }} />
            {dropdownRender ? dropdownRender(n) : n}
          </div>
        ),
        onSearch:
          (v) => {
            setSV(v);
            onSearch && onSearch(v);
          },
        onBlur:
          () => {
            onBlur && onBlur();
            setTimeout(() => {
              setSV('');
            }, 200);
          },
      }),
    ),
    mapProps(props => _.omit(props, 'sv', 'setSV', 'handleSelectFilteredClick', 'handleUnselectFilteredClick', 'handleSelectAllClick')),
  );
}

function filterOptions(sv, options, { optionFilterProp, filterOption, }) {
  options = _.isArray(options) ? options : [options];
  return _.reduce(options, (res, o) => {
    let r = [];

    if (o) {
      if (o.type === Select.OptGroup) {
        r = filterOptions(sv, o.props.children, { optionFilterProp, filterOption, });
      } else {
        if (typeof filterOption === 'function') {
          if (filterOption(sv, o)) {
            r = [o.props.value];
          }
        } else {
          if (String(o.props[optionFilterProp]).toLowerCase().includes(sv)) {
            r = [o.props.value];
          }
        }
      }
    }

    return [...res, ...r];
  }, []);
}
