import asyncComponent from 'src/helpers/asyncFunc';

export const modals = [
  {
    id: 'edit-mutator-parameter',
    title: 'Parameter',
    component: asyncComponent(_ => import('./config/parameters/edit')),
  },
  {
    id: 'edit-mutator-group',
    title: 'Group',
    component: asyncComponent(_ => import('./config/parameters/edit-group')),
  },
  {
    id: 'edit-mutator-condition',
    title: 'Condition',
    component: asyncComponent(_ => import('./config/conditions/edit')),
  },
  {
    id: 'publish-mutator-config',
    title: 'Publish changes',
    component: asyncComponent(_ => import('./config/control-panel/comment')),
  },
  {
    id: 'import-mutator-config-from-firebase',
    title: 'Import Config from Firebase',
    component: asyncComponent(_ => import('./config/import-firebase')),
  },
  {
    id: 'import-mutator-config-from-mutator',
    title: 'Import Config from Mutator',
    component: asyncComponent(_ => import('./config/import-mutator')),
  },
  {
    id: 'edit-mutator-experiment',
    title: 'Experiment',
    component: asyncComponent(_ => import('./experiments/edit')),
    preventCloseOnSubmit: true,
  },
];