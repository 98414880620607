import { handleActions } from 'redux-actions';

import actions from './actions';

const defaultState = {

  hideCreateAccountLink: false,
  serverMaintenance: false,
  jiraIssueCollectorHidden: false,

};

export default handleActions(
  {
    [actions.fetchSuccess]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        serverMaintenance: false,
      };
    },
  },
  defaultState
)
