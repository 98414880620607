import _ from 'lodash';
import uuid from 'uuid/v1';
import { handleActions } from 'redux-actions';

import actions from './actions';

const defaultState = {
  snapshot: null,
  version: null,
  groups: null,
  parameters: null,
  conditions: null,
  history: null,
};

export default handleActions(
  {
    [actions.fetchFullHistorySuccess]: (state, { payload, }) => ({ ...state, history: payload, }),

    [actions.fetchSuccess]: (state, { payload, }) => ({
      ...state,
      snapshot: payload.snapshot || null,
      version: payload.version || null,
      ...cloneSnapshot(payload.snapshot),
    }),
    [actions.fetchError]: () => defaultState,

    [actions.discard]: (state) => ({
      ...state,
      ...cloneSnapshot(state.snapshot),
    }),

    [actions.push]: (state, { payload, }) => ({
      ...state,
      groups: payload.groups,
      parameters: payload.parameters,
      conditions: payload.conditions,
    }),

    [actions.createGroup]: (state, { payload, }) => ({ ...state, groups: create(state.groups, payload, { id: uuid(), }), }),
    [actions.updateGroup]: (state, { payload, }) => ({ ...state, groups: update(state.groups, payload, { id: payload.id, }), }),
    [actions.deleteGroup]: (state, { payload, }) => ({ ...state, groups: remove(state.groups, { id: payload.id, }), }),

    [actions.createParameter]: (state, { payload, }) => ({ ...state, parameters: create(state.parameters, payload), }),
    [actions.updateParameter]: (state, { payload, }) => ({ ...state, parameters: update(state.parameters, payload, { key: payload.key, }), }),
    [actions.deleteParameter]: (state, { payload, }) => ({ ...state, parameters: remove(state.parameters, { key: payload.key, }), }),

    [actions.createCondition]: (state, { payload, }) => ({ ...state, conditions: create(state.conditions, payload, { id: uuid(), }), }),
    [actions.updateCondition]: (state, { payload, }) => ({ ...state, conditions: update(state.conditions, payload, { id: payload.id, }), }),
    [actions.updateConditions]: (state, { payload, }) => ({ ...state, conditions: [...payload], }),
    [actions.deleteCondition]: (state, { payload, }) => ({
      ...state,
      conditions: remove(state.conditions, { id: payload.id, }),
      parameters: _.map(state.parameters, p => ({ ...p, conditions: _.filter(p.conditions, c => c.condition_id !== payload.id) })),
    }),

    [actions.clear]: () => ({ ...defaultState }),
  },
  defaultState
)

function create(collection, item, keyobj) {
  Object.assign(item, keyobj);
  return _.concat([item], collection);
}
function update(collection, item, keyobj) {
  let index = _.findIndex(collection, keyobj);
  collection[index] = item;
  return [...collection];
}
function remove(collection, keyobj) {
  collection = [...collection];
  _.remove(collection, keyobj);
  return collection;
}

function cloneSnapshot(snapshot) {
  return {
    groups: _.cloneDeep(snapshot?.groups || null),
    parameters: _.cloneDeep(snapshot?.parameters || null),
    conditions: _.cloneDeep(snapshot?.conditions || null),
  };
}
