import { all, } from 'redux-saga/effects';

import campaings from './campaigns/saga';
import creatives from './creatives/saga';
import placements from './placements/saga';
import promoTargets from './promo-targets/saga';

export default function* () {
  yield all([
    campaings(),
    creatives(),
    placements(),
    promoTargets(),
  ]);
}
