import _ from 'lodash';
import { createActions } from 'redux-actions';

import API from 'src/service/api';

let asyncActions = {
  'FETCH_REVENUES': 'fetchRevenues',
  'FETCH_COHORT_REVENUES': 'fetchCohortRevenues',

  'FETCH_AD_EXPENSES': 'fetchAdExpenses',

  'FETCH_GROSS_MARGIN': 'fetchGrossMargin',
  'FETCH_ROMI': 'fetchRomi',
  'FETCH_INSTALLS': 'fetchInstalls',
  'FETCH_CURRENCIES': 'fetchCurrencies',

  'FETCH_ARPU': 'fetchArpu',
  'FETCH_ARPPU': 'fetchArppu',
  'FETCH_ARPAS': 'fetchArpas',
  'FETCH_REFUNDS': 'fetchRefunds',
  'FETCH_PURCHASES': 'fetchPurchases',

  'FETCH_ACTIVE_SUBSCRIPTION': 'fetchActiveSubscription',
  'FETCH_ACTIVE_PAID_SUBSCRIPTION': 'fetchActivePaidSubscription',
  'FETCH_NEW_SUBSCRIPTION': 'fetchNewSubscription',
  'FETCH_CANCEL_SUBSCRIPTION': 'fetchCancelSubscription',
  'FETCH_RETURN_SUBSCRIPTION': 'fetchReturnSubscription',
};

asyncActions = _.mapValues(asyncActions, (item) => (
  async (...args) => {
    const rs = await API[item](...args);
    if (rs instanceof Error) throw rs;
    return rs.data;
  }
));

export default createActions(
  { ...asyncActions },
);
