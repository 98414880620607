import _ from 'lodash';
import { createActions, } from 'redux-actions';

import API from 'src/service/api';

import { createSuccessErrorActions, } from '../../helpers';


let asyncActions = {
  'FETCH_HISTORY': 'fetchMutatorHistory',
  'FETCH_VERSION': 'fetchMutatorConfig',
};

asyncActions = _.mapValues(asyncActions, (item) => (
  async (...args) => {
    const rs = await API[item](...args);
    if (rs instanceof Error) throw rs;
    return rs;
  }
));

export default createActions(

  { ...asyncActions },

  ...createSuccessErrorActions('FETCH'),

  'FETCH_FULL_HISTORY_SUCCESS',

  'PUBLISH',
  'DISCARD',
  'ROLLBACK',
  'PUSH',
  
  'IMPORT_CONFIG',

  'CREATE_GROUP',
  'UPDATE_GROUP',
  'DELETE_GROUP',

  'CREATE_PARAMETER',
  'UPDATE_PARAMETER',
  'DELETE_PARAMETER',

  'CREATE_CONDITION',
  'UPDATE_CONDITION',
  'UPDATE_CONDITIONS',
  'DELETE_CONDITION',

  'CLEAR',

  { prefix: 'MUTATOR/CONFIG', }

);
