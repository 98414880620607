import _ from 'lodash';
import { takeEvery, put, call } from 'redux-saga/effects';

import API from 'src/service/api';

import { getApiErrorTitle, getApiErrorText, } from '../helpers/errors-api';

import { AppActions, } from './actions';


export function createSuccessErrorActions(name) {
  return [name, `${name}_SUCCESS`, `${name}_ERROR`];
}

export function createFetchGenerator(name, method, actions) {
  if (!actions) {
    actions = method;
    method = name;
  }

  return function* () {
    yield takeEvery(actions[name], function* ({ payload }) {
      const rs = yield call(API[method], payload);

      if (rs instanceof Error) {
        const message = getApiErrorText(rs);

        yield put(actions[`${name}Error`]({ message }));
        yield put(actions[`${name}Success`]([]));
      } else {
        yield put(actions[`${name}Success`](rs.data));
      }
    });
  }
}

export function* processError(rs) {
  if (_.get(rs, 'response.data.data.status') === 422) {
    yield put(AppActions.showNotification({
      type: 'error',
      message: `Error`,
      description: getApiErrorTitle(rs),
    }));
    try {
      let err = JSON.parse(_.get(rs, 'response.data.data.message'));
      yield put(AppActions.setModalFormError(err));
    }
    catch (ex) {
      console.warn(ex);
    }
  }
  else {
    yield put(AppActions.showNotification({
      type: 'error',
      message: `Error`,
      description: getApiErrorText(rs),
    }));
  }
}
