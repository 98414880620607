import _ from 'lodash';
import { createActions, } from 'redux-actions';

import API from 'src/service/api';

import { createSuccessErrorActions, } from '../../helpers';

let asyncActions = {
  'FETCH_CREATIVE': 'fetchVariatorCreative',
};

asyncActions = _.mapValues(asyncActions, (item) => (
  async (...args) => {
    const rs = await API[item](...args);
    if (rs instanceof Error) throw rs;
    return rs;
  }
));

export default createActions(

  { ...asyncActions },

  ...createSuccessErrorActions('FETCH_CREATIVES'),

  'CREATE_CREATIVE',
  'UPDATE_CREATIVE',
  'DELETE_CREATIVE',

  { prefix: 'VARIATOR/CREATIVES', }

);
