import React, { Component } from 'react';

import { SpinFullscreen, } from 'src/components/ui';


const m = new Map();


export default function asyncFunc(importComponent) {

  class AsyncFunc extends Component {

    constructor(props) {
      super(props);
      this.Component = m.get(importComponent);
    }

    async componentDidMount() {
      if (!this.Component) {
        const { default: Component } = await importComponent();
        this.Component = Component;
        m.set(importComponent, Component);
        this.forceUpdate();
      }
    }

    render() {
      let C = this.Component;
      return C
        ? <C {...this.props} />
        : <SpinFullscreen />;
    }

  }

  return AsyncFunc;
}
