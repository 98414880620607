import { createActions } from 'redux-actions';

import { createSuccessErrorActions } from '../helpers';

export default createActions(

  'CLEAR_DATA',

  ...createSuccessErrorActions('FETCH_SETTINGS'),
  'CREATE_SETTING',
  'DELETE_SETTING',
  'UPDATE_SETTING',

  { prefix: 'SETTINGS', }

);
