import styled from 'styled-components';
import { Drawer, } from 'antd';


export default styled(Drawer)`
  z-index: 1002;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .ant-drawer-content {
    overflow: visible !important;
  }

  .ant-drawer-wrapper-body {
    overflow: visible !important;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    .ant-drawer-body {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
    }
  } 
`;
