import { handleActions, } from 'redux-actions';

import actions from './actions';

const defaultState = {
  campaigns: null,
  campaignsTypes: null,
};

export default handleActions(
  {
    [actions.fetchCampaignsSuccess]: (state, { payload }) => ({ ...state, campaigns: payload }),
    [actions.fetchCampaignsTypesSuccess]: (state, { payload }) => ({ ...state, campaignsTypes: payload }),
  },
  defaultState
)
