import React from 'react';

import Image from '../../image/rob.png';
import FourXXStyleWrapper from './4xx.style';

export default () => (
  <FourXXStyleWrapper className="iso404Page">
    <div className="iso404Content">
      <h1><i className="ion-ios-build" /><i className="ion-ios-hammer" /></h1>
      <h3>Server Maintenance</h3>
      <p>Server currently under maintenance, please try again later</p>
    </div>
    <div className="iso404Artwork">
      <img alt="#" src={Image} />
    </div>
  </FourXXStyleWrapper>
);
