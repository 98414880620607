import { all, fork, } from 'redux-saga/effects';

import { createFetchGenerator, } from '../helpers';

import { SubtruckActions, } from '../actions';

const sagas = [
  createFetchGenerator('fetchSubtruckTransaction', SubtruckActions),
  createFetchGenerator('fetchSubtruckTransactionEvents', SubtruckActions),
  createFetchGenerator('fetchSubtruckTransactionEvent', SubtruckActions),
  createFetchGenerator('sendSubtruckFacebookPixelTestEvent', SubtruckActions),
  createFetchGenerator('sendSubtruckSnapchatPixelTestEvent', SubtruckActions),
];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
