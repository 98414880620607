import _ from 'lodash';
import { handleActions } from 'redux-actions';

import actions from './actions';

const STORAGE_KEY_REVENUE_NETWORKS = 'REDUX_CACHE_REVENUE_NETWORKS';

let networks = [],
  networksHash = {};

try {
  networks = JSON.parse(localStorage.getItem(STORAGE_KEY_REVENUE_NETWORKS)) || {};
  networksHash = _.keyBy(networks, 'code');
}
catch (ex) {
}

const defaultState = {
  products: null,
  networks,
  networksHash,
};

export default handleActions(
  {
    [actions.fetchRevenueProductsSuccess]: (state, { payload }) => ({ ...state, products: payload, }),
    [actions.fetchRevenueNetworksSuccess]: (state, { payload }) => {
      localStorage.setItem(STORAGE_KEY_REVENUE_NETWORKS, JSON.stringify(payload));
      return ({
        ...state,
        networks: payload,
        networksHash: _.keyBy(payload, 'code'),
      });
    },
  },
  defaultState
)
