import { all, fork, } from 'redux-saga/effects';

import { createFetchGenerator } from '../helpers';

import actions from './actions';

const sagas = [
  createFetchGenerator('fetchRevenueProducts', actions),
  createFetchGenerator('fetchRevenueNetworks', actions),
];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
