import _ from 'lodash';
import fp from 'lodash/fp';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withProps, } from 'recompose';
import { Link, } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

import { Breadcrumb, Button, Popover, Menu, TitlePlatform, } from 'src/components/ui';


const breadcrumbReplaceMap = {
  'Arpu': 'ARPU',
  'Arppu': 'ARPPU',
  'Arpas': 'ARPAS',
  'Dau': 'DAU',
  'Evtruck': 'EvTruck',
  'Ltv': 'LTV',
  'Romi': 'ROMI',
  'Subtruck': 'SubTruck',
  'Sdk': 'SDK',
};

const buildBreadcrumb = fp.flow([
  fp.map(_.startCase),
  fp.map(item => item in breadcrumbReplaceMap ? breadcrumbReplaceMap[item] : item),
]);


export default compose(
  connect(
    (state, props) => ({
      isMobileView: props.isMobileView === undefined ? state.app.view === 'MobileView' : props.isMobileView,
      apps: state.magnus.apps,
      projects: state.sdk.projects,
      products: state.revenue.products,
      experiments: state.mutator.experiments.experiments,
      savedCharts: state.evtruck.charts.savedCharts,
      dashboards: state.evtruck.dashboards.dashboards,
    }),
  ),
  withProps(({
    pathname,
    search,
    apps,
    projects,
    products,
    experiments,
    savedCharts,
    dashboards,
  }) => {
    const breadparts = pathname.split('/'),
      breadcrumbs = buildBreadcrumb(breadparts);

    if (breadcrumbs[breadcrumbs.length - 1] === breadcrumbs[breadcrumbs.length - 2]) {
      breadcrumbs.pop();
    }

    if (breadparts[1] && breadparts[1] !== 'profile' && breadparts[1] !== 'companies' && breadparts[1] !== 'instructions') {
      breadcrumbs[1] = _.filter(breadparts[1].split('~')).join(' & ');
    }

    if (breadcrumbs[2] === 'SDK') {
      const project = _.find(projects, item => item.id == breadparts[3]); // eslint-disable-line eqeqeq
      if (project) {
        breadcrumbs[3] = <TitlePlatform project={project} />;
      }
      if (breadcrumbs[4] === 'Mutator' && breadcrumbs[5] === 'Experiments' && breadcrumbs[6]) {
        const experiment = _.find(experiments, item => item.id == breadparts[6]); // eslint-disable-line eqeqeq
        if (experiment) {
          breadcrumbs[6] = experiment.name;
        }
      }
      if (breadcrumbs[4] === 'EvTruck' && breadcrumbs[5] === 'Charts' && breadcrumbs[6]) {
        const chart = _.find(savedCharts, item => item.id == breadparts[6]); // eslint-disable-line eqeqeq
        if (chart) {
          breadcrumbs[6] = chart.name;
        }
      }
      if (breadcrumbs[4] === 'EvTruck' && breadcrumbs[5] === 'Dashboards' && breadcrumbs[6]) {
        const dashboard = _.find(dashboards, item => item.id == breadparts[6]); // eslint-disable-line eqeqeq
        if (dashboard) {
          breadcrumbs[6] = dashboard.name;
        }
      }
      if (breadcrumbs[4] === 'EvTruck' && breadcrumbs[5] === 'User Props' && breadcrumbs[6]) {
        breadcrumbs[6] = breadparts[6];
      }
      if (breadcrumbs[4] === 'EvTruck' && breadcrumbs[5] === 'Events' && breadcrumbs[6]) {
        breadcrumbs[6] = breadparts[6];
      }
    }
    else if (
      (breadcrumbs[2] === 'Analytics' && breadcrumbs[3] === 'Revenue Forecast')
      ||
      breadcrumbs[2] === 'Marketing'
    ) {
      if (breadparts[4]) {
        const app = _.find(apps, item => item.id == breadparts[4]); // eslint-disable-line eqeqeq
        if (app) {
          breadcrumbs[4] = <TitlePlatform app={app} />;
        }
      }

      if (
        (breadcrumbs[2] === 'Marketing' && breadcrumbs[3] === 'Products')
        ||
        (breadcrumbs[2] === 'Analytics' && breadcrumbs[3] === 'Revenue Forecast')
      ) {
        if (breadparts[5]) {
          const product = _.find(products, item => item.id == breadparts[5]); // eslint-disable-line eqeqeq
          if (product) {
            breadcrumbs[5] = product.name;
          }
          else {
            breadcrumbs[5] = breadparts[5];
          }
        }
      }
      else if (breadcrumbs[2] === 'Marketing') {
        if (breadparts[5]) {
          breadcrumbs[5] = decodeURIComponent(breadparts[5]); // source
        }
      }
    }

    return {
      breadcrumbs: breadcrumbs.map((item, index) => ({
        title: item,
        path: breadparts.slice(0, index + 1).join('/') + (index === (breadcrumbs.length - 1) ? search : ''),
      })),
    };
  })
)(({
  isMobileView,
  breadcrumbs,
}) => {
  if (isMobileView) {
    if (breadcrumbs && breadcrumbs.length > 2 && breadcrumbs[breadcrumbs.length - 1].title) {
      return (
        <DDView
          breadcrumbs={breadcrumbs}
        />
      );
    }
    else {
      return breadcrumbs[breadcrumbs.length - 1].title;
    }
  }
  else {
    return (
      <FullView
        breadcrumbs={breadcrumbs}
      />
    );
  }
});

const DDView = ({ breadcrumbs }) => (
  <Popover
    content={
      <Menu style={{ border: 0, }}>
        {breadcrumbs.slice(0, -1).map((i) => i.title ? (
          <Menu.Item key={i.path}>
            <Link to={i.path}>{i.title}</Link>
          </Menu.Item>
        ) : null)}
      </Menu>
    }
    trigger="click"
    placement="bottomLeft"
  >
    <div style={{ display: 'flex', alignItems: 'flex-end', }}>
      <Button size="small" style={{ border: 0, fontSize: '14px', boxShadow: 'none', }}>
        <span>{breadcrumbs[breadcrumbs.length - 1].title}</span>
        <DownOutlined style={{ margin: '0 0 0 5px', fontSize: '12px', fontWeight: 'bold' }} />
      </Button>
    </div>
  </Popover>
);

const FullView = ({ breadcrumbs }) => (
  <Breadcrumb style={{ marginBottom: 2, }}>
    {breadcrumbs.map((i) => (
      <Breadcrumb.Item key={i.path}>
        {
          i.title
            ? <Link to={i.path}>{i.title}</Link>
            : i.title
        }
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
);
