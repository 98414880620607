export const SECTIONS = {
  COMPANY: 'company',
  ACCOUNTS: 'accounts',
  PEOPLE: 'people',
  ANALYTICS: 'analytics',
  MARKETING: 'marketing',
  VARIATOR: 'variator',
  MUTATOR: 'mutator',
  SUBTRUCK: 'subtruck',
  EVTRUCK: 'evtruck',
  SDK: 'sdk',
  SDK_SUMMARY: 'sdk-summary',
};

export const ICONS = {
  [SECTIONS.COMPANY]: 'ion-md-home',
  [SECTIONS.ACCOUNTS]: 'ion-md-briefcase',
  [SECTIONS.PEOPLE]: 'ion-md-people',
  [SECTIONS.ANALYTICS]: 'ion-md-trending-up',
  [SECTIONS.MARKETING]: 'ion-md-stats',
  [SECTIONS.VARIATOR]: 'ion-md-flame',
  [SECTIONS.MUTATOR]: 'ion-md-nuclear',
  [SECTIONS.SUBTRUCK]: 'ion-md-card',
  [SECTIONS.EVTRUCK]: 'ion-md-paw',
  [SECTIONS.SDK]: 'ion-md-code',
  [SECTIONS.SDK_SUMMARY]: 'ion-md-code',
}

export const ROUTES = {
  [SECTIONS.COMPANY]: `/:company/${SECTIONS.COMPANY}`,
  [SECTIONS.ACCOUNTS]: `/:company/${SECTIONS.ACCOUNTS}`,
  [SECTIONS.PEOPLE]: `/:company/${SECTIONS.PEOPLE}`,
  [SECTIONS.ANALYTICS]: `/:company/${SECTIONS.ANALYTICS}`,
  [SECTIONS.MARKETING]: `/:company/${SECTIONS.MARKETING}`,
  [SECTIONS.VARIATOR]: `/:company/${SECTIONS.SDK}/:project/${SECTIONS.VARIATOR}`,
  [SECTIONS.MUTATOR]: `/:company/${SECTIONS.SDK}/:project/${SECTIONS.MUTATOR}`,
  [SECTIONS.SUBTRUCK]: `/:company/${SECTIONS.SDK}/:project/${SECTIONS.SUBTRUCK}`,
  [SECTIONS.EVTRUCK]: `/:company/${SECTIONS.SDK}/:project/${SECTIONS.EVTRUCK}`,
  [SECTIONS.SDK]: `/:company/${SECTIONS.SDK}`,
  [SECTIONS.SDK_SUMMARY]: `/:company/${SECTIONS.SDK_SUMMARY}`,
}
