import _ from 'lodash';
import axios from 'axios';

import { store } from 'src/redux/store';
import { AppActions, AuthActions, } from 'src/redux/actions';

const queue = new Set();

const API_URL = process.env.REACT_APP_API_ENDPOINT;

const RQF = async (method, path, params, headers, options) => {
  const args = {
    method: method,
    url: `${API_URL}/${path}/`,
    headers: {},
  };

  const state = store.getState();

  if (state.auth.user) {
    args.headers['Authorization'] = `Bearer ${state.auth.token}`;

    // TODO попробовать отрефакторить как-то
    let projectId = state.app.sdkProject || params?.app_project_id;
    if (!projectId && params?.app_project_ids?.length === 1) {
      projectId = params?.app_project_ids?.[0];
    }
    const projectCompanyId = _.find(state.sdk.projects, { id: +projectId })?.company_id;
    if (projectId && projectCompanyId) {
      args.headers['X-Company-Id'] = projectCompanyId;
    }
    else if (state.auth.company) {
      args.headers['X-Company-Id'] = state.auth.company.id;
    }
    else if (state.auth.companies?.length) {
      args.headers['X-Company-Id'] = _.map(state.auth.companies, 'id').join(',');
    }
    else {
      args.headers['X-Company-Id'] = _.map(state.magnus.companies, 'id').join(',');
    }
  }

  if (headers) {
    if (headers.token) args.headers['Authorization'] = `Bearer ${headers.token}`;
    if (headers.companyId) args.headers['X-Company-Id'] = headers.companyId;
  }

  args.headers['pragma'] = 'no-cache';
  args.headers['cache-control'] = 'no-cache';

  !!~['get', 'delete'].indexOf(method) && Object.assign(args, { params });
  !!~['post', 'put'].indexOf(method) && Object.assign(args, { data: params });

  options = _.assign({ noFullSpinner: false, }, options);

  try {
    if (!options.noFullSpinner) {
      if (queue.size === 0) {
        store.dispatch(AppActions.showSpinner());
      }
      queue.add(args);
    }

    const rs = await axios(args);

    return rs;
  }
  catch (ex) {
    if (ex.response && ex.response.status === 401) {
      store.dispatch(AuthActions.logout());
    } else if (ex.response) {
      console.log(ex.response.data);
    } else if (ex.request) {
      console.log(ex.request);
    } else {
      console.log('Error', ex.message);
    }

    return ex;
  }
  finally {
    if (!options.noFullSpinner) {
      queue.delete(args);
      if (queue.size === 0) {
        store.dispatch(AppActions.hideSpinner());
      }
    }
  }
};

const RQ = async (...args) => {
  const rs = await RQF(...args);
  if (rs instanceof Error) {
    return rs;
  }
  else {
    return rs.data;
  }
}

export default {
  signup: async ({ name, email, password, invite_hash }) => await RQ('post', 'auth/signup', {
    name, email, password, password_confirm: password, invite_hash
  }),
  login: async args => await RQ('post', 'auth/login', args),
  confirmEmail: async ({ id, key: auth_key }) => await RQ('get', 'auth/confirm', { auth_key }),
  forgotPassword: async ({ email }) => await RQ('get', 'password/reset-mail', { email }),
  verifyResetPassword: async ({ token }) => await RQ('get', 'password/token-verification', { token }),
  resetPassword: async ({ token, password }) => await RQ('post', 'password/reset', {
    token, password, password_confirm: password
  }),
  fetchProfile: async args => await RQ('get', 'profile', {}, {
    token: args && args.token,
    companyId: args && args.companyId
  }),
  updateProfile: async formData => await RQ('put', 'profile', formData),
  updatePassword: async args => await RQ('put', 'password/change', args),

  fetchProfileSettings: async () => await RQ('get', 'profile/settings'),
  createProfileSetting: async (args) => await RQ('post', `profile/settings`, args),
  updateProfileSetting: async (args) => await RQ('put', `profile/settings/${args.id}`, args),
  deleteProfileSetting: async (args) => await RQ('delete', `profile/settings/${args.id}`),

  fetchCompanies: async args => await RQ('get', 'companies', {}, { token: args && args.token }),
  fetchCompanyUsers: async _ => await RQ('get', 'companies/users'),
  fetchCompanyRoles: async _ => await RQ('get', 'companies/roles'),
  fetchUserApps: async ({ id }) => await RQ('get', `users/${id}/apps`),
  fetchInvite: async ({ inviteHash }) => await RQ('get', `companies/invite-hash/${inviteHash}`),
  updateAvailableApps: async args => await RQ('post', `apps/users/${args.user_id}`, args),
  createCompany: async ({ name }) => await RQ('post', 'companies', { name }),
  createUserCompany: async ({ invite_hash }) => await RQ('post', 'companies/users', { invite_hash }),
  updateUserCompany: async ({ id, status }) => await RQ('put', `companies/users/${id}`, { status }),
  updateCompany: async ({ id, name }) => await RQ('put', `companies/${id}`, { name }),
  leaveCompany: async id => await RQ('delete', `profile/companies/${id}`),
  deleteUsers: async ids => await RQ('delete', 'companies/users', { user_ids: ids }),

  fetchPublishers: async _ => await RQ('get', 'publishers'),
  fetchPublisher: async ({ id }) => await RQ('get', `publishers/${id}`),
  deletePublisher: async ({ id }) => await RQ('delete', `publishers/${id}`),
  updatePublisher: async ({ id, is_active }) => await RQ('put', `publishers/${id}`, { is_active }),
  createPublisherIos: async (args) => await RQ('post', 'publishers/ios', args),
  updatePublisherIos: async ({ id, ...args }) => await RQ('put', `publishers/ios/${id}`, args),
  createPublisherAndroid: async formData => await RQ('post', 'publishers/android', formData),
  updatePublisherAndroid: async formData => await RQ('put', `publishers/android/${formData.get('id')}`, formData),

  fetchAdsAccounts: async _ => await RQ('get', 'expenses/ad-accounts'),
  changeFeeAdsAccounts: async args => await RQ('put', `expenses/ad-accounts/${args.id}`, _.omit(args, 'id')),

  fetchCreativesPreviews: async args => await RQ('get', `expenses/creative-previews/${args.creative_name}`),

  fetchLinkedGoogleAdWords: async _ => await RQ('get', 'expenses/linked-accounts/ad-words'),
  createLinkedGoogleAdWordsUrl: async ({ url }) => await RQ('get', 'expenses/linked-accounts/ad-words/create-url', { url }),
  createLinkedGoogleAdWords: async ({ url, code }) => await RQ('post', 'expenses/linked-accounts/ad-words', { url, code, }),
  deleteAccountGoogleAdWords: async ({ id }) => await RQ('delete', `expenses/linked-accounts/ad-words/${id}`),

  fetchLinkedFacebookAds: async _ => await RQ('get', 'expenses/linked-accounts/facebook'),
  createLinkedFacebookAdsUrl: async (args) => await RQ('get', 'expenses/linked-accounts/facebook/create-url', args),
  createLinkedFacebookAds: async (args) => await RQ('post', 'expenses/linked-accounts/facebook', args),
  updateLinkedFacebookAds: async ({ id, ...args }) => await RQ('put', `expenses/linked-accounts/facebook/${id}`, args),
  deleteAccountFacebookAds: async ({ id }) => await RQ('delete', `expenses/linked-accounts/facebook/${id}`),

  updateAdCampaign: async ({ network, id, field, ...args }) => await RQ('put', `expenses/linked-accounts/${network}/campaigns/${id}/${field}`, args),
  updateAdGroup: async ({ network, id, field, ...args }) => await RQ('put', `expenses/linked-accounts/${network}/ad-groups/${id}/${field}`, args),
  updateAd: async ({ network, id, field, ...args }) => await RQ('put', `expenses/linked-accounts/${network}/ads/${id}/${field}`, args),
  fetchAdsManagementHistory: async (args) => await RQ('get', `expenses/history`, args),

  fetchLinkedSnapchat: async (args, headers) => await RQ('get', 'expenses/linked-accounts/snapchat', args, headers),
  createLinkedSnapchatUrl: async ({ url }) => await RQ('get', 'expenses/linked-accounts/snapchat/create-url', { url, }),
  createLinkedSnapchat: async ({ url, code }) => await RQ('post', 'expenses/linked-accounts/snapchat', { url, code, }),
  deleteAccountSnapchat: async ({ id }) => await RQ('delete', `expenses/linked-accounts/snapchat/${id}`),

  fetchLinkedAdMob: async _ => await RQ('get', 'monetizations/linked-accounts/ad-mob'),
  createLinkedAdMobUrl: async ({ url }) => await RQ('get', 'monetizations/linked-accounts/ad-mob/create-url', { url }),
  createLinkedAdMob: async ({ url, code }) => await RQ('post', 'monetizations/linked-accounts/ad-mob', { url, code, }),
  deleteAccountAdMob: async ({ id }) => await RQ('delete', `monetizations/linked-accounts/ad-mob/${id}`),

  fetchLinkedSearchAds: async _ => await RQ('get', 'expenses/linked-accounts/search-ads'),
  createLinkedSearchAds: async formData => await RQ('post', 'expenses/linked-accounts/search-ads', formData),
  updateLinkedSearchAds: async formData => await RQ('put', `expenses/linked-accounts/search-ads/${formData.get('id')}`, formData),
  deleteAccountSearchAds: async ({ id }) => await RQ('delete', `expenses/linked-accounts/search-ads/${id}`),

  fetchLinkedAppodeal: async _ => await RQ('get', 'monetizations/linked-accounts/appodeal'),
  createLinkedAppodeal: async ({ name, api_key, appodeal_id }) => await RQ('post', 'monetizations/linked-accounts/appodeal', { name, api_key, appodeal_id, }),
  updateLinkedAppodeal: async args => await RQ('put', `monetizations/linked-accounts/appodeal/${args.id}`, _.omit(args, 'id')),
  deleteAccountAppodeal: async ({ id }) => await RQ('delete', `monetizations/linked-accounts/appodeal/${id}`),

  fetchLinkedAdjust: async _ => await RQ('get', 'finances/linked-accounts/adjust'),
  createLinkedAdjust: async args => await RQ('post', 'finances/linked-accounts/adjust', _.omit(args, 'id')),
  updateLinkedAdjust: async args => await RQ('put', `finances/linked-accounts/adjust/${args.id}`, _.omit(args, 'id')),
  deleteLinkedAdjust: async ({ id }) => await RQ('delete', `finances/linked-accounts/adjust/${id}`),
  updateLinkedAdjustFee: async args => await RQ('post', `finances/linked-accounts/adjust/${args.id}/costs`, _.omit(args, 'id')),
  fetchLinkedAdjustEvents: async ({ id, ...args }) => await RQ('get', `finances/linked-accounts/adjust/${id}/app-events`, args),

  fetchLinkedAppsflyer: async _ => await RQ('get', 'finances/linked-accounts/appsflyer'),
  createLinkedAppsflyer: async args => await RQ('post', 'finances/linked-accounts/appsflyer', _.omit(args, 'id')),
  updateLinkedAppsflyer: async args => await RQ('put', `finances/linked-accounts/appsflyer/${args.id}`, _.omit(args, 'id')),
  deleteLinkedAppsflyer: async ({ id }) => await RQ('delete', `finances/linked-accounts/appsflyer/${id}`),
  updateLinkedAppsflyerFee: async args => await RQ('post', `finances/linked-accounts/appsflyer/${args.id}/costs`, _.omit(args, 'id')),

  fetchLinkedUnityAds: async _ => await RQ('get', 'monetizations/linked-accounts/unity-ads'),
  createLinkedUnityAds: async args => await RQ('post', 'monetizations/linked-accounts/unity-ads', _.omit(args, 'id')),
  updateLinkedUnityAds: async args => await RQ('put', `monetizations/linked-accounts/unity-ads/${args.id}`, _.omit(args, 'id')),
  deleteLinkedUnityAds: async ({ id }) => await RQ('delete', `monetizations/linked-accounts/unity-ads/${id}`),

  fetchLinkedUnityAdsExpenses: async _ => await RQ('get', 'expenses/linked-accounts/unity-ads'),
  createLinkedUnityAdsExpenses: async args => await RQ('post', 'expenses/linked-accounts/unity-ads', _.omit(args, 'id')),
  updateLinkedUnityAdsExpenses: async args => await RQ('put', `expenses/linked-accounts/unity-ads/${args.id}`, _.omit(args, 'id')),
  deleteLinkedUnityAdsExpenses: async ({ id }) => await RQ('delete', `expenses/linked-accounts/unity-ads/${id}`),

  fetchLinkedIronSource: async _ => await RQ('get', 'monetizations/linked-accounts/iron-source'),
  createLinkedIronSource: async args => await RQ('post', 'monetizations/linked-accounts/iron-source', _.omit(args, 'id')),
  updateLinkedIronSource: async args => await RQ('put', `monetizations/linked-accounts/iron-source/${args.id}`, _.omit(args, 'id')),
  deleteLinkedIronSource: async ({ id }) => await RQ('delete', `monetizations/linked-accounts/iron-source/${id}`),

  fetchLinkedIronSourceExpenses: async _ => await RQ('get', 'expenses/linked-accounts/iron-source'),
  createLinkedIronSourceExpenses: async args => await RQ('post', 'expenses/linked-accounts/iron-source', _.omit(args, 'id')),
  updateLinkedIronSourceExpenses: async args => await RQ('put', `expenses/linked-accounts/iron-source/${args.id}`, _.omit(args, 'id')),
  deleteLinkedIronSourceExpenses: async ({ id }) => await RQ('delete', `expenses/linked-accounts/iron-source/${id}`),

  fetchLinkedApplovinExpenses: async _ => await RQ('get', 'base/linked-accounts/applovin'),
  createLinkedApplovinExpenses: async args => await RQ('post', 'base/linked-accounts/applovin', _.omit(args, 'id')),
  updateLinkedApplovinExpenses: async args => await RQ('put', `base/linked-accounts/applovin/${args.id}`, _.omit(args, 'id')),
  deleteLinkedApplovinExpenses: async ({ id }) => await RQ('delete', `base/linked-accounts/applovin/${id}`),

  createLinkedTikTokUrl: async ({ url }) => await RQ('get', 'expenses/linked-accounts/tik-tok/create-url', { url, }),
  fetchLinkedTikTokExpenses: async _ => await RQ('get', 'expenses/linked-accounts/tik-tok'),
  createLinkedTikTokExpenses: async args => await RQ('post', 'expenses/linked-accounts/tik-tok', _.omit(args, 'id')),
  updateLinkedTikTokExpenses: async args => await RQ('put', `expenses/linked-accounts/tik-tok/${args.id}`, _.omit(args, 'id')),
  deleteLinkedTikTokExpenses: async ({ id }) => await RQ('delete', `expenses/linked-accounts/tik-tok/${id}`),

  fetchLinkedAccountsPayPal: async _ => await RQ('get', 'finances/linked-accounts', { type: 'paypal' }),
  createLinkedAccountPayPal: async args => await RQ('post', 'finances/linked-accounts', { type: 'paypal', ...args }),
  updateLinkedAccountPayPal: async args => await RQ('put', `finances/linked-accounts/${args.id}`, { type: 'paypal', ...args }),
  deleteLinkedAccountPayPal: async ({ id }) => await RQ('delete', `finances/linked-accounts/${id}`),

  fetchLinkedAccountsRecurly: async _ => await RQ('get', 'finances/linked-accounts', { type: 'recurly' }),
  createLinkedAccountRecurly: async args => await RQ('post', 'finances/linked-accounts', { type: 'recurly', ...args }),
  updateLinkedAccountRecurly: async args => await RQ('put', `finances/linked-accounts/${args.id}`, { type: 'recurly', ...args }),
  deleteLinkedAccountRecurly: async ({ id }) => await RQ('delete', `finances/linked-accounts/${id}`),

  fetchLinkedAccountsSolidgate: async _ => await RQ('get', 'finances/linked-accounts', { type: 'solidgate' }),
  createLinkedAccountSolidgate: async args => await RQ('post', 'finances/linked-accounts', { type: 'solidgate', ...args }),
  updateLinkedAccountSolidgate: async args => await RQ('put', `finances/linked-accounts/${args.id}`, { type: 'solidgate', ...args }),
  deleteLinkedAccountSolidgate: async ({ id }) => await RQ('delete', `finances/linked-accounts/${id}`),

  createLinkedPinterestUrl: async ({ url }) => await RQ('get', 'expenses/linked-accounts/pinterest/create-url', { url, }),
  fetchLinkedPinterestExpenses: async _ => await RQ('get', 'expenses/linked-accounts/pinterest'),
  createLinkedPinterestExpenses: async args => await RQ('post', 'expenses/linked-accounts/pinterest', _.omit(args, 'id')),
  deleteLinkedPinterestExpenses: async ({ id }) => await RQ('delete', `expenses/linked-accounts/pinterest/${id}`),

  fetchApps: async _ => await RQ('get', 'me/apps'),
  fetchCompanyApps: async _ => await RQ('get', 'apps'),
  updateApp: async args => await RQ('put', `apps/${args.id}`, _.omit(args, 'id')),
  createAndroidApp: async args => await RQ('post', 'apps', args),

  fetchRoles: async _ => await RQ('get', 'roles', { 'with[users]': 1 }),
  fetchActions: async _ => await RQ('get', 'roles/actions'),
  createRole: async ({ name, role_id }) => await RQ('post', 'roles', { name, permissions: role_id }),
  deleteRole: async ({ id }) => await RQ('delete', `roles/${id}`),
  updateRole: async ({ name, role_id, id }) => await RQ('put', `roles/${id}`, { name, permissions: role_id }),
  assignRole: async args => await RQ('post', `roles/users`, args),

  fetchInvites: async _ => await RQ('get', 'company-invites'),
  createInvite: async args => await RQ('post', 'company-invites', args),
  approveInvite: async ({ key, token }) => await RQ('post', 'company-invites/approve', { invite_user_key: key }, { token }),
  verifyInvite: async ({ key }) => await RQ('get', 'company-invites/verification', { invite_user_key: key }),
  signupInvite: async ({ key, name, password }) => await RQ('post', 'auth/invite-signup', {
    invite_user_key: key, name, password, password_confirm: password
  }),
  deleteInvite: async ({ id }) => await RQ('delete', `company-invites/${id}`),
  resendInvite: async ({ id }) => await RQ('get', `company-invites/resend-mail/${id}`),
  updateInvite: async args => await RQ('put', `company-invites/${args.id}`, args),

  notifications: async () => await RQ('get', 'company-notifications', null, null, { noFullSpinner: true, }),

  fetchRevenues: async args => await RQ('get', `revenues`, args),
  fetchCohortRevenues: async args => await RQ('get', `revenues/cohort`, args),
  fetchInstalls: async args => await RQ('get', `apps/installs`, args),
  fetchAdExpenses: async args => await RQ('get', `expenses`, args),
  fetchGrossMargin: async args => await RQ('get', `revenues/gross-margin`, args),
  fetchRomi: async args => await RQ('get', `revenues/romi`, args),
  fetchArpu: async args => await RQ('get', `revenues/arpu`, args),
  fetchArppu: async args => await RQ('get', `revenues/arppu`, args),
  fetchArpas: async args => await RQ('get', `revenues/arpas`, args),
  fetchRefunds: async args => await RQ('get', `revenues/refund`, args),
  fetchPurchases: async args => await RQ('get', 'apps/subscriptions/purchase', args),
  fetchCurrencies: async args => await RQ('get', `base/currencies`, args),

  fetchActiveSubscription: async args => await RQ('get', `apps/subscriptions/active-subscription`, args),
  fetchActivePaidSubscription: async args => await RQ('get', `apps/subscriptions/active-paid-subscription`, args),
  fetchNewSubscription: async args => await RQ('get', `apps/subscriptions/new-subscription`, args),
  fetchCancelSubscription: async args => await RQ('get', `apps/subscriptions/cancel`, args),
  fetchReturnSubscription: async args => await RQ('get', `apps/subscriptions/refund`, args),

  fetchMarketingDashboard: async args => await RQ('get', `marketing/analytics`, args),
  fetchMarketingCohortReport: async ({ metric, ...args }) => await RQ('get', `marketing/cohort-report/${metric}`, args),
  fetchMarketingTimelineReport: async args => await RQ('get', `marketing/timeline-report`, args),

  fetchMarketingSources: async (args, options) => await RQ('get', `marketing/analytics/sources`, args, null, options),
  fetchMarketingCampaigns: async (args, options) => await RQ('get', `marketing/analytics/campaign-names`, args, null, options),
  fetchMarketingAdGroups: async (args, options) => await RQ('get', `marketing/analytics/adgroup-names`, args, null, options),
  fetchMarketingCreatives: async (args, options) => await RQ('get', `marketing/analytics/creative-names`, args, null, options),

  fetchPredictionsSalesRevenueApp: async args => await RQ('get', `predictions/prediction/sales-revenue-app`, args),
  fetchPredictionsSalesRevenueProduct: async args => await RQ('get', `predictions/prediction/sales-revenue-product`, args),
  fetchPredictionsRevenueForecast: async args => await RQ('get', `predictions/prediction/revenue-forecast`, args),

  fetchFinanceExpenses: async args => await RQ('get', 'finances/expenses', args),
  createFinanceExpenses: async args => await RQ('post', 'finances/expenses', args),
  deleteFinanceExpenses: async args => await RQ('delete', `finances/expenses/${args.id}`),
  updateFinanceExpenses: async args => await RQ('put', `finances/expenses/${args.id}`, _.omit(args, 'id')),

  fetchRevenueProducts: async args => await RQ('get', 'revenues/products', args),
  createRevenueProduct: async args => await RQ('post', 'revenues/products', args),

  fetchCountries: async args => await RQ('get', `base/countries`, args),
  fetchRevenueNetworks: async args => await RQ('get', `monetizations/statistics/networks`, args),
  fetchFinanceNetworks: async args => await RQ('get', `expenses/networks`, args),
  fetchGoogleAdsReport: async ({ id, ...args }) => await RQ('get', `base/google-ads/${id}`, args),

  fetchRevenueCompare: async args => await RQ('get', `revenues/compares`, args),

  fetchCompanySources: async () => await RQ('get', `sources`),
  fetchUserSources: async ({ id, }) => await RQ('get', `users/${id}/sources`),
  updateUserSources: async ({ id, ...args }) => await RQ('put', `users/${id}/sources`, args),

  fetchExpensesTrackers: async args => await RQ('get', 'expenses/trackers', args),
  createExpensesTracker: async args => await RQ('post', 'expenses/trackers', args),
  updateExpensesTracker: async args => await RQ('put', `expenses/trackers/${args.id}`, args),
  deleteExpensesTracker: async args => await RQ('delete', `expenses/trackers/${args.id}`),


  fetchAppProjects: async args => await RQ('get', 'app-projects', args),
  createAppProject: async args => await RQ('post', 'app-projects', args),
  updateAppProject: async args => await RQ('put', `app-projects/${args.id}`, args),
  deleteAppProject: async args => await RQ('delete', `app-projects/${args.id}`),
  fetchAppProjectHistory: async ({ id, ...args }) => await RQ('get', `app-projects/${id}/service-tokens/history`, args),

  createProjectApplication: async args => await RQ('post', 'apps/app-project', args),

  fetchAppProjectNotes: async args => await RQ('get', 'app-projects-notes', args),
  createAppProjectNote: async args => await RQ('post', 'app-projects-notes', args),
  updateAppProjectNote: async args => await RQ('put', `app-projects-notes/${args.id}`, args),
  deleteAppProjectNote: async args => await RQ('delete', `app-projects-notes/${args.id}`),

  fetchSDKSegmentOptions: async args => await RQ('get', 'base/segments/options', args),

  fetchSubtruckTransactions: async args => await RQF('get', 'sdk/subtruck/transactions', args),
  fetchSubtruckTransaction: async args => await RQ('get', `sdk/subtruck/transactions/${args.id}`),
  fetchSubtruckTransactionEvents: async args => await RQ('get', `sdk/subtruck/transactions/${args.id}/subscription-events`),
  fetchSubtruckTransactionEvent: async args => await RQ('get', `sdk/subtruck/subscription-events/${args.id}`),
  fetchSubtruckFailedTransactions: async args => await RQ('get', 'sdk/subtruck/transaction-fails', args),

  sendSubtruckFacebookPixelTestEvent: async args => await RQ('post', `expenses/linked-accounts/facebook/pixel-event/subtruck`, args),
  sendSubtruckSnapchatPixelTestEvent: async args => await RQ('post', `expenses/linked-accounts/snapchat/pixel-event/subtruck`, args),

  fetchVariatorPlacements: async args => await RQ('get', 'sdk/variator/placements', args),
  createVariatorPlacement: async args => await RQ('post', 'sdk/variator/placements', args),
  deleteVariatorPlacement: async args => await RQ('delete', `sdk/variator/placements/${args.id}`),
  updateVariatorPlacement: async args => await RQ('put', `sdk/variator/placements/${args.id}`, _.omit(args, 'id')),

  fetchVariatorCreatives: async args => await RQ('get', 'sdk/variator/creatives', args),
  fetchVariatorCreative: async args => await RQ('get', `sdk/variator/creatives/${args.id}`),
  createVariatorCreative: async args => await RQ('post', 'sdk/variator/creatives', args),
  deleteVariatorCreative: async args => await RQ('delete', `sdk/variator/creatives/${args.id}`),
  updateVariatorCreative: async args => await RQ('put', `sdk/variator/creatives/${args.id}`, _.omit(args, 'id')),

  fetchVariatorCampaigns: async args => await RQ('get', 'sdk/variator/campaigns', args),
  createVariatorCampaign: async args => await RQ('post', 'sdk/variator/campaigns', args),
  deleteVariatorCampaign: async args => await RQ('delete', `sdk/variator/campaigns/${args.id}`),
  updateVariatorCampaign: async args => await RQ('put', `sdk/variator/campaigns/${args.id}`, _.omit(args, 'id')),
  activateVariatorCampaign: async args => await RQ('put', `sdk/variator/campaigns/${args.id}/activate`),
  deactivateVariatorCampaign: async args => await RQ('put', `sdk/variator/campaigns/${args.id}/deactivate`),
  fetchVariatorCampaignsTypes: async args => await RQ('get', 'sdk/variator/campaigns/types', args),

  fetchVariatorStatistics: async args => await RQ('get', `sdk/variator/statistics`, args),

  fetchVariatorPromoTargets: async args => await RQ('get', 'sdk/variator/promo-targets', args),
  createVariatorPromoTarget: async args => await RQ('post', 'sdk/variator/promo-targets', args),
  deleteVariatorPromoTarget: async args => await RQ('delete', `sdk/variator/promo-targets/${args.id}`),
  updateVariatorPromoTarget: async args => await RQ('put', `sdk/variator/promo-targets/${args.id}`, _.omit(args, 'id')),

  fetchMutatorHistory: async (args, headers) => await RQ('get', `sdk/mutator/remote-config`, args, headers),
  fetchMutatorConfig: async ({ id, }, headers) => await RQ('get', `sdk/mutator/remote-config/${id}`, null, headers),
  updateMutatorConfig: async args => await RQ('post', `sdk/mutator/remote-config`, args),
  revertMutatorVersion: async args => await RQ('post', `sdk/mutator/remote-config/revert`, args),

  fetchMutatorExperiments: async args => {
    args = args || {};
    if (!args.with) {
      args.with = ['goals', 'variants', 'segments', 'performed_events'];
    }
    return await RQ('get', 'sdk/mutator/experiments', args);
  },
  createMutatorExperiment: async args => await RQ('post', 'sdk/mutator/experiments', args),
  deleteMutatorExperiment: async args => await RQ('delete', `sdk/mutator/experiments/${args.id}`),
  updateMutatorExperiment: async args => await RQ('put', `sdk/mutator/experiments/${args.id}`, args),
  fetchMutatorStatistics: async ({ id, ...args }) => await RQ('get', `sdk/mutator/experiments/${id}/statistics`, args),

  fetchMutatorDeviceLog: async args => await RQ('get', `sdk/mutator/experiment-variants`, args),
  fetchEvTruckEventsLog: async args => await RQ('get', 'sdk/evtruck/events', args),
  fetchEvTruckInstallsLog: async args => await RQ('get', 'sdk/evtruck/installs', args),
  fetchEvTruckUserPropsLog: async args => await RQ('get', 'sdk/evtruck/user-properties-history', args),

  fetchEvTruckAnalytics: async args => await RQ('post', 'sdk/evtruck/events/statistics', args),
  fetchEvTruckEvents: async args => await RQ('get', 'sdk/evtruck/events/list', args),
  fetchEvTruckParams: async args => await RQ('get', 'sdk/evtruck/events/params-list', args),

  fetchEvTruckCharts: async args => await RQ('get', 'sdk/evtruck/services', args),
  createEvTruckChart: async args => await RQ('post', 'sdk/evtruck/services', args),
  deleteEvTruckChart: async args => await RQ('delete', `sdk/evtruck/services/${args.id}`),
  updateEvTruckChart: async args => await RQ('put', `sdk/evtruck/services/${args.id}`, args),
  fetchEvTruckFunnelStatistics: async (args, options) => await RQ('post', `sdk/evtruck/funnel`, args, null, options),
  fetchEvTruckRetentionStatistics: async (args, options) => await RQ('post', `sdk/evtruck/retention`, args, null, options),
  fetchEvTruckFlowStatistics: async (args, options) => await RQ('post', `sdk/evtruck/behavior-flow-2`, args, null, options),

  fetchEvTruckSegments: async args => await RQ('get', 'sdk/evtruck/segment-containers', args),
  createEvTruckSegment: async args => await RQ('post', 'sdk/evtruck/segment-containers', args),
  deleteEvTruckSegment: async args => await RQ('delete', `sdk/evtruck/segment-containers/${args.id}`),
  updateEvTruckSegment: async args => await RQ('put', `sdk/evtruck/segment-containers/${args.id}`, args),

  fetchEvTruckDashboards: async args => await RQ('get', 'sdk/evtruck/dashboards', args),
  fetchEvTruckDashboard: async args => await RQ('get', `sdk/evtruck/dashboards/${args.id}`),
  createEvTruckDashboard: async args => await RQ('post', 'sdk/evtruck/dashboards', args),
  deleteEvTruckDashboard: async args => await RQ('delete', `sdk/evtruck/dashboards/${args.id}`),
  updateEvTruckDashboard: async args => await RQ('put', `sdk/evtruck/dashboards/${args.id}`, args),

};
