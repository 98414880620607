import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/analytics';

import 'antd/lib/style/themes/default.less';
import 'antd/dist/antd.less';

import App from './App';
import * as serviceWorker from './serviceWorker';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
})

firebase.initializeApp({
  apiKey: 'AIzaSyArcIrN6EO8nJbuvl9p0Q_ikEjOuq4m1Ic',
  authDomain: 'magnus-216011.firebaseapp.com',
  databaseURL: 'https://magnus-216011.firebaseio.com',
  projectId: 'magnus-216011',
  storageBucket: 'magnus-216011.appspot.com',
  messagingSenderId: '900666621875',
  appId: '1:900666621875:web:3f6cfff2b0fdbbdef315c6',
  measurementId: 'G-2XWLCF7QLK'
});

ReactDOM.render(<App />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept('./App.js', _ => {
    const NextApp = require('./App').default;

    ReactDOM.render(<NextApp />, document.getElementById('root'));
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
