import _ from 'lodash';
import { createActions, } from 'redux-actions';

import API from 'src/service/api';

import { createSuccessErrorActions, } from '../../helpers';

let asyncActions = {
  'FETCH_STATISTICS': 'fetchVariatorStatistics',
};

asyncActions = _.mapValues(asyncActions, (item) => (
  async (...args) => {
    const rs = await API[item](...args);
    if (rs instanceof Error) throw rs;
    return rs;
  }
));

export default createActions(

  { ...asyncActions },

  ...createSuccessErrorActions('FETCH_CAMPAIGNS'),
  ...createSuccessErrorActions('FETCH_CAMPAIGNS_TYPES'),

  'CREATE_CAMPAIGN',
  'UPDATE_CAMPAIGN',
  'DELETE_CAMPAIGN',

  'ACTIVATE_CAMPAIGN',
  'DEACTIVATE_CAMPAIGN',

  { prefix: 'VARIATOR/CAMPAINGS', }

);
