import { handleActions } from 'redux-actions';

import actions from './actions';

const defaultState = {
  data: null,
};

export default handleActions(
  {
    [actions.fetchSettingsSuccess]: (state, { payload }) => ({ ...state, data: payload }),

    [actions.clearData]: state => ({ ...defaultState, }),
  },
  defaultState
)
