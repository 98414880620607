import _ from 'lodash';
import { handleActions } from 'redux-actions';

import actions from './actions';

const STORAGE_KEY_NETWORKS = 'REDUX_CACHE_FINANCE_NETWORKS';

let networksHash = {};

try {
  networksHash = JSON.parse(localStorage.getItem(STORAGE_KEY_NETWORKS)) || {};
}
catch (ex) {
  networksHash = {};
}

const defaultState = {
  data: null,
  networksHash,
  networks: _.map(networksHash, (name, key) => ({ name, key, })),
  trackers: null,
};

export default handleActions(
  {
    [actions.fetchFinanceExpensesSuccess]: (state, { payload }) => ({ ...state, data: payload }),

    [actions.fetchFinanceNetworksSuccess]: (state, { payload }) => {
      localStorage.setItem(STORAGE_KEY_NETWORKS, JSON.stringify(payload));
      return ({
        ...state,
        networksHash: payload,
        networks: _.map(payload, (name, key) => ({ name, key, })),
      });
    },

    [actions.fetchExpensesTrackersSuccess]: (state, { payload }) => ({ ...state, trackers: payload }),

    [actions.clearData]: state => ({ ...defaultState, }),
  },
  defaultState
)
