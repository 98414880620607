import { handleActions, } from 'redux-actions';

import actions from './actions';

const defaultState = {
  creatives: null,
};

export default handleActions(
  {
    [actions.fetchCreativesSuccess]: (state, { payload }) => ({ ...state, creatives: payload }),
  },
  defaultState
)
