import _ from 'lodash';
import React from 'react';
import { compose, toClass } from 'recompose';
import styled from 'styled-components';

import { Button, Checkbox } from './';


const Styles = styled.div`
  .ant-btn{
    z-index: 0!important;
  }
`;

const getChecked = (form, field) => {
  const checked = form.getFieldValue(field);

  if (typeof checked === 'undefined') return [];
  if (Array.isArray(checked)) return checked;

  return [checked];
};

const handleClick = (form, field, value) => {
  const checked = getChecked(form, field);

  checked.includes(value) ? _.pull(checked, value) : checked.push(value);

  form.setFieldsValue({ [field]: checked });
};

export default compose(
  toClass
)(({ form, field, options }) => {
  options.forEach(item => {
    item.value = String(item.value);
    item.type = getChecked(form, field).includes(item.value) ? 'primary' : 'default'
  });

  return (
    <Styles>
      <Checkbox.Group options={options.map(item => item.value)} style={{ display: 'none' }} />
      <Button.Group>
        {options.map(item =>
          <Button
            key={item.value}
            type={item.type}
            disabled={item.disabled}
            onClick={e => handleClick(form, field, item.value)}
          >
            <span>{item.name}</span>
          </Button>
        )}
      </Button.Group>
    </Styles>
  );
});

// export default ({ form, field, options }) => {
//   const checked = getChecked(form, field);
//
//   options.forEach(item => {
//     item.value = String(item.value);
//     item.type = checked.includes(item.value) ? 'primary' : 'default'
//   });
//
//   return (
//     <Styles>
//       <Checkbox.Group options={options.map(item => item.value)} style={{ display: 'none' }} />
//       <Button.Group onClick={e => handleClick(form, field, e.target.value)}>
//         {options.map(item => <Button value={item.value} type={item.type} key={item.value}>{item.name}</Button>)}
//       </Button.Group>
//     </Styles>
//   );
// };

// export default class extends React.Component {
//   get checked() {
//     const { form, field } = this.props
//       , checked = form.getFieldValue(field);
//
//     if (typeof checked === 'undefined') return [];
//     if (Array.isArray(checked)) return checked;
//
//     return [ checked ];
//   };
//
//   handleClick(value) {
//     const { form, field } = this.props
//       , checked = this.checked;
//
//     checked.includes(value) ? _.pull(checked, value) : checked.push(value);
//
//     form.setFieldsValue({ [field]: checked });
//   };
//
//   render() {
//     const { options } = this.props;
//
//     options.forEach(item => {
//       item.value = String(item.value);
//       item.type = this.checked.includes(item.value) ? 'primary' : 'default'
//     });
//
//     return (
//       <Styles>
//         <Checkbox.Group options={options.map(item => item.value)} style={{ display: 'none' }} />
//         <Button.Group onClick={e => this.handleClick(e.target.value)}>
//           {options.map(item => <Button value={item.value} type={item.type} key={item.value}>{item.name}</Button>)}
//         </Button.Group>
//       </Styles>
//     );
//   }
// }
