import _ from 'lodash';
import { handleActions } from 'redux-actions';

import LANGS from '../../constants/langs';

import actions from './actions';

const adminRole = { id: 0, name: 'Administrator' };

const STORAGE_KEY_COUNTRIES = 'REDUX_CACHE_COUNTRIES';

let countriesHash = {};

try {
  countriesHash = JSON.parse(localStorage.getItem(STORAGE_KEY_COUNTRIES)) || {};
}
catch (ex) {
  countriesHash = {};
}

const defaultState = {
  companies: [],
  roles: [adminRole],
  permissions: [],
  companyRoles: [adminRole],
  invites: [],
  apps: null,
  allCompanyApps: null,
  userApps: null,
  userProjects: null,

  countriesHash,
  countries: _.map(countriesHash, (name, key) => ({ name, key, value: key, })),

  langsHash: _.mapValues(_.keyBy(LANGS, 'alpha2'), i => i.English),

  companySources: null,
  userSources: null,
};

export default handleActions(
  {
    [actions.fetchCompaniesSuccess]:
      (state, { payload }) =>
      ({
        ...state,
        companies: payload,
      }),

    [actions.fetchCompanyUsersSuccess]:
      (state, { payload }) => {
        payload.forEach(item => {
          if (item.is_admin === 1) {
            item.role = { ...state.roles.find(i => i.id === 0) };
          }
        });

        return {
          ...state,
          users: payload,
        };
      },

    [actions.fetchInvitesSuccess]:
      (state, { payload }) => ({
        ...state,
        invites: payload,
      }),

    [actions.fetchRolesSuccess]:
      (state, { payload }) => {
        payload.forEach(item => {
          item.id = +item.id;
        });

        return {
          ...state,
          roles: defaultState.roles.concat(payload),
        };
      },

    [actions.fetchCompanyRolesSuccess]:
      (state, { payload }) => {
        payload.forEach(item => {
          item.id = +item.id;
        });

        return {
          ...state,
          companyRoles: defaultState.companyRoles.concat(payload),
        };
      },

    [actions.fetchActionsSuccess]:
      (state, { payload }) => ({
        ...state,
        actions: payload,
      }),

    [actions.fetchAppsSuccess]:
      (state, { payload }) => ({
        ...state,
        apps: payload
      }),

    [actions.fetchUserAppsSuccess]:
      (state, { payload }) => ({
        ...state,
        userApps: payload?.apps,
        userProjects: payload?.app_projects,
      }),

    [actions.clearUserApps]:
      (state, { payload }) => ({
        ...state,
        userApps: null,
        userProjects: null,
      }),

    [actions.fetchCompanyAppsSuccess]:
      (state, { payload }) => ({
        ...state,
        allCompanyApps: payload,
      }),

    [actions.clearCompanyApps]:
      (state, { payload }) => ({
        ...state,
        allCompanyApps: null,
      }),

    [actions.fetchCountriesSuccess]:
      (state, { payload }) => {
        localStorage.setItem(STORAGE_KEY_COUNTRIES, JSON.stringify(payload));
        return ({
          ...state,
          countriesHash: payload,
          countries: _.map(payload, (name, key) => ({ name, key, value: key, })),
        });
      },

    [actions.fetchCompanySourcesSuccess]:
      (state, { payload }) => ({
        ...state,
        companySources: payload,
      }),

    [actions.clearCompanySources]:
      (state, { payload }) => ({
        ...state,
        companySources: null,
      }),

    [actions.fetchUserSourcesSuccess]:
      (state, { payload }) => ({
        ...state,
        userSources: payload,
      }),

    [actions.clearUserSources]:
      (state) => ({
        ...state,
        userSources: null,
      }),

  },
  defaultState
)
