import _ from 'lodash';

export function parse(state, values) {
  const res = {};
  _.forEach(state, (v, k) => {
    if (typeof v === 'boolean') {
      res[k] = values[k].asBoolean();
    }
    else if (typeof v === 'number') {
      res[k] = values[k].asNumber();
    }
    else {
      res[k] = values[k].asString();
    }
  });
  return res;
}

export function onFetched(state) {
  document.querySelector('#atlwdg-trigger').style.display = state.jiraIssueCollectorHidden ? 'none' : undefined;
}