import _ from 'lodash';
import React from 'react';
import { compose, withHandlers, withProps, } from 'recompose';
import { Tree, } from 'antd';


function getCheckedChildren(nodes, acc = []) {
  nodes.forEach(node => {
    if (node.children) {
      return getCheckedChildren(node.children, acc);
    } else {
      acc.push(node);
    }
  });

  return acc;
}

function renderTree(node) {
  return (
    <Tree.TreeNode
      style={{ display: node.hidden ? 'none' : undefined }}
      title={node.title || node.name}
      icon={node.icon}
      key={node.children ? `parent-${node.value || node.value}` : node.value}
    >
      {node.children && node.children.map(i => renderTree(i))}
    </Tree.TreeNode>
  );
}


export default compose(
  withProps(
    ({ checked = [], ...props }) => {
      return { checked };
    },
  ),
  withHandlers({
    handleCheck: ({ handleData }) => (checkedKeys, { checkedNodes }) => {
      const checked = checkedNodes.filter(i => !i.children).map(i => i.key);
      handleData(checked);
    },
    handleSelect: ({ handleData, checked }) => ([value], info) => {
      const rootNode = info.selectedNodes[0]
        , selected = info.node.checked
        , nodes = rootNode.children ? getCheckedChildren([rootNode]) : [rootNode];

      const values = nodes.map(item => item.key);

      checked = selected ? _.without(checked, ...values) : _.union(checked, values);

      handleData(checked);
    },
    handleKeyDown: _ => e => {
      if (e.keyCode === 32) {
        e.preventDefault();
        e.target.click();
      }
    },
  }),
)(({
  handleCheck,
  handleSelect,
  nodes,
  checked,
  ...props
}) => (
  <Tree
    checkable
    defaultExpandAll
    checkedKeys={_.map(checked, String)}
    selectedKeys={[]}
    onCheck={handleCheck}
    onSelect={handleSelect}
    {...props}
  >
    {_.map(nodes, item => renderTree(item))}
  </Tree>
));
