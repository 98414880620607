import { handleActions, } from 'redux-actions';

import actions from './actions';

const defaultState = {
  placements: null,
};

export default handleActions(
  {
    [actions.fetchPlacementsSuccess]: (state, { payload }) => ({ ...state, placements: payload, }),
  },
  defaultState
)
