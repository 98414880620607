import { all, } from 'redux-saga/effects';

import dashboards from './dashboards/saga';
import events from './events/saga';
import charts from './charts/saga';
import segments from './segments/saga';

export default function* () {
  yield all([
    charts(),
    dashboards(),
    events(),
    segments(),
  ]);
}
