import { handleActions, } from 'redux-actions';

import actions from './actions';

const defaultState = {
  dashboards: null,
};

export default handleActions(
  {
    [actions.fetchDashboardsSuccess]: (state, { payload }) => ({ ...state, dashboards: payload, }),
    [actions.clear]: () => defaultState,
  },
  defaultState
)
