import _ from 'lodash';

import { store } from '../store';

export function serializeCompany(value) {
  const { companies } = store.getState().magnus;
  value = _.filter(_.map(value, v => _.find(companies, { id: v })?.name));
  value = value.length === 0 ? '~' : value.length === 1 ? value[0] : ('~' + value.join('~') + '~');
  return value;
}

export function parseCompany(value) {
  const { companies } = store.getState().magnus;
  value = _.filter((value || '').split('~'));
  value = _.filter(_.map(value, i => _.find(companies, { name: i })?.id));
  return value;
}