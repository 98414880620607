import _ from 'lodash';
import { createActions, } from 'redux-actions';

import API from 'src/service/api';

import { createSuccessErrorActions, } from '../../helpers';

import { queue } from '../queue';


let queueActions = {
  'FETCH_FUNNEL_STATISTICS': 'fetchEvTruckFunnelStatistics',
  'FETCH_RETENTION_STATISTICS': 'fetchEvTruckRetentionStatistics',
  'FETCH_FLOW_STATISTICS': 'fetchEvTruckFlowStatistics',
};

let asyncActions = {
  'CREATE_SAVED_CHART': 'createEvTruckChart',
};


queueActions = _.mapValues(queueActions, (item) => (
  async (...args) => {
    const rs = await queue.add(() => API[item](...args));
    if (rs instanceof Error) throw rs;
    return rs;
  }
));

asyncActions = _.mapValues(asyncActions, (item) => (
  async (...args) => {
    const rs = await API[item](...args);
    if (rs instanceof Error) throw rs;
    return rs;
  }
));

export default createActions(

  { ...queueActions, ...asyncActions },

  ...createSuccessErrorActions('FETCH_SAVED_CHARTS'),

  'DELETE_SAVED_CHART',
  'UPDATE_SAVED_CHART',

  'CLEAR',

  { prefix: 'EVTRUCK/RETENTION', }

);
