
export const CHANNEL = 'messaging';

export function postMessage(data, fallbackWindow) {
  const message = JSON.stringify(data);
  try {
    const bc = new window.BroadcastChannel(CHANNEL);
    bc.postMessage(message);
  }
  catch (ex) {
    fallbackWindow.postMessage(message, '*');
  }
}
