import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose, withHandlers, } from 'recompose';

import { Select, TitlePlatform, } from 'src/components/ui';


export default compose(
  withRouter,
  connect(
    state => ({
      project: state.app.sdkProject,
      projects: _.sortBy(_.filter(state.sdk.projects, { is_hidden: false }), i => i.name.toLowerCase()),
    }),
  ),
  withHandlers({
    handleChange:
      ({ history, location, }) =>
        (v) => {
          const arr = location.pathname.split('/').slice(0, 6);
          arr[3] = v;
          history.push(arr.join('/'));
        },
  }),
)(({
  project,
  projects,
  handleChange,
}) => (
  <div style={{ padding: '16px 16px 0 16px', }}>
    <Select
      value={project}
      showSearch
      optionFilterProp={'name'}
      placeholder="Select Project"
      style={{ width: '100%', }}
      onChange={handleChange}
    >
      {projects.map(item => (
        <Select.Option value={String(item.id)} key={String(item.id)} name={item.name}>
          <TitlePlatform project={item} />
        </Select.Option>
      ))}
    </Select>
  </div>
));
