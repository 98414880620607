import { createActions, } from 'redux-actions';

import { createSuccessErrorActions, } from '../../helpers';

export default createActions(

  ...createSuccessErrorActions('FETCH_SEGMENTS'),

  'CREATE_SEGMENT',
  'DELETE_SEGMENT',
  'UPDATE_SEGMENT',

  'CLEAR',

  { prefix: 'EVTRUCK/SEGMENTS', }

);
