import React from 'react';
import { compose, withHandlers, withStateHandlers, setStatic } from 'recompose';
import styled from 'styled-components';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';

import { Input, Button, } from './';


const Styles = styled.div`
  .magnus-input-editable-container {
     display: flex;
     justify-content: space-between;
     align-items: center;
  }
`;


export default compose(
  setStatic('getDerivedStateFromProps', (nextProps, prevState) => (
    !prevState.edit && nextProps.text !== prevState.value
      ? { value: nextProps.text }
      : null
  )),
  withStateHandlers(
    ({ text }) => ({ value: text, edit: false }),
    {
      toggle: state => _ => ({ edit: !state.edit }),
      change: _ => value => ({ value }),
    },
  ),
  withHandlers({
    handleCheck: ({ change, regEx }) => e => {
      const value = e.target.value;

      if (regEx && value && !regEx.test(value)) return;

      change(value);
    },
  }),
  withHandlers({
    handleSubmit: ({ handleData, value, toggle }) => e => {
      e.preventDefault();

      handleData(value);
      toggle();
    },
    handleKeyUp: ({ toggle }) => e => {
      if (e.keyCode === 27) toggle();
    },
  }),
)(({ toggle, edit, handleCheck, handleSubmit, handleKeyUp, value, change, disabled }) => (
  <Styles>
    {edit
      ? (
        <Input
          suffix={<CheckOutlined style={{ cursor: 'pointer' }} onClick={handleSubmit} />}
          onPressEnter={handleSubmit}
          value={value}
          onChange={handleCheck}
          onKeyUp={handleKeyUp}
        />
      ) : (
        <div className="magnus-input-editable-container">
          <span>{value}</span><Button title="Edit" size="small" shape="circle" icon={<EditOutlined />} onClick={toggle} disabled={disabled} />
        </div>
      )
    }
  </Styles>
));
