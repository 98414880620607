import _ from 'lodash';
import React from 'react';
import { compose, withHandlers, setStatic, } from 'recompose';
import { Input, Tooltip, } from 'antd';
import { QuestionCircleOutlined, } from '@ant-design/icons';


export default compose(
  setStatic('TextArea', Input.TextArea),
  setStatic('Search', Input.Search),
  withHandlers({
    onChange: ({ onChange, trim, spaceless, regEx }) => e => {
      const value = e.target.value;

      //if (trim) e.target.value = value.trim();
      if (spaceless) e.target.value = value.replace(' ', '');
      if (regEx && value && !regEx.test(value)) return;

      onChange(e);
    },
  }),
)(({ tooltip, ...rest }) => (
  <Input
    tabIndex="0"
    addonAfter={tooltip ? (
      <Tooltip arrowPointAtCenter={true} placement="bottomLeft" title={tooltip}>
        <QuestionCircleOutlined style={{ fontSize: '18px' }} />
      </Tooltip>
    ) : undefined}
    {..._.omit(rest, ['trim', 'spaceless', 'regEx'])}
  />
));
