import { createActions } from 'redux-actions';

import { createSuccessErrorActions } from '../helpers';

export default createActions(

  ...createSuccessErrorActions('FETCH'),

  { prefix: 'REMOTE_CONFIG', }

);
