import _ from 'lodash';
import { withRouter, } from 'react-router';
import { connect, } from 'react-redux';
import { compose, lifecycle, withHandlers, withProps, } from 'recompose';
import queryString from 'query-string';


export default (storageKey, stringify, parse, preserve = f => f, restore = f => f) =>
  compose(
    connect(
      state => ({
        user: state.auth.user,
        company: state.auth.company,
      }),
    ),
    withProps(props => {
      const { user, company, } = props;
      const base = typeof storageKey === 'function' ? storageKey(props) : storageKey;
      return {
        storageKeyId: `${base}-${user?.id || null}-${company?.id || null}`
      };
    }),
    withRouter,
    withProps((props) => {
      const {
        history,
        onChange,
      } = props;

      return ({
        onLocationChange: onChange,
        onChange:
          (values) => {
            history.replace({ search: stringify(values, props), state: { search: true, } });
          },
      });
    }),
    withHandlers({
      handleChangeLocation:
        (props) =>
          (location) => {
            const {
              storageKeyId,
              history,
              onLocationChange,
            } = props;

            let f;

            try {
              f = restore(JSON.parse(window.localStorage.getItem(storageKeyId)), props);
            }
            catch (ex) {
              console.error(ex);
            }

            f = f || {};

            if (!location.search && (!location.state || !location.state.search)) {
              history.replace({ search: stringify(f, props), state: { search: true, } });
            }
            else {
              let prev = f;
              f = parse(location.search, props, prev);
              window.localStorage.setItem(storageKeyId, JSON.stringify(preserve(f, props, prev)));

              onLocationChange(f);
            }
          },
    }),
    lifecycle({
      componentDidMount() {
        const { location, handleChangeLocation, } = this.props;
        handleChangeLocation(location);
      },
      componentDidUpdate(prev) {
        const { location, handleChangeLocation, } = this.props;
        if (location.search !== prev.location.search || !_.isEqual(location.state, prev.location.state)) {
          handleChangeLocation(location);
        }
      },
    }),
  );

export function stringify(s) {
  return queryString.stringify(s, { arrayFormat: 'comma', sort: false, });
}

export function parse(search) {
  return queryString.parse(search, { arrayFormat: 'comma', });
}

export function stringifyArray(v, d = []) {
  return (!v || _.isEqual(v, d)) ? undefined : (v?.length ? v : null);
}

export function parseArray(v, d = []) {
  return _.isArray(v) ? v : v ? [v] : (v === null ? [] : d);
}
