import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Table, } from 'antd';

import { sortByField, } from 'src/helpers/sorter';



const Styles = styled.div`
  overflow: hidden;

  .ant-table.ant-table-small {
    th {
      font-size: 13px;
      color: rgb(120, 129, 149);
    }
    td {
      font-size: 12px;
    }
  }
  
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    word-break: break-all;
  }

  .ant-table-placeholder {
    z-index: auto;
  }

  .nowrap {
    white-space: nowrap;
  }

  .ant-table-footer {
    color: rgb(120, 129, 149);
    font-size: 12px;
  }

  .ant-table .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }
`;

function generateTableRowKey(item) {
  const keys = Object.keys(item);

  if (!!~keys.indexOf('id')) return item.id;
}

const Component = props => {
  let { id, dataSource, columns, groupBy, pagination = {}, aggregate } = props;

  if (pagination !== false) {
    const storedSize = localStorage.getItem(`table-${id}-size`);

    let pageSize,
      pageSizeOptions = pagination.pageSizeOptions;

    if (pageSizeOptions) {
      pageSize = Number(storedSize) || pagination.pageSize || pagination.defaultPageSize || pageSizeOptions[0];
    }
    else {
      pageSize = Number(storedSize) || 100;
      pageSizeOptions = ['20', '50', '100', '200', '500'];
    }

    pagination = {
      showSizeChanger: true,
      defaultCurrent: 1,
      hideOnSinglePage: true,
      pageSize,
      pageSizeOptions: pageSizeOptions.map(String),
      showTotal: value => `Total: ${value}`,
      onShowSizeChange: (current, size) => {
        id && localStorage.setItem(`table-${id}-size`, size);
        pagination.pageSize = size;
      },
      ...pagination,
    };
  }

  let expandedRowRender, grouppedDataSource;

  if (groupBy) {
    const groupped = _.groupBy(dataSource, groupBy);

    grouppedDataSource = Object.entries(groupped).reduce((acc, [key]) => (
      acc.concat({
        [groupBy]: key,
        [aggregate]: _.sumBy(groupped[key], aggregate),
        children: {
          dataSource: groupped[key],
          columns: _.filter(columns, item => item.dataIndex !== groupBy),
        },
      })
    ), []);

    expandedRowRender = item => <Component columns={item.children.columns} dataSource={item.children.dataSource} />;
  }

  const columnsReady = groupBy ? _.filter(columns, item => item.dataIndex === groupBy || item.dataIndex === aggregate) : columns;

  if (columnsReady.length > 0 && ['day', 'week', 'month', 'year'].includes(columnsReady[0].dataIndex)) {
    if (groupBy) {
      columnsReady.forEach(item => {
        delete item.sorter;
      });
    } else {
      dataSource = _.orderBy(dataSource, ['sorter'], ['desc'])
    }
  }

  columnsReady.forEach(item => {
    switch (typeof item.sorter) {
      case 'string': item.sorter = sortByField(item.sorter); break;
      case 'boolean': item.sorter = sortByField(item.dataIndex); break;
      default:
    }
  });

  return (
    <Styles>
      <Table
        bordered
        size="small"
        rowKey={generateTableRowKey}
        expandedRowRender={expandedRowRender || null}
        {...props}
        columns={columnsReady}
        dataSource={grouppedDataSource ? grouppedDataSource : dataSource}
        pagination={pagination}
        showSorterTooltip={false}
      />
    </Styles>
  );
};

export default Component;
