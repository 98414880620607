import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { branch, compose, withProps, renderComponent, lifecycle } from 'recompose';
import { Spin } from 'antd';
import { Helmet, } from 'react-helmet';
import PopUp from 'react-new-window';

import asyncComponent from 'src/helpers/asyncFunc';

import { AuthActions, RemoteConfigActions, } from 'src/redux/actions';

import App from 'src/containers/App/App';


const Spinner = () => (
  <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Spin style={{ fontSize: '100px', width: '100px', height: '100px', marginBottom: '200px' }} />
  </div>
);

export default compose(
  connect(
    state => ({
      title: state.app.title,
      user: state.auth.user,
      authorized: state.auth.authorized,
      popup: state.accounts.popup,
    }),
    {
      authorize: AuthActions.authorize,
      fetchRemoteConfig: RemoteConfigActions.fetch,
    }
  ),
  withProps(({
    authorized,
    authorize,
  }) => {
    authorized === null && authorize();
  }),
  branch(
    ({ authorized }) => authorized === false,
    renderComponent(Spinner)
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchRemoteConfig();
    }
  }),
)(({
  history,
  user,
  authorized,
  title,
  popup,
}) => (
  <ConnectedRouter history={history}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Switch>
      <Route key="404" exact path="/404" component={asyncComponent(_ => import('./screens/public/404'))} />,
      <Route key="ref" exact path="/ref" component={asyncComponent(_ => import('./screens/public/refferal-link'))} />,
      <Route key="invite" exact path="/invite" component={asyncComponent(_ => import('./screens/public/invitation'))} />,
      <Route key="instructions" path="/instructions" component={asyncComponent(_ => import('./screens/public/instructions'))} />,
      <Route key="policy" path="/policy" component={asyncComponent(_ => import('./screens/public/policy'))} />,
      {user === null
        ? ([
          <Route key="index" exact path="/" component={asyncComponent(_ => import('./screens/public/signin'))} />,
          <Route key="signin" exact path="/signin" component={asyncComponent(_ => import('./screens/public/signin'))} />,
          <Route key="signup" exact path="/signup" component={asyncComponent(_ => import('./screens/public/signup'))} />,
          <Route key="forgotpassword" exact path="/forgotpassword" component={asyncComponent(_ => import('./screens/public/forgot-password'))} />,
          <Route key="password-reset" exact path="/password-reset" component={asyncComponent(_ => import('./screens/public/reset-password'))} />,
          <Route key="confirm" exact path="/confirm" component={asyncComponent(_ => import('./screens/public/confirm-email'))} />,
          !!authorized && <Redirect key="authorized" to={{ pathname: "/signin", state: { path: window.location.pathname + window.location.search, } }} />,
        ]) : ([
          <Redirect key="signin" from="/signin" to="/" />,
          <Redirect key="signup" from="/signup" to="/" />,
          <Route key="backurl" path="/backurl/:type?" component={asyncComponent(_ => import('./screens/backurl'))} />,
          <Route key="app" render={props => <App {...props} />} />
        ])
      }
    </Switch>
    {popup && <PopUp {...popup} />}
  </ConnectedRouter>
));
