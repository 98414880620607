import React from 'react';
import moment from 'moment';
import { compose, withHandlers, withPropsOnChange, branch, withProps, } from 'recompose';
import { DatePicker, } from 'antd';

import withPresets from './with-presets';


export default compose(
  branch(
    ({ presets }) => !!presets,
    compose(
      withHandlers({
        onChange:
          ({ onChange, }) =>
            ([from, to, preset]) => {
              onChange([to, preset]);
            },
      }),
      withProps(({ value: [value, preset], }) => ({ value, preset, })),
      withPresets,
      withHandlers({
        onChange:
          ({ onChange, }) =>
            (to) => {
              onChange([null, to]);
            },
      }),
    ),
  ),
  withHandlers({
    onChange:
      ({ onChange, }) =>
        (date, dateString) => {
          onChange(dateString);
        },
  }),
  withPropsOnChange(
    ['value'],
    ({ value, }) => ({
      value: value
        ? moment(value, 'YYYY-MM-DD')
        : null,
    })
  ),
)(props => (
  <DatePicker
    showToday={false}
    {...props}
  />
));
