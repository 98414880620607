import { compose, withProps, setStatic, } from 'recompose';
import styled from 'styled-components';

import '@ant-design/compatible/assets/index.css';
import { Form } from '@ant-design/compatible';


const Styles = styled(Form)`
  &.ant-legacy-form-vertical {
    .ant-legacy-form-item {
      margin-bottom: 8px;
      padding-bottom: 4px;
    }
    .ant-legacy-form-item-label {
      label {
        white-space: nowrap;
      }
    }
  }
`;


export default compose(
  setStatic('Item', Form.Item),
  setStatic('create', Form.create),
  withProps(() => ({
    layout: 'vertical',
  })),
)(Styles);
