import { createBrowserHistory } from 'history';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import promiseMiddleware from 'redux-promise';
import { persistStore, } from 'redux-persist';

import rootReducer from './reducers';
import rootSaga from './sagas';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({
    router: connectRouter(history),
    ...rootReducer,
  }),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      promiseMiddleware,
      thunkMiddleware,
      sagaMiddleware,
    ),
  ),
);

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor, history };
