import { all, call, fork, put, take } from 'redux-saga/effects';

import { getApiErrorText } from 'src/helpers/errors-api';

import API from 'src/service/api';

import { createFetchGenerator } from '../helpers';

import { AppActions } from '../actions';

import actions from './actions';


function* sagaCreateSetting() {
  while (true) {
    const { payload } = yield take(actions.createSetting);

    const rs = yield call(API.createProfileSetting, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Profile Settings were updated.`,
      }));

      yield put(actions.fetchSettings());
    }
  }
}

function* sagaUpdateSetting() {
  while (true) {
    const { payload } = yield take(actions.updateSetting);

    const rs = yield call(API.updateProfileSetting, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Profile Settings were updated.`,
      }));

      yield put(actions.fetchSettings());
    }
  }
}

function* sagaDeleteSetting() {
  while (true) {
    const { payload } = yield take(actions.deleteSetting);

    const rs = yield call(API.deleteProfileSetting, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Profile Settings were updated.`,
      }));

      yield put(actions.fetchSettings());
    }
  }
}


const sagas = [

  createFetchGenerator('fetchSettings', 'fetchProfileSettings', actions),
  sagaCreateSetting,
  sagaDeleteSetting,
  sagaUpdateSetting,

];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
