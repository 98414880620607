import { combineReducers } from 'redux';

import charts from './charts/reducer';
import dashboards from './dashboards/reducer';
import events from './events/reducer';
import segments from './segments/reducer';

export default combineReducers({
  charts,
  dashboards,
  events,
  segments,
});

