import styled from 'styled-components';

const DashAppHolder = styled.div`
  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .charts{
    .highcharts-container, .highcharts-container svg {
      width: 100% !important;
    }
  }
  
  .ant-spin-nested-loading{
    width: 100%;
  }

  .ant-table-filter-dropdown-btns{
    padding: 7px 12px;
  }
  
  .ant-dropdown {
    z-index: 1000;
  }

  .magnus-analytics-tabbar-tips {
    display: flex;
    position: fixed;
    top: 79px;
    right: 20px;
    z-index: 3;
  }

  .ant-typography {
    color: rgba(0, 0, 0, 0.65);
  }
  h4.ant-typography {
    font-size: 18px; 
  }
`;

export default DashAppHolder;
