import asyncComponent from 'src/helpers/asyncFunc';

export const modals = [
  {
    id: 'edit-variator-campaign',
    title: 'Campaign',
    component: asyncComponent(_ => import('./campaigns/edit')),
    preventCloseOnSubmit: true,
  },
  {
    id: 'edit-variator-creative',
    title: 'Creative',
    component: asyncComponent(_ => import('./creatives/edit')),
    preventCloseOnSubmit: true,
  },
  {
    id: 'edit-variator-placement',
    title: 'Trigger',
    component: asyncComponent(_ => import('./placements/edit')),
    preventCloseOnSubmit: true,
  },
  {
    id: 'update-variator-segment',
    title: 'Edit Segment',
    component: asyncComponent(_ => import('./campaigns/edit-segment')),
  },
  {
    id: 'edit-variator-product',
    title: 'Product',
    component: asyncComponent(_ => import('./products/edit')),
    preventCloseOnSubmit: true,
  },
  {
    id: 'edit-variator-promo-target',
    title: 'Promo Target',
    component: asyncComponent(_ => import('./promo-targets/edit')),
    preventCloseOnSubmit: true,
  },
];