import { handleActions } from 'redux-actions';

import actions from './actions';

const defaultState = {
  events: null,
  params: null,
};

export default handleActions(
  {
    [actions.fetchEventsSuccess]: (state, { payload }) => ({ ...state, events: payload, }),
    [actions.fetchParamsSuccess]: (state, { payload }) => ({ ...state, params: payload, }),
    [actions.clearParams]: (state) => ({ ...state, params: null, }),
    [actions.clear]: () => defaultState,
  },
  defaultState
)
