import React from 'react';
import { compose, withHandlers, withStateHandlers } from 'recompose';
import { Input } from 'antd';

export default compose(
  withStateHandlers(
    ({ defaultValue }) => ({ value: defaultValue }),
    {
      change: _ => value => ({ value }),
    },
  ),
  withHandlers({
    handleKeyUp: ({ toggle }) => e => {
      if ((e.keyCode === 27 || e.keyCode === 13) && toggle) {
        toggle(false);
      }
    },
    handleChange: ({ onValueChange, change }) => e => {
      change(e.target.value);
      onValueChange(e.target.value);
    },
  }),
)(({ handleChange, handleKeyUp, value }) => (
  <Input
    ref={input => input && input.focus()}
    value={value}
    onChange={handleChange}
    onKeyUp={handleKeyUp}
  />
));
