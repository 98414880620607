import _ from 'lodash';
import { handleActions, } from 'redux-actions';

import actions from './actions';

const defaultState = {
  savedCharts: null,
};

export default handleActions(
  {
    [actions.fetchSavedChartsSuccess]: (state, { payload }) => ({
      ...state,
      savedCharts: _.orderBy(payload, ['id'], ['desc']),
    }),
    [actions.fetchSavedChartsError]: (state, { payload }) => ({
      ...state,
      savedCharts: [],
    }),
    [actions.clear]: () => defaultState,
  },
  defaultState
)
