import { all, call, fork, take, put, } from 'redux-saga/effects';

import API from 'src/service/api';
import { getApiErrorText, } from 'src/helpers/errors-api';

import { createFetchGenerator, processError, } from '../../helpers';

import { AppActions, } from '../../actions';


import actions from './actions';

function* sagaCreatePromoTarget() {
  while (true) {
    const { payload } = yield take(actions.createPromoTarget);

    const rs = yield call(API.createVariatorPromoTarget, payload);

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Target ${payload.title} was added.`,
      }));

      yield put(AppActions.toggleModalForm(null));

      yield put(actions.fetchPromoTargets());
    }
  }
}

function* sagaUpdatePromoTarget() {
  while (true) {
    const { payload } = yield take(actions.updatePromoTarget);

    const rs = yield call(API.updateVariatorPromoTarget, payload);

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Target ${payload.title} was updated.`,
      }));

      yield put(AppActions.toggleModalForm(null));

      yield put(actions.fetchPromoTargets());
    }
  }
}

function* sagaDeletePromoTarget() {
  while (true) {
    const { payload } = yield take(actions.deletePromoTarget);

    const rs = yield call(API.deleteVariatorPromoTarget, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Target ${payload.title} was deleted.`,
      }));

      yield put(actions.fetchPromoTargets());
    }
  }
}

const sagas = [
  createFetchGenerator('fetchPromoTargets', 'fetchVariatorPromoTargets', actions),

  sagaCreatePromoTarget,
  sagaUpdatePromoTarget,
  sagaDeletePromoTarget,
];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
