import { all, } from 'redux-saga/effects';

import config from './config/saga';
import experiments from './experiments/saga';

export default function* () {
  yield all([
    config(),
    experiments(),
  ]);
}
