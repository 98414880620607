import { compose, toClass, setStatic, withProps, } from 'recompose';
import { Select } from 'antd';

export default compose(
  setStatic('Option', Select.Option),
  setStatic('OptGroup', Select.OptGroup),
  toClass,
  withProps(({
    getPopupContainer,
  }) => ({
    getPopupContainer: getPopupContainer === undefined ? (trigger => trigger.parentNode) : getPopupContainer,
  }))
)(Select);
