import { handleActions } from 'redux-actions';

import actions from './actions';


const defaultState = {
  authorized: null,
  user: null,
  profile: null,
  token: null,
  company: null,
  companies: null,
  sandbox: Object.create(null),
};

export default handleActions(
  {
    [actions.setSandbox]:
      (state, { payload }) => ({
        ...state,
        sandbox: {
          ...state.sandbox,
          ...payload,
        },
      }),

    [actions.clearSandbox]:
      state =>
      ({
        ...state,
        sandbox: defaultState.sandbox,
      }),

    [actions.authorize]:
      state =>
        (state.authorized === null ? { ...state, authorized: false } : { ...state }),

    [actions.authorizeSuccess]:
      (state, { payload = {} }) => ({
        ...state,
        authorized: true,
        user: payload.user || null,
        token: payload.token || null,
        company: payload.company || null,
        companies: payload.companies || null,
      }),

    [actions.setUser]:
      (state, { payload = {} }) => {
        const { user } = payload;

        const oldUser = { ...state.user };

        oldUser.name = user.name;
        oldUser.email = user.email;
        oldUser.avatar = user.avatar;

        return { ...state, user: oldUser };
      },

    [actions.setCompany]:
      (state, { payload = {} }) => ({
        ...state,
        company: payload.company || null,
        companies: payload.companies || null,
      }),

    [actions.unsetCompany]:
      (state, { payload = {} }) => {
        return {
          ...state,
          company: null,
          companies: null,
        };
      },

    [actions.fetchProfileSuccess]:
      (state, { payload }) => ({
        ...state,
        profile: payload,
      }),
  },
  defaultState
)
