import _ from 'lodash';
import React from 'react';
import { compose, toClass, withProps, } from 'recompose';

import { SelectMultiple, Select, } from './';

import { withMultipleModeProcessor, } from './select-multiple';


export default compose(
  toClass,
  withProps(() => ({
    mode: 'multiple',
  })),
  withMultipleModeProcessor(),
)(React.forwardRef(({ options, onChange, ...others }, ref) => {
  options = _.groupBy(
    options && options.sort(),
    i =>
      /unknown/i.test(i)
        ? 'Unknown'
        : /organic/i.test(i)
          ? 'Organic'
          : 'Non-Organic'
  );

  return (
    <SelectMultiple
      ref={ref}
      allowClear
      optionFilterProp="name"
      onChange={onChange}
      {...others}
    >
      {
        _.map(options, (g, gname) => (
          <Select.OptGroup key={gname} label={<span style={{ cursor: 'pointer', }} onClick={onChange.bind(null, g)}>{gname}</span>}>
            {g.map(item =>
              <Select.Option
                key={item}
                value={item}
                name={item}
              >
                {item}
              </Select.Option>
            )}
          </Select.OptGroup>
        ))
      }
    </SelectMultiple>
  );
}));
