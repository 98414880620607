import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, withProps, } from 'recompose';
import { withRouter } from 'react-router';

import { mapActions, AppActions, MagnusActions, FilterActions, RevenueActions, SDKActions, } from 'src/redux/actions';

import { Layout, } from 'src/components/ui';

import TopbarWrapper from './style';
import Breadcrumbs from './breadcrumbs';
import TopbarUser from './topbarUser';
import TopbarSettings from './topbarSettings'

import FilterApps from './filter-apps';
import FilterDates from './filter-dates';


export default compose(
  withRouter,
  connect(
    state => ({
      app: state.app,
      filter: state.filter,
      company: state.auth.company,
      apps: state.magnus.apps,
      products: state.revenue.products,
      projects: state.sdk.projects,
      projectNotes: state.sdk.projectNotes,
    }),
    {
      ...mapActions([
        'toggleCollapsed',
      ], AppActions),
      ...mapActions([
        'restoreFilter',
      ], FilterActions),
      ...mapActions([
        'fetchApps',
      ], MagnusActions),
      ...mapActions([
        'fetchProjects',
        'fetchProjectNotes',
      ], SDKActions),
      ...mapActions([
        'fetchRevenueProducts',
      ], RevenueActions),
    },
  ),
  lifecycle({
    componentDidMount() {
      const {
        filter,
        restoreFilter,
        apps,
        fetchApps,
        projects,
        fetchProjects,
        projectNotes,
        fetchProjectNotes,
        products,
        fetchRevenueProducts,
      } = this.props;

      if (!filter.restored) {
        restoreFilter();
      }
      if (!apps) {
        fetchApps();
      }
      if (!projects) {
        fetchProjects();
      }
      if (!projectNotes) {
        fetchProjectNotes();
      }
      if (!products) {
        fetchRevenueProducts();
      }
    },
  }),
  withProps(({
    app: { collapsed, openDrawer },
  }) => ({
    collapsed: collapsed && !openDrawer,
  })),
)(({
  filter: { showAppsFilter, showDatesFilter, },
  company,
  collapsed,
  toggleCollapsed,
  location,
}) => (
  <TopbarWrapper>
    <Layout.Header
      style={{
        position: 'fixed',
        width: '100%',
        height: 70,
      }}
      className={collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'}
    >
      <div className="isoLeft">
        <button
          className={collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'}
          onClick={toggleCollapsed}
        >
          <i className='ion-md-menu' />
        </button>
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16, }}>
          <Breadcrumbs pathname={location.pathname} />
          {showAppsFilter ? <FilterApps /> : null}
        </div>
        {showDatesFilter ? <FilterDates /> : null}
      </div>
      <ul className="isoRight">
        {company && (
          <li>
            <TopbarSettings />
          </li>
        )}
        <li className="isoUser">
          <TopbarUser />
        </li>
      </ul>
    </Layout.Header>
  </TopbarWrapper>
));
