import moment from 'moment';
import { compose, withHandlers, withPropsOnChange, withProps, branch, toClass, } from 'recompose';
import { DatePicker, } from 'antd';

import withPresets from './with-presets';


export default compose(
  toClass,
  branch(
    ({ presets }) => !!presets,
    compose(
      withProps(({ value: [from, to, preset], }) => ({ value: [from, to], preset, })),
      withPresets,
    ),
  ),
  withHandlers({
    onChange:
      ({ onChange, }) =>
        (dates, dateStrings) => {
          onChange(dateStrings);
        },
  }),
  withPropsOnChange(
    ['value'],
    ({ value, }) => ({
      value: [
        value && value[0] ? moment(value[0], 'YYYY-MM-DD') : null,
        value && value[1] ? moment(value[1], 'YYYY-MM-DD') : null,
        value && value[2] ? value[2] : null,
      ],
    })
  ),
)(DatePicker.RangePicker);
