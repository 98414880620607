import React from 'react';
import { Route, Switch, Redirect, withRouter, } from 'react-router-dom';
import { compose, } from 'recompose';

import asyncComponent from 'src/helpers/asyncFunc';
import render404 from 'src/helpers/render-404';


const routes = [
  {
    path: '/',
    component: asyncComponent(_ => import('../../screens/home/index')),
    exact: true,
  },
  {
    path: '/companies',
    component: asyncComponent(_ => import('../../screens/companies/index')),
  },
  {
    path: '/profile',
    component: asyncComponent(_ => import('../../screens/profile/index')),
  },
  {
    path: '/:company',
    component: asyncComponent(_ => import('./CompanyRouter')),
  },
];


export default compose(
  withRouter,
)(() => (
  <Switch>
    <Redirect from={`/Apptember/**`} to={`/Wowmaking/**`} />
    {routes.map(({ path, ...rest }) => (
      <Route key={path} path={path} {...rest} />
    ))}
    <Route component={render404()} />
  </Switch>
));
