import _ from 'lodash';
import React from 'react';
import { connect, } from 'react-redux';
import { compose, withState, withHandlers, withProps, branch, renderNothing, } from 'recompose';
import { DownOutlined, AppstoreFilled, StarOutlined, StarFilled } from '@ant-design/icons';
import styled from 'styled-components';

import { store } from 'src/redux/store';
import { mapActions, FilterActions, SettingsActions, } from 'src/redux/actions';

import { Row, Col, Switch, Button, Popover, Tree, Input, IconPlatform, TitlePlatform, } from 'src/components/ui';
import { parseArray, } from 'src/components/filter/location';


const FAV_COLOR = '#9e850055';

const StyledTree = styled(Tree)`
  .ant-tree-treenode {
    width: 100%;
    justify-content: stretch;
  }
  .ant-tree-node-content-wrapper{
    flex-grow: 2;
  }
`;

export default compose(
  connect(
    state => ({
      filter: state.filter,
      apps: state.magnus.apps,
      favApps: _.filter(state.settings.data, { type: 'favourite_apps' })?.[0],
    }),
    {
      ...mapActions([
        'updateAppsFilter',
      ], FilterActions),
      ...mapActions([
        'createSetting',
        'updateSetting',
        'deleteSetting',
      ], SettingsActions),
    }
  ),
  branch(({ apps, filter }) => !apps || !filter.restored, renderNothing),
  withHandlers({
    renderText:
      ({
        apps,
        filter,
      }) =>
        () => {
          if (filter.apps.length === apps.length) return 'All apps';
          switch (filter.apps.length) {
            case 0: return 'Select Apps';
            case 1: return <TitlePlatform app={apps.find(item => filter.apps.includes(item.id))} />;
            default: return `${filter.apps.length} apps`;
          }
        },
  }),
  withState('searchValue', 'setSearchValue', ''),
  withState('searchFav', 'setSearchFav', false),
  withState('data', 'setData', ({ filter }) => _.cloneDeep(filter)),
  withProps(
    ({
      favApps,
    }) => ({
      fav: favApps?.data || [],
    }),
  ),
  withHandlers({
    handleApply:
      ({
        data,
        updateAppsFilter,
      }) =>
        () => {
          updateAppsFilter({ apps: data.apps, });
        },
    handleChange:
      ({
        setData,
      }) =>
        (value) => {
          setData({ apps: value.map(i => +i), });
        },
    handleShowPublishersChange:
      ({
        updateAppsFilter,
      }) =>
        (value) => {
          updateAppsFilter({ showPublishers: value, });
        },
    handleFavClick:
      ({
        fav,
        favApps,
        createSetting,
        updateSetting,
        deleteSetting,
      }) =>
        (id) => {
          let data = [...fav];
          if (_.includes(data, id)) {
            data = _.without(data, id);
          } else {
            data = [...data, id];
          }

          if (favApps) {
            if (data.length) {
              updateSetting({
                ...favApps,
                data,
              });
            } else {
              deleteSetting(favApps);
            }
          } else {
            createSetting({
              name: 'Favourite Apps',
              type: 'favourite_apps',
              data,
            });
          }
        },
  }),
  withProps(
    ({
      filter,
      apps,
      searchValue,
      searchFav,
      fav,
      handleFavClick,
    }) => ({
      nodes: getAppsNodesFromApps(apps, searchValue, searchFav, filter.showPublishers, fav, handleFavClick),
    }),
  ),
  withState('visible', 'setVisible', false),
  withHandlers({
    handleVisibleChange:
      ({
        filter,
        setVisible,
        setData,
        handleApply,
      }) =>
        (v) => {
          if (v) {
            setData(_.cloneDeep(filter));
          } else {
            handleApply();
          }
          setVisible(v);
        },
  }),
)(({
  visible,
  filter,
  apps,
  renderText,
  data,
  nodes,
  handleShowPublishersChange,
  searchValue,
  setSearchValue,
  searchFav,
  setSearchFav,
  handleChange,
  handleVisibleChange,
  fav,
}) => (
  <Popover
    visible={visible}
    content={
      <div>
        <Row type="flex" justify="space-between"
          style={{ paddingBottom: 8, borderBottom: '2px #eee solid' }}>
          <Row gutter={8}>
            <Col xs={8}>
              <Button
                onClick={() => handleChange(_.map(_.filter(apps, a => a.platform === 'ios'), 'id'))}
              >
                <IconPlatform platform={'ios'} />
              </Button>
            </Col>
            <Col xs={8}>
              <Button
                onClick={() => handleChange(_.map(_.filter(apps, a => a.platform === 'android'), 'id'))}
              >
                <IconPlatform platform={'android'} />
              </Button>
            </Col>
            <Col xs={8}>
              <Button
                onClick={() => handleChange(_.map(_.filter(apps, a => a.platform === 'web'), 'id'))}
              >
                <IconPlatform platform={'web'} />
              </Button>
            </Col>
          </Row>
          <Input.Search
            placeholder="Search"
            value={searchValue}
            allowClear
            style={{ width: '60%' }}
            onChange={e => setSearchValue(e.target.value)}
            suffix={
              searchFav
                ? <StarFilled style={{ fontSize: 20, color: FAV_COLOR, }} onClick={() => setSearchFav(false)} />
                : <StarOutlined style={{ fontSize: 20, color: FAV_COLOR, }} onClick={() => setSearchFav(true)} />
            }
          />
        </Row>
        <Row type="flex" justify="space-between"
          style={{ paddingBottom: 8, paddingTop: 8, borderBottom: '2px #eee solid', alignItems: 'center' }}>
          <Col>
            <Switch
              size="small"
              checked={filter.showPublishers}
              onChange={handleShowPublishersChange}
            /> Show publishers
          </Col>
          <Col style={{ paddingRight: '3px' }}>
            <Button
              size="small"
              onClick={() => handleChange(_.map(apps, 'id'))}
            >
              <span>All</span>
            </Button>
            <span> | </span>
            <Button
              size="small"
              onClick={() => handleChange(_.intersection(_.map(apps, 'id'), fav))}
            >
              <span>Fav</span>
            </Button>
            <span> | </span>
            <Button
              size="small"
              onClick={() => handleChange([])}
            >
              <span>None</span>
            </Button>
          </Col>
        </Row>
        <div style={{ height: '50vh', overflow: 'auto', }}>
          <StyledTree
            nodes={nodes}
            checked={data.apps}
            showIcon
            handleData={handleChange}
          />
        </div>
      </div>
    }
    onVisibleChange={handleVisibleChange}
    trigger="click"
    placement="bottomLeft"
    overlayStyle={{ width: '480px', height: '1vh' }}
  >
    <div style={{ display: 'flex', alignItems: 'flex-end', }}>
      <Button size="small" style={{ border: 0, fontSize: '14px', boxShadow: 'none', padding: 0, }}>
        {renderText()} <DownOutlined style={{ margin: '0 0 0 5px', fontSize: '12px', fontWeight: 'bold' }} />
      </Button>
    </div>
  </Popover >
));



function getAppsNodesFromApps(apps, searchValue, searchFav, showPublishers, fav, handleFavClick) {
  searchValue = searchValue.trim().toLowerCase();

  apps = _.map(apps, a => ({
    ...a,
    hidden:
      (
        searchValue.length > 0
        &&
        !~a.name.toLowerCase().indexOf(searchValue)
        &&
        !~(a.publisher?.name || '').toLowerCase().indexOf(searchValue)
      )
      ||
      (searchFav && !_.includes(fav, a.id)),
  }));

  let nodes;
  if (showPublishers) {
    nodes = _.groupBy(apps, i => _.get(i, 'publisher.id', 'web'));
    nodes = _.toPairs(nodes);
    nodes = nodes.reduce((acc, [key, value]) => {
      acc.push({
        name: value[0].publisher?.name || 'WEB',
        hidden: !_.find(value, i => !i.hidden),
        icon: <IconPlatform platform={value[0].platform} />,
        value: String(key),
        children: mapAppsNodes(value, fav, handleFavClick),
      });
      return acc;
    }, []);
    nodes = _.sortBy(nodes, i => i.name.toLowerCase());
  }
  else {
    nodes = mapAppsNodes(apps, fav, handleFavClick);
  }

  return [
    {
      name: 'All',
      icon: <AppstoreFilled />,
      children: nodes,
    },
  ];
}

function mapAppsNodes(apps, fav, handleFavClick) {
  return _.sortBy(apps.map(i => ({
    name: i.name,
    title: (
      <>
        <span style={{ flexGrow: 100 }}>{i.name}</span>
        {
          _.includes(fav, i.id)
            ? <StarFilled
              onClick={(e) => { e.stopPropagation(); handleFavClick(i.id); }}
              style={{ position: 'absolute', right: 10, top: 2, fontSize: 20, color: FAV_COLOR, }}
            />
            : <StarOutlined
              onClick={(e) => { e.stopPropagation(); handleFavClick(i.id); }}
              style={{ position: 'absolute', right: 10, top: 2, fontSize: 20, color: FAV_COLOR, }}
            />
        }
      </>
    ),
    hidden: i.hidden,
    value: String(i.id),
    icon: <IconPlatform platform={i.platform} />,
  })), i => i.name.toLowerCase());
}


export function stringifyApps(values) {
  return {
    apps: values.app_ids,
  }
}

export function parseApps(s) {
  return {
    app_ids: parseArray(s.apps).map(Number),
  };
}

export const preserveFilterApps = (f) => {
  return _.omit(f, 'app_ids');
}

export const restoreFilterApps = () => {
  const { filter } = store.getState();
  return ({
    app_ids: filter?.apps,
  });
}
