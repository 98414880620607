import { createActions } from 'redux-actions';
import { createSuccessErrorActions } from '../helpers';

export default createActions(
  'CLEAR_DATA',

  ...createSuccessErrorActions('FETCH_FINANCE_EXPENSES'),
  'CREATE_FINANCE_EXPENSES',
  'DELETE_FINANCE_EXPENSES',
  'UPDATE_FINANCE_EXPENSES',
  
  ...createSuccessErrorActions('FETCH_FINANCE_NETWORKS'),

  ...createSuccessErrorActions('FETCH_EXPENSES_TRACKERS'),
  'CREATE_EXPENSES_TRACKER',
  'DELETE_EXPENSES_TRACKER',
  'UPDATE_EXPENSES_TRACKER',
  
  { prefix: 'FINANCE', }

);
