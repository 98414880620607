import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { compose, withHandlers, defaultProps, lifecycle, withState, withPropsOnChange, } from 'recompose';

import { Form, Modal, } from 'src/components/ui';

import { AppActions } from 'src/redux/actions';

import asyncComponent from 'src/helpers/asyncFunc';

import { modals as SDKModals, } from 'src/screens/sdk/modals';


const modals = [
  {
    id: 'create-publisher-android',
    title: 'Add Android publisher',
    component: asyncComponent(_ => import('src/screens/accounts/publishers/edit-android')),
  },
  {
    id: 'update-publisher-android',
    title: 'Edit Android Publisher',
    component: asyncComponent(_ => import('src/screens/accounts/publishers/edit-android')),
  },
  {
    id: 'create-publisher-ios',
    title: 'Add iOS Publisher',
    component: asyncComponent(_ => import('src/screens/accounts/publishers/edit-ios')),
    preventCloseOnSubmit: true,
  },
  {
    id: 'update-publisher-ios',
    title: 'Edit iOS Publisher',
    component: asyncComponent(_ => import('src/screens/accounts/publishers/edit-ios')),
    preventCloseOnSubmit: true,
  },
  {
    id: 'create-company',
    title: 'Add Company',
    component: asyncComponent(_ => import('src/screens/companies/create-company')),
  },
  {
    id: 'create-role',
    title: 'Add a new role',
    component: asyncComponent(_ => import('src/screens/people/roles/create-role')),
  },
  {
    id: 'update-role',
    title: 'Edit role',
    component: asyncComponent(_ => import('src/screens/people/roles/update-role')),
  },
  {
    id: 'create-invitation',
    title: 'Add user to the company',
    component: asyncComponent(_ => import('src/screens/people/users/create-invitation')),
  },
  {
    id: 'approve-user',
    title: 'User approval',
    component: asyncComponent(_ => import('src/screens/people/users/approve-user')),
  },
  {
    id: 'update-available-apps',
    title: 'Edit Available Apps',
    component: asyncComponent(_ => import('src/screens/people/users/available-apps')),
  },
  {
    id: 'update-available-sources',
    title: 'Edit Available Sources',
    component: asyncComponent(_ => import('src/screens/people/users/available-sources')),
  },
  {
    id: 'create-project',
    title: 'Add Project',
    component: asyncComponent(_ => import('src/reusable/app-projects/project-edit')),
  },
  {
    id: 'update-project',
    title: 'Edit Project',
    component: asyncComponent(_ => import('src/reusable/app-projects/project-edit')),
  },
  {
    id: 'project-history',
    title: 'Project Settings History',
    component: asyncComponent(_ => import('src/reusable/app-projects/project-history')),
    width: 800,
  },
  {
    id: 'create-linked-account-appodeal',
    title: 'Add Appodeal Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-appodeal')),
  },
  {
    id: 'create-linked-account-search-ads',
    title: 'Add Search Ads Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-search-ads')),
  },
  {
    id: 'update-linked-account-search-ads',
    title: 'Edit Search Ads Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-search-ads')),
  },
  {
    id: 'update-linked-account-appodeal',
    title: 'Edit Appodeal Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-appodeal')),
  },
  {
    id: 'update-ads-account-fee',
    title: 'Edit Fee',
    component: asyncComponent(_ => import('src/screens/accounts/ads-accounts/update-ads-account-fee')),
  },
  {
    id: 'create-finance-expenses',
    title: 'Add Expenses',
    component: asyncComponent(_ => import('src/screens/analytics/overview/expenses/additional-expenses/update-finance-expenses')),
  },
  {
    id: 'update-finance-expenses',
    title: 'Edit Expenses',
    component: asyncComponent(_ => import('src/screens/analytics/overview/expenses/additional-expenses/update-finance-expenses')),
  },
  {
    id: 'create-linked-account-adjust',
    title: 'Add Adjust Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-adjust')),
  },
  {
    id: 'update-linked-account-adjust',
    title: 'Edit Adjust Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-adjust')),
  },
  {
    id: 'create-linked-account-appsflyer',
    title: 'Add Appsflyer Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-appsflyer')),
  },
  {
    id: 'update-linked-account-appsflyer',
    title: 'Edit Appsflyer Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-appsflyer')),
  },
  {
    id: 'update-linked-account-appsflyer-fee',
    title: 'Edit Appsflyer Fee',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-fees')),
  },
  {
    id: 'update-linked-account-adjust-fee',
    title: 'Edit Adjust Fee',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-fees')),
  },
  {
    id: 'create-linked-account-unity-ads',
    title: 'Add Unity Ads Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-unity-ads')),
  },
  {
    id: 'update-linked-account-unity-ads',
    title: 'Edit Unity Ads Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-unity-ads')),
  },
  {
    id: 'create-linked-account-unity-ads-expenses',
    title: 'Add Unity Ads Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-unity-ads-expenses')),
  },
  {
    id: 'update-linked-account-unity-ads-expenses',
    title: 'Edit Unity Ads Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-unity-ads-expenses')),
  },
  {
    id: 'create-linked-account-iron-source',
    title: 'Add ironSource Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-iron-source')),
  },
  {
    id: 'update-linked-account-iron-source',
    title: 'Edit ironSource Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-iron-source')),
  },
  {
    id: 'create-linked-account-iron-source-expenses',
    title: 'Add ironSource Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-iron-source-expenses')),
  },
  {
    id: 'update-linked-account-iron-source-expenses',
    title: 'Edit ironSource Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-iron-source-expenses')),
  },
  {
    id: 'create-linked-account-applovin',
    title: 'Add Applovin Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-applovin')),
  },
  {
    id: 'update-linked-account-applovin',
    title: 'Edit Applovin Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-applovin')),
  },
  {
    id: 'update-linked-account-tik-tok-expenses',
    title: 'Edit TikTok Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-tik-tok-expenses')),
  },
  {
    id: 'create-linked-account-paypal',
    title: 'Add PayPal Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-paypal')),
  },
  {
    id: 'update-linked-account-paypal',
    title: 'Edit PayPal Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-paypal')),
  },
  {
    id: 'create-linked-account-recurly',
    title: 'Add Recurly Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-recurly')),
  },
  {
    id: 'update-linked-account-recurly',
    title: 'Edit Recurly Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-recurly')),
  },
  {
    id: 'create-linked-account-solidgate',
    title: 'Add Solidgate Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-solidgate')),
  },
  {
    id: 'update-linked-account-solidgate',
    title: 'Edit Solidgate Account',
    component: asyncComponent(_ => import('src/screens/accounts/linked-accounts/edit-solidgate')),
  },
  {
    id: 'edit-company-note',
    title: 'Edit Note',
    component: asyncComponent(_ => import('src/screens/company/notes/edit')),
    preventCloseOnSubmit: true,
  },
  {
    id: 'edit-expenses-tracker',
    title: 'Edit Tracker',
    component: asyncComponent(_ => import('src/screens/company/trackers/edit')),
    preventCloseOnSubmit: true,
  },
  {
    id: 'create-android-app',
    title: 'Create Android App',
    component: asyncComponent(_ => import('src/screens/company/applications/append-android-app')),
    preventCloseOnSubmit: true,
  },
  {
    id: 'edit-number',
    component: asyncComponent(_ => import('./edit-number')),
    preventCloseOnSubmit: true,
  },
  {
    id: 'edit-select',
    component: asyncComponent(_ => import('./edit-select')),
    preventCloseOnSubmit: true,
  },
  ...SDKModals,
];

const ModalForm = compose(
  Form.create(),
  withState('validationErrors', 'setValidationErrors', null),
  withPropsOnChange(
    ['serverError'],
    ({
      form,
      serverError,
      setValidationErrors,
    }) => {
      if (serverError) {
        let values = form.getFieldsValue(),
          errors = {};

        _.forEach(serverError, (v, k) => {
          v = _.map(v, message => ({ message, }));
          form.setFields({
            [k]: {
              value: _.get(values, k),
              errors: v,
            },
          });
          _.set(errors, k, v);
        });

        setValidationErrors(errors);
      }
    },
  ),
  withHandlers({
    handleSubmit:
      ({
        form,
        handleData,
        close,
        data = {},
        preventCloseOnSubmit,
        setValidationErrors,
        clearServerError,
      }) =>
        e => {
          e.preventDefault();

          form.validateFields((errors, values) => {
            setValidationErrors(errors);

            if (!errors) {
              if (!preventCloseOnSubmit) {
                close();
              }

              clearServerError();

              try {
                Object.keys(data).forEach(item => {
                  const instance = form.getFieldInstance(item);

                  if (instance) {
                    if (values[item]?.trim) {
                      values[item] = values[item].trim();
                    }
                  }
                });
              } catch (e) {
                console.warn(e);
              }

              handleData({ ...data, ...values });
            }
          });
        },
  }),
  withHandlers({
    handleKeyPress:
      ({
        handleSubmit,
      }) =>
        e => {
          if (e.key === 'Enter') {
            handleSubmit(e);
          }
        },
  }),
)(({
  component: Component,
  title,
  width,
  handleSubmit,
  handleKeyPress,
  form,
  close,
  visible,
  data,
  extraData,
  validationErrors,
}) => (
  <Modal
    title={title}
    visible={visible}
    onCancel={close}
    maskClosable={false}
    footer={null}
    afterClose={_ => form.resetFields()}
    destroyOnClose={true}
    zIndex={1002}
    width={width}
  >
    <Form onSubmit={handleSubmit}>
      {visible && (
        <Component
          form={form}
          close={close}
          handleSubmit={handleSubmit}
          handleKeyPress={handleKeyPress}
          data={data}
          extraData={extraData}
          validationErrors={validationErrors}
        />
      )}
    </Form>
  </Modal>
)
);

export default compose(
  connect(
    state => ({
      modalForm: state.app.modalForm,
      modalFormError: state.app.modalFormError,
    }),
    {
      toggleModalForm: AppActions.toggleModalForm,
      setModalFormError: AppActions.setModalFormError,
    },
  ),
  defaultProps({ container: document.createElement('div') }),
  lifecycle({
    componentDidMount() {
      document.body.appendChild(this.props.container);
    },
    componentWillUnmount() {
      document.body.removeChild(this.props.container);
    }
  }),
)(({
  container,
  modalForm,
  modalFormError,
  toggleModalForm,
  setModalFormError,
}) => {
  let props = {
    close: _ => toggleModalForm(null),
    clearServerError: _ => setModalFormError(null),
    component: null,
  };

  if (modalForm !== null) {
    const rest = modals.find(item => item.id === modalForm.id);

    if (rest) props = {
      ...props,
      title: modalForm.title ?? rest.title,
      component: rest.component,
      serverError: modalFormError,
      handleData: modalForm.handler,
      data: modalForm.data,
      extraData: modalForm.extraData,
      width: modalForm.width || rest.width,
      preventCloseOnSubmit: rest.preventCloseOnSubmit,
    };
  }

  return ReactDOM.createPortal(<ModalForm visible={props.component !== null} {...props} />, container);
});
