export function mapActions(actions, creators) {
  return Object.assign({}, ...actions.map(item => ({ [item]: creators[item] })))
}

export { default as AppActions } from './app/actions';
export { default as AuthActions } from './auth/actions';
export { default as MagnusActions } from './magnus/actions';
export { default as FilterActions } from './filter/actions';
export { default as SettingsActions } from './settings/actions';
export { default as RemoteConfigActions } from './remote-config/actions';

export { default as AccountsActions } from './accounts/actions';
export { default as RevenueActions } from './revenue/actions';
export { default as FinanceActions } from './finance/actions';

export { default as AnalyticsActions } from './analytics/actions';
export { default as MarketingActions } from './marketing/actions';
export { default as PredictionsActions } from './predictions/actions';

export { default as SDKActions } from './sdk/actions';
export { default as SubtruckActions } from './subtruck/actions';
export { default as VariatorActions } from './variator/actions';
export { default as MutatorActions } from './mutator/actions';
export { default as EvTruckActions } from './evtruck/actions';

