import _ from 'lodash';
import { createActions } from 'redux-actions';

import API from 'src/service/api';

import { retrofy } from '../retrofy';


let asyncActions = {
  'FETCH_PREDICTIONS_SALES_REVENUE_PRODUCT': 'fetchPredictionsSalesRevenueProduct',
  'FETCH_PREDICTIONS_REVENUE_FORECAST': 'fetchPredictionsRevenueForecast',
};

asyncActions = _.mapValues(asyncActions, (item) => (
  async (...args) => {
    const rs = await API[item](...args);
    if (rs instanceof Error) throw rs;
    return rs.data;
  }
));

const actions = createActions(
  { ...asyncActions },
);

actions.fetchPredictionsSalesRevenueApp = retrofy('fetchPredictionsSalesRevenueApp');

export default actions;

