import _ from 'lodash';

import { store } from 'src/redux/store';

export function hasPermissions(permissions) {
  const { auth: { company, companies }, magnus: { companies: allCompanies } } = store.getState();
  return _.reduce(company ? [company] : companies?.length ? companies : allCompanies, (prev, curr) => prev && hasPerms(curr, permissions), true);
}

export function hasAdminPermissions() {
  const { auth: { company }, magnus: { companies } } = store.getState();
  return _.reduce(company ? [company] : companies, (prev, curr) => prev && hasAdminPerms(curr), true);
}

function hasPerms(company, permissions) {
  if (company.is_admin) {
    return true;
  } else {
    if (typeof permissions === 'string') {
      permissions = [permissions];
    }
    return company.role?.permissions?.some(i => permissions.includes(i));
  }
}

function hasAdminPerms(company, permissions) {
  if (company.is_admin) {
    return true;
  } else {
    return false;
  }
}