import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { getView, } from './utils';
import actions from './actions';

const defaultState = {
  collapsed: localStorage.getItem(`sidebar`) !== 'true',
  view: getView(window.innerWidth),
  height: window.innerHeight,

  openDrawer: false,
  openKeys: [],
  current: [null],

  sdkProject: null,

  spinner: false,

  title: '',

  modalForm: null,
  modalFormError: null,

  recentPages: [],
};


export default persistReducer({ key: 'app', storage, whitelist: ['recentPages'], }, handleActions(
  {
    [actions.toggleCollapsed]: (state) => ({ ...state, collapsed: !state.collapsed, }),
    [actions.toggleAll]: (state, { payload }) => {
      const view = getView(payload.width);
      const collapsed = view !== "DesktopView";
      if (state.view !== payload.view || payload.height !== state.height) {
        const height = payload.height ? payload.height : state.height;
        return {
          ...state,
          collapsed: collapsed,
          view: view,
          height,
        };
      }
    },

    [actions.toggleOpenDrawer]: (state) => ({ ...state, openDrawer: !state.openDrawer, }),

    [actions.changeOpenKeys]: (state, { payload }) => ({ ...state, openKeys: payload, }),
    [actions.changeCurrent]: (state, { payload }) => ({ ...state, current: payload, }),

    [actions.setSDKProject]: (state, { payload }) => ({ ...state, sdkProject: payload, }),

    [actions.showSpinner]: (state) => ({ ...state, spinner: true, }),
    [actions.hideSpinner]: (state) => ({ ...state, spinner: false, }),

    [actions.setTitle]: (state, { payload }) => ({ ...state, title: payload, }),

    [actions.toggleModalForm]: (state, { payload }) => ({ ...state, modalForm: payload || null, modalFormError: null, }),
    [actions.setModalFormError]: (state, { payload }) => ({ ...state, modalFormError: payload, }),


    [LOCATION_CHANGE]:
      (state, { payload }) => ({
        ...state,
        recentPages:
          _.reject(
            _.uniqBy(
              [
                { pathname: payload.location.pathname, search: payload.location.search, },
                ...state.recentPages.slice(payload.action === 'REPLACE' ? 1 : 0),
              ],
              i => i.pathname + i.search,
            ),
            { pathname: '/' },
          ).slice(0, 20),
      }),
  },
  defaultState
));
