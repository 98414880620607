import _ from 'lodash';
import { all, fork, call, takeEvery, put, take } from 'redux-saga/effects';
import queryString from 'query-string';

import API from 'src/service/api';
import { postMessage } from 'src/service/messaging';
import { getApiErrorText } from 'src/helpers/errors-api';
import { processError, } from '../helpers';

import { AppActions } from '../actions';

import { store } from '../store';

import actions from './actions';

function* checkResponse(rs, resolve, reject) {
  if (rs instanceof Error) {
    if (reject) {
      yield put(reject());
    } else {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    }
  } else {
    const { data } = rs;

    if (resolve) yield put(resolve(data));
  }
}

function* sagaCreateAccountPopup() {
  yield takeEvery(actions.createAccountPopup, function* ({ payload }) {
    const type = payload.type.replace(/-/g, '_');

    let url = `${window.location.href.split('/').slice(0, 3).join('/')}/backurl`;

    if (type === 'pinterest') {
      url += `/${type}`;
    } else {
      url = queryString.stringifyUrl({
        url,
        query: {
          type: payload.type.replace(/-/g, '_'),
          fbType: payload.fbType,
        }
      });
    }

    const rs = yield call(API[{
      'google-ad-words': 'createLinkedGoogleAdWordsUrl',
      'ad-mob': 'createLinkedAdMobUrl',
      'facebook-ads': 'createLinkedFacebookAdsUrl',
      'snapchat': 'createLinkedSnapchatUrl',
      'tik-tok': 'createLinkedTikTokUrl',
      'pinterest': 'createLinkedPinterestUrl',
    }[payload.type]], { url, type: payload.fbType, });

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      const { data } = rs;

      localStorage.removeItem('refreshLinkedAccountToken');
      localStorage.setItem('createLinkedAccountURL', url);

      if (payload.id) {
        localStorage.setItem(`refreshLinkedAccountToken`, payload.id);
      }

      yield put(actions.openPopup({
        features: { height: 600, width: 500, toolbar: false, menubar: false, location: false, titlebar: false },
        copyStyles: false,
        onBlock: e => console.log('BLOCKED', e),
        onUnload: _ => store.dispatch(actions.openPopup(null)),
        url: data,
      }));
    }
  });
}

function* sagaFetchPublishers() {
  yield takeEvery(actions.fetchPublishers, function* () {
    const rs = yield call(API.fetchPublishers);

    yield checkResponse(rs, actions.fetchPublishersSuccess);
  });
}

function* sagaCreatePublisher() {
  while (true) {
    const { payload } = yield take(actions.createPublisher);

    let rs;

    /* eslint-disable default-case */
    switch (payload.type) {
      case 'android': {
        const formData = new FormData();

        formData.append('name', payload.name);
        formData.append('android_publisher_bucket_id', payload.android_publisher_bucket_id);
        formData.append('android_publisher_id', payload.android_publisher_id);
        formData.append('android_publisher_name', payload.android_publisher_name);

        if (payload.android_credentials) formData.append('android_credentials', payload.android_credentials.file);

        rs = yield call(API.createPublisherAndroid, formData);

        break;
      }
      case 'ios': {
        rs = yield call(API.createPublisherIos, payload);

        break;
      }
    }

    if (rs instanceof Error) {
      if (payload.type === 'ios') {
        yield processError(rs);
      } else {
        yield put(AppActions.showNotification({
          type: 'error',
          message: `Error`,
          description: getApiErrorText(rs),
        }));
      }
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Publisher account was added.`,
      }));

      yield put(actions.fetchPublishers());
    }
  }
}

function* sagaUpdatePublisher() {
  while (true) {
    const { payload } = yield take(actions.updatePublisher);

    let rs;

    switch (payload.platform) {
      case 'android': {
        const formData = new FormData();

        formData.append('id', payload.id);
        formData.append('name', payload.name);
        formData.append('android_publisher_bucket_id', payload.android_publisher_bucket_id);
        formData.append('android_publisher_id', payload.android_publisher_id);
        formData.append('android_publisher_name', payload.android_publisher_name);

        if (payload.android_credentials) formData.append('android_credentials', payload.android_credentials.file);

        rs = yield call(API.updatePublisherAndroid, formData);

        break;
      }
      case 'ios': {
        rs = yield call(API.updatePublisherIos, payload);
        break;
      }
      default: {
        rs = yield call(API.updatePublisher, _.pick(payload, ['id', 'is_active',]));
        break;
      }
    }

    if (rs instanceof Error) {
      if (payload.platform === 'ios') {
        yield processError(rs);
      } else {
        yield put(AppActions.showNotification({
          type: 'error',
          message: `Error`,
          description: getApiErrorText(rs),
        }));
      }
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Publisher account ${payload.name} was successfully updated.`,
      }));

      yield put(actions.fetchPublishers());
    }
  }
}

function* sagaDeletePublisher() {
  while (true) {
    const { payload } = yield take(actions.deletePublisher);

    const rs = yield call(API.deletePublisher, _.pick(payload, 'id'));

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Publisher account ${payload.name} was deleted.`,
      }));

      yield put(actions.fetchPublishers());
    }
  }
}

function* sagaFetchLinkedAccounts() {
  yield takeEvery(actions.fetchLinkedAccounts, function* ({ payload }) {
    let rs;

    const headers = { companyId: payload.app_project?.company_id };

    if (payload.type === 'unity-ads') {
      rs = yield call(API[{
        'ad-revenue': 'fetchLinkedUnityAds',
        'ad-expenses': 'fetchLinkedUnityAdsExpenses',
      }[payload.subType]]);
    }
    else if (payload.type === 'iron-source') {
      rs = yield call(API[{
        'ad-revenue': 'fetchLinkedIronSource',
        'ad-expenses': 'fetchLinkedIronSourceExpenses',
      }[payload.subType]]);
    }
    else {
      rs = yield call(API[{
        'adjust': 'fetchLinkedAdjust',
        'appsflyer': 'fetchLinkedAppsflyer',
        'google-ad-words': 'fetchLinkedGoogleAdWords',
        'facebook-ads': 'fetchLinkedFacebookAds',
        'snapchat': 'fetchLinkedSnapchat',
        'search-ads': 'fetchLinkedSearchAds',
        'appodeal': 'fetchLinkedAppodeal',
        'ad-mob': 'fetchLinkedAdMob',
        'applovin': 'fetchLinkedApplovinExpenses',
        'tik-tok': 'fetchLinkedTikTokExpenses',
        'paypal': 'fetchLinkedAccountsPayPal',
        'recurly': 'fetchLinkedAccountsRecurly',
        'solidgate': 'fetchLinkedAccountsSolidgate',
        'pinterest': 'fetchLinkedPinterestExpenses',
      }[payload.type]], null, headers);
    }

    yield checkResponse(rs, actions.fetchLinkedAccountsSuccess);
  });
}

function* sagaFetchAdsAccounts() {
  yield takeEvery(actions.fetchAdsAccounts, function* () {
    const rs = yield call(API.fetchAdsAccounts);

    yield checkResponse(rs, actions.fetchAdsAccountsSuccess);
  });
}

function* sagaUpdateAdsAccountFee() {
  yield takeEvery(actions.updateAdsAccountFee, function* ({ payload }) {
    const args = {
      id: payload.id,
      fee_type: payload.fee_type,
      amounts: [],
      dates: [],
    };

    const total = Object.keys(payload).filter(item => !!~item.indexOf('fee-')).length;

    switch (payload.fee_type) {
      case 'fixed':
        args.fee = payload['fee-0'];

        break;
      case 'month_amount':
        Array(total).fill().forEach((_, i) => {
          args.amounts.push({
            fee: payload[`fee-${i}`],
            amount_start: payload[`amount_start-${i}`],
            amount_end: payload[`amount_end-${i}`],
          });
        });

        break;
      case 'date':
        Array(total).fill().forEach((_, i) => {
          args.dates.push({
            fee: payload[`fee-${i}`],
            date_start: payload[`date_start-${i}`],
          });
        });

        break;
    }

    const rs = yield call(API.changeFeeAdsAccounts, args);

    yield checkResponse(rs, actions.fetchAdsAccounts);
  });
}

function* sagaCreateLinkedAccount() {
  while (true) {
    const { payload } = yield take(actions.createLinkedAccount);

    if (
      payload.type === 'google_ad_words'
      || payload.type === 'ad_mob'
      || payload.type === 'facebook_ads'
      || payload.type === 'snapchat'
      || payload.type === 'tik_tok'
      || payload.type === 'pinterest'
    ) {
      const rs = yield call(API[{
        'google_ad_words': 'createLinkedGoogleAdWords',
        'ad_mob': 'createLinkedAdMob',
        'facebook_ads': 'createLinkedFacebookAds',
        'snapchat': 'createLinkedSnapchat',
        'tik_tok': 'createLinkedTikTokExpenses',
        'pinterest': 'createLinkedPinterestExpenses',
      }[payload.type]], { code: payload.code, url: payload.url, name: 'tik-tok', type: payload.fbType, });

      localStorage.removeItem('createLinkedAccountURL');

      if (rs instanceof Error) {
        postMessage({
          type: 'return',
          error: true,
          payload: { response: rs.response, },
        }, window.opener);
      } else {
        postMessage({
          type: 'return',
          payload: 'Linked account was added.',
        }, window.opener);
      }
    } else {
      let rs;

      switch (payload.type) {
        case 'appodeal': {
          rs = yield call(API.createLinkedAppodeal, _.omit(payload, ['type']));

          break;
        }
        case 'search-ads': {
          const formData = new FormData();

          formData.append('name', payload.name);
          formData.append('client_id', payload.client_id);
          formData.append('team_id', payload.team_id);
          formData.append('key_id', payload.key_id);

          rs = yield call(API.createLinkedSearchAds, formData);

          break;
        }
        case 'adjust': {
          const args = {
            name: payload.name,
            user_token: payload.user_token,
            apps: [],
          };

          const total = Object.keys(payload).filter(item => !!~item.indexOf('app_id-')).length;

          Array(total).fill()
            .forEach((_, i) => {
              args.apps.push({
                app_id: payload[`app_id-${i}`],
                app_token: payload[`app_token-${i}`],
              });
            });

          rs = yield call(API.createLinkedAdjust, args);

          break;
        }
        case 'appsflyer': {
          rs = yield call(API.createLinkedAppsflyer, _.pick(payload, 'name', 'api_key', 'app_ids'));

          break;
        }
        case 'unity-ads': {
          if (payload.subType === 'ad-revenue') {
            const args = {
              name: payload.name,
              api_key: payload.api_key,
              organization_id: payload.organization_id,
              apps: [],
            };

            const total = Object.keys(payload).filter(item => !!~item.indexOf('app_id-')).length;

            Array(total).fill().forEach((_, i) => {
              args.apps.push({
                app_id: payload[`app_id-${i}`],
                acquire_source_game_id: payload[`acquire_source_game_id-${i}`],
                monetize_source_game_ids: payload[`monetize_source_game_ids-${i}`],
              });
            });

            rs = yield call(API.createLinkedUnityAds, args);
          } else if (payload.subType === 'ad-expenses') {
            rs = yield call(API.createLinkedUnityAdsExpenses, _.pick(payload, 'name', 'api_key', 'organization_id'));
          }

          break;
        }
        case 'iron-source': {
          let args = _.pick(payload, 'name', 'username', 'secret_key', 'refresh_token');
          if (payload.subType === 'ad-revenue') {
            rs = yield call(API.createLinkedIronSource, args);
          } else {
            rs = yield call(API.createLinkedIronSourceExpenses, args);
          }
          break;
        }
        case 'applovin': {
          rs = yield call(API.createLinkedApplovinExpenses, _.pick(payload, 'name', 'username', 'report_key', 'management_key'));
          break;
        }
        case 'paypal': {
          let args = _.pick(payload, 'name', 'credentials', 'apps');
          rs = yield call(API.createLinkedAccountPayPal, args);
          break;
        }
        case 'recurly': {
          let args = _.pick(payload, 'name', 'credentials', 'apps');
          rs = yield call(API.createLinkedAccountRecurly, args);
          break;
        }
        case 'solidgate': {
          let args = _.pick(payload, 'name', 'credentials', 'apps');
          rs = yield call(API.createLinkedAccountSolidgate, args);
          break;
        }
      }

      if (rs instanceof Error) {
        yield put(AppActions.showNotification({
          type: 'error',
          message: `Error`,
          description: getApiErrorText(rs),
        }));
      } else {
        yield put(AppActions.showNotification({
          type: 'success',
          message: `Linked account ${payload.name} was added.`,
        }));

        yield put(actions.fetchLinkedAccounts({ type: payload.type, subType: payload.subType }));
      }
    }
  }
}

function* sagaUpdateLinkedAccount() {
  while (true) {
    const { payload } = yield take(actions.updateLinkedAccount);

    if (payload.code && (payload.type === 'facebook_ads' || payload.type === 'tik_tok')) {
      const rs = yield call(API[{
        'facebook_ads': 'updateLinkedFacebookAds',
        'tik_tok': 'updateLinkedTikTokExpenses',
      }[payload.type]], { ...payload, type: payload.fbType || payload.type });

      localStorage.removeItem('createLinkedAccountURL');
      localStorage.removeItem('refreshLinkedAccountToken');

      if (rs instanceof Error) {
        postMessage({
          type: 'return',
          error: true,
          payload: { response: rs.response, },
        }, window.opener)
      } else {
        postMessage({
          type: 'return',
          payload: 'Linked account was updated.',
        }, window.opener);
      }
    } else {
      let rs;

      switch (payload.type) {
        case 'firebase': {
          const formData = new FormData();

          formData.append('id', payload.id);
          formData.append('name', payload.name);

          if (payload.credentials) formData.append('credentials', payload.credentials.file);

          rs = yield call(API.updateAccountFirebase, formData);

          break;
        }
        case 'search-ads': {
          const formData = new FormData();

          formData.append('id', payload.id);
          formData.append('name', payload.name);
          formData.append('client_id', payload.client_id);
          formData.append('team_id', payload.team_id);
          formData.append('key_id', payload.key_id);

          rs = yield call(API.updateLinkedSearchAds, formData);

          break;
        }
        case 'appodeal': {
          rs = yield call(API.updateLinkedAppodeal, _.omit(payload, 'type'));

          break;
        }
        case 'adjust': {
          const args = {
            id: payload.id,
            name: payload.name,
            user_token: payload.user_token,
            apps: [],
          };

          const total = Object.keys(payload).filter(item => !!~item.indexOf('app_id-')).length;

          Array(total).fill()
            .forEach((_, i) => {
              args.apps.push({
                app_id: payload[`app_id-${i}`],
                app_token: payload[`app_token-${i}`],
              });
            });

          rs = yield call(API.updateLinkedAdjust, args);

          break;
        }
        case 'appsflyer': {
          rs = yield call(API.updateLinkedAppsflyer, _.pick(payload, 'id', 'name', 'api_key', 'app_ids'));

          break;
        }
        case 'unity-ads': {
          if (payload.subType === 'ad-revenue') {
            const args = {
              id: payload.id,
              name: payload.name,
              api_key: payload.api_key,
              organization_id: payload.organization_id,
              apps: [],
            };

            const total = Object.keys(payload).filter(item => !!~item.indexOf('app_id-')).length;

            Array(total).fill().forEach((_, i) => {
              args.apps.push({
                app_id: payload[`app_id-${i}`],
                acquire_source_game_id: payload[`acquire_source_game_id-${i}`],
                monetize_source_game_ids: payload[`monetize_source_game_ids-${i}`],
              });
            });

            rs = yield call(API.updateLinkedUnityAds, args);
          } else if (payload.subType === 'ad-expenses') {
            rs = yield call(API.updateLinkedUnityAdsExpenses, _.pick(payload, 'id', 'name', 'api_key', 'organization_id'));
          }

          break;
        }
        case 'iron-source': {
          let args = _.pick(payload, 'id', 'name', 'username', 'secret_key', 'refresh_token');
          if (payload.subType === 'ad-revenue') {
            rs = yield call(API.updateLinkedIronSource, args);
          } else {
            rs = yield call(API.updateLinkedIronSourceExpenses, args);
          }
          break;
        }
        case 'applovin': {
          rs = yield call(API.updateLinkedApplovinExpenses, _.pick(payload, 'id', 'name', 'username', 'report_key', 'management_key'));
          break;
        }
        case 'tik-tok': {
          rs = yield call(API.updateLinkedTikTokExpenses, _.pick(payload, 'id', 'name', 'code'));
          break;
        }
        case 'paypal': {
          let args = _.pick(payload, 'id', 'name', 'credentials', 'apps');
          rs = yield call(API.updateLinkedAccountPayPal, args);
          break;
        }
        case 'recurly': {
          let args = _.pick(payload, 'id', 'name', 'credentials', 'apps');
          rs = yield call(API.updateLinkedAccountRecurly, args);
          break;
        }
        case 'solidgate': {
          let args = _.pick(payload, 'id', 'name', 'credentials', 'apps');
          rs = yield call(API.updateLinkedAccountSolidgate, args);
          break;
        }
      }

      if (rs instanceof Error) {
        yield put(AppActions.showNotification({
          type: 'error',
          message: `Error`,
          description: getApiErrorText(rs)
        }));
      } else {
        yield put(AppActions.showNotification({
          type: 'success',
          message: `Linked account ${payload.name} was updated.`,
        }));

        yield put(actions.fetchLinkedAccounts({ type: payload.type, subType: payload.subType }));
      }
    }
  }
}

function* sagaDeleteLinkedAccount() {
  while (true) {
    const { payload } = yield take(actions.deleteLinkedAccount);

    let rs;

    if (payload.type === 'unity-ads') {
      rs = yield call(API[{
        'ad-revenue': 'deleteLinkedUnityAds',
        'ad-expenses': 'deleteLinkedUnityAdsExpenses',
      }[payload.subType]], _.pick(payload, 'id'));
    }
    else if (payload.type === 'iron-source') {
      rs = yield call(API[{
        'ad-revenue': 'deleteLinkedIronSource',
        'ad-expenses': 'deleteLinkedIronSourceExpenses',
      }[payload.subType]], _.pick(payload, 'id'));
    }
    else {
      rs = yield call(API[{
        'google-ad-words': 'deleteAccountGoogleAdWords',
        'facebook-ads': 'deleteAccountFacebookAds',
        'snapchat': 'deleteAccountSnapchat',
        'ad-mob': 'deleteAccountAdMob',
        'search-ads': 'deleteAccountSearchAds',
        'appodeal': 'deleteAccountAppodeal',
        'adjust': 'deleteLinkedAdjust',
        'appsflyer': 'deleteLinkedAppsflyer',
        'applovin': 'deleteLinkedApplovinExpenses',
        'tik-tok': 'deleteLinkedTikTokExpenses',
        'paypal': 'deleteLinkedAccountPayPal',
        'recurly': 'deleteLinkedAccountRecurly',
        'solidgate': 'deleteLinkedAccountSolidgate',
        'pinterest': 'deleteLinkedPinterestExpenses',
      }[payload.type]], _.pick(payload, 'id'));
    }

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Linked account ${payload.name} was deleted.`,
      }));

      yield put(actions.fetchLinkedAccounts({ type: payload.type, subType: payload.subType }));
    }
  }
}

function* sagaUpdateLinkedAccountFees() {
  while (true) {
    const { payload } = yield take(actions.updateLinkedAccountFees);

    let rs;

    const args = {
      id: payload.id,
      costs: [],
    };

    const groups = Object.keys(payload).filter(item => !!~item.indexOf('date_start-')).length;
    Array(groups).fill().forEach((_, g) => {
      const items = Object.keys(payload).filter(item => !!~item.indexOf(`amount-${g}-`)).length;
      Array(items).fill().forEach((_, i) => {
        args.costs.push({
          date_start: payload[`date_start-${g}`] || null,
          type: payload[`type-${g}`],
          amount: +payload[`amount-${g}-${i}`] || 0,
          installs_start: +payload[`installs_start-${g}-${i}`] || 0,
          installs_end: +payload[`installs_end-${g}-${i}`] || 0,
        });
      });
    });

    switch (payload.type) {
      case 'adjust': {
        rs = yield call(API.updateLinkedAdjustFee, args);

        break;
      }
      case 'appsflyer': {
        rs = yield call(API.updateLinkedAppsflyerFee, args);

        break;
      }
    }

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Linked account ${payload.name} fees was updated.`,
      }));

      yield put(actions.fetchLinkedAccounts({ type: payload.type }));
    }
  }
}

const sagas = [
  sagaFetchPublishers,
  sagaCreatePublisher,
  sagaDeletePublisher,
  sagaUpdatePublisher,
  sagaFetchLinkedAccounts,
  sagaFetchAdsAccounts,
  sagaCreateAccountPopup,
  sagaCreateLinkedAccount,
  sagaDeleteLinkedAccount,
  sagaUpdateLinkedAccount,
  sagaUpdateAdsAccountFee,
  sagaUpdateLinkedAccountFees,
];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
