import _ from 'lodash';
import { createActions } from 'redux-actions';

import API from 'src/service/api';

import { createSuccessErrorActions } from '../helpers';

let asyncActions = {
  'FETCH_REVENUE_COMPARE': 'fetchRevenueCompare',
};

asyncActions = _.mapValues(asyncActions, (item) => (
  async (...args) => {
    const rs = await API[item](...args);

    if (rs instanceof Error) throw rs;

    return rs.data;
  }
));

export default createActions(
  { ...asyncActions, },
  ...createSuccessErrorActions('FETCH_REVENUE_PRODUCTS'),
  ...createSuccessErrorActions('FETCH_REVENUE_NETWORKS'),
);
