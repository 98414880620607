import { createActions } from 'redux-actions';

import { createSuccessErrorActions } from '../helpers';

export default createActions(

  ...createSuccessErrorActions('FETCH_PROJECTS'),
  ...createSuccessErrorActions('CREATE_PROJECT'),
  ...createSuccessErrorActions('UPDATE_PROJECT'),
  ...createSuccessErrorActions('DELETE_PROJECT'),
  'CLEAR_PROJECTS',

  ...createSuccessErrorActions('CREATE_APPLICATION'),

  ...createSuccessErrorActions('FETCH_PRODUCTS'),
  ...createSuccessErrorActions('CREATE_PRODUCT'),
  'CLEAR_PRODUCTS',

  ...createSuccessErrorActions('FETCH_PROJECT_NOTES'),
  ...createSuccessErrorActions('CREATE_PROJECT_NOTE'),
  ...createSuccessErrorActions('UPDATE_PROJECT_NOTE'),
  ...createSuccessErrorActions('DELETE_PROJECT_NOTE'),
  'CLEAR_PROJECT_NOTES',

  ...createSuccessErrorActions('FETCH_SEGMENT_OPTIONS'),
  'CLEAR_SEGMENT_OPTIONS',

  ...createSuccessErrorActions('FETCH_PROJECT_HISTORY'),
  'CLEAR_PROJECT_HISTORY',

  { prefix: 'SDK', }

);
