import asyncComponent from 'src/helpers/asyncFunc';

export const modals = [
  {
    id: 'update-evtruck-segment',
    title: 'Edit Segment',
    component: asyncComponent(_ => import('./segments/edit')),
  },
  {
    id: 'update-evtruck-segment-name',
    title: 'Save Segment',
    component: asyncComponent(_ => import('./segments/edit-name')),
  },
  {
    id: 'update-evtruck-event',
    title: 'Edit Event',
    component: asyncComponent(_ => import('./filters/events/event-edit')),
  },
  {
    id: 'update-evtruck-chart',
    title: 'Save Chart',
    component: asyncComponent(_ => import('./charts/edit-name')),
  },
  {
    id: 'update-evtruck-dashboard',
    title: 'Save Dashboard',
    component: asyncComponent(_ => import('./dashboards/edit-name')),
  },
];