import _ from 'lodash';
import { createActions } from 'redux-actions';

import API from 'src/service/api';

import { createSuccessErrorActions } from '../helpers';

let asyncActions = {
  'FETCH_CREATIVES_PREVIEWS': 'fetchCreativesPreviews',
};

asyncActions = _.mapValues(asyncActions, (item) => (
  async (...args) => {
    const rs = await API[item](...args);
    if (rs instanceof Error) throw rs;
    return rs.data;
  }
));

export default createActions(

  { ...asyncActions },

  'CLEAR_DATA',
  'OPEN_POPUP',
  'CREATE_ACCOUNT_POPUP',

  ...createSuccessErrorActions('FETCH_PUBLISHERS'),
  'CREATE_PUBLISHER',
  'DELETE_PUBLISHER',
  'UPDATE_PUBLISHER',

  ...createSuccessErrorActions('FETCH_LINKED_ACCOUNTS'),
  'CREATE_LINKED_ACCOUNT',
  'DELETE_LINKED_ACCOUNT',
  'UPDATE_LINKED_ACCOUNT',

  'UPDATE_LINKED_ACCOUNT_FEES',

  ...createSuccessErrorActions('FETCH_ADS_ACCOUNTS'),
  'UPDATE_ADS_ACCOUNT_FEE',

  { prefix: 'ACCOUNTS', }
);
