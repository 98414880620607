import _ from 'lodash';
import { createActions } from 'redux-actions';

import API from 'src/service/api';

import { createSuccessErrorActions, } from '../../helpers';

import { queue } from '../queue';


let queueActions = {
  'FETCH_ANALYTICS': 'fetchEvTruckAnalytics',
};

let asyncActions = {
  'FETCH_EVENTS_LOG': 'fetchEvTruckEventsLog',
  'FETCH_USER_PROPS_LOG': 'fetchEvTruckUserPropsLog',
  'FETCH_INSTALLS_LOG': 'fetchEvTruckInstallsLog',
};


queueActions = _.mapValues(queueActions, (item) => (
  async (...args) => {
    const rs = await queue.add(() => API[item](...args));
    if (rs instanceof Error) throw rs;
    return rs;
  }
));

asyncActions = _.mapValues(asyncActions, (item) => (
  async (...args) => {
    const rs = await API[item](...args);
    if (rs instanceof Error) throw rs;
    return rs;
  }
));

export default createActions(

  { ...queueActions, ...asyncActions },

  ...createSuccessErrorActions('FETCH_EVENTS'),

  ...createSuccessErrorActions('FETCH_PARAMS'),
  'CLEAR_PARAMS',

  'CLEAR',

  { prefix: 'EVTRUCK/EVENTS', }

);
