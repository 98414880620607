import app from './app/reducer';
import auth from './auth/reducer';
import magnus from './magnus/reducer';
import filter from './filter/reducer';
import settings from './settings/reducer';
import remoteConfig from './remote-config/reducer';

import accounts from './accounts/reducer';
import revenue from './revenue/reducer';
import finance from './finance/reducer';

import sdk from './sdk/reducer';
import subtruck from './subtruck/reducer';
import variator from './variator/reducer';
import mutator from './mutator/reducer';
import evtruck from './evtruck/reducer';

export default {
  app,
  auth,
  magnus,
  filter,
  settings,
  remoteConfig,

  accounts,
  revenue,
  finance,

  sdk,
  subtruck,
  variator,
  mutator,
  evtruck,
};
