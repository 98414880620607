import _ from 'lodash';
import { all, takeEvery, fork, select, put, take, } from 'redux-saga/effects';

import { PRESETS, getPeriodByPreset, } from 'src/components/date-picker';

import MagnusActions from '../magnus/actions';

import actions from './actions';

const filterAppsStorageId = (user, companies) => `filter-apps-${user?.id || null}-${_.sortBy(_.map(companies, 'id')).join('-')}`;
const filterDatesStorageId = (user, companies) => `filter-dates-${user?.id || null}`;
const filterMarketingStorageId = (user, companies) => `filter-marketing-${user?.id || null}-${_.sortBy(_.map(companies, 'id')).join('-')}`;

export function* sagaUpdateFilter() {
  yield takeEvery(actions.updateFilter, function* ({ payload }) {
    const { user, companies } = yield select(state => state.auth);

    if (user) {
      const appsStored = JSON.parse(localStorage.getItem(filterAppsStorageId(user, companies))) || {},
        datesStored = JSON.parse(localStorage.getItem(filterDatesStorageId(user, companies))) || {},
        marketingStored = JSON.parse(localStorage.getItem(filterMarketingStorageId(user, companies))) || { marketing: {}, };

      if (payload.apps) {
        payload.apps = payload.apps.map(Number);
        let allApps = yield select(state => state.magnus.apps);
        payload.apps = _.intersection(payload.apps, _.map(allApps, 'id'));
      }

      payload = { ...datesStored, ...appsStored, ...marketingStored, ...payload, };

      localStorage.setItem(filterAppsStorageId(user, companies), JSON.stringify(_.pick(payload, ['apps', 'showPublishers',])));
      localStorage.setItem(filterDatesStorageId(user, companies), JSON.stringify(_.pick(payload, ['period',])));
      localStorage.setItem(filterMarketingStorageId(user, companies), JSON.stringify(_.pick(payload, ['marketing',])));

      yield put(actions.updateFilterSuccess(payload));
    }
  });
}

export function* sagaUpdateAppsFilter() {
  yield takeEvery(actions.updateAppsFilter, function* ({ payload }) {
    const { user, companies } = yield select(state => state.auth);

    if (user) {
      const stored = JSON.parse(localStorage.getItem(filterAppsStorageId(user, companies))) || {};

      if (payload.apps) {
        payload.apps = payload.apps.map(Number);
        let allApps = yield select(state => state.magnus.apps);
        payload.apps = _.intersection(payload.apps, _.map(allApps, 'id'));
      }

      payload = { ...stored, ...payload, };

      localStorage.setItem(filterAppsStorageId(user, companies), JSON.stringify(_.pick(payload, ['apps', 'showPublishers',])));

      yield put(actions.updateAppsFilterSuccess(payload));
    }
  });
}

export function* sagaUpdateDatesFilter() {
  yield takeEvery(actions.updateDatesFilter, function* ({ payload }) {
    const { user, companies } = yield select(state => state.auth);

    if (user) {
      const stored = JSON.parse(localStorage.getItem(filterDatesStorageId(user, companies))) || { period: {}, };

      payload = {
        period: {
          ...stored.period,
          ...payload.period,
        },
      };

      localStorage.setItem(filterDatesStorageId(user, companies), JSON.stringify(_.pick(payload, ['period',])));

      yield put(actions.updateDatesFilterSuccess(payload));
    }
  });
}

export function* sagaUpdateMarketingFilter() {
  yield takeEvery(actions.updateMarketingFilter, function* ({ payload }) {
    const { user, companies } = yield select(state => state.auth);

    if (user) {
      const stored = JSON.parse(localStorage.getItem(filterMarketingStorageId(user, companies))) || { marketing: {}, };

      payload = {
        marketing: {
          ...stored.marketing,
          ...payload.marketing,
        },
      };

      localStorage.setItem(filterMarketingStorageId(user, companies), JSON.stringify(_.pick(payload, ['marketing',])));

      yield put(actions.updateMarketingFilterSuccess(payload));
    }
  });
}

export function* sagaRestoreFilter() {
  yield takeEvery(actions.restoreFilter, function* () {
    const { user, companies } = yield select(state => state.auth);

    const appsStored = JSON.parse(localStorage.getItem(filterAppsStorageId(user, companies))) || {};
    const datesStored = JSON.parse(localStorage.getItem(filterDatesStorageId(user, companies))) || {};
    const marketingStored = JSON.parse(localStorage.getItem(filterMarketingStorageId(user, companies))) || { marketing: {}, };

    let payload = { ...appsStored, ...datesStored, ...marketingStored, };

    let allApps = yield select(state => state.magnus.apps);

    if (!allApps) {
      yield take(MagnusActions.fetchAppsSuccess);
    }

    if (!payload.apps) {
      allApps = yield select(state => state.magnus.apps) || [];
      payload = {
        ...payload,
        apps: allApps.map(item => item.id),
      };
    }

    if (!payload.period) {
      let preset = PRESETS.LAST_30;
      let { from, to, } = getPeriodByPreset(preset);
      payload = {
        ...payload,
        period: {
          from,
          to,
          preset,
        },
      };
    }

    payload.restored = true;

    yield put(actions.updateFilter(payload));
  });
}


export default function* () {
  yield all([
    fork(sagaUpdateFilter),
    fork(sagaRestoreFilter),
    fork(sagaUpdateAppsFilter),
    fork(sagaUpdateDatesFilter),
    fork(sagaUpdateMarketingFilter),
  ]);
}
