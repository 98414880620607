import { combineReducers } from 'redux';

import config from './config/reducer';
import experiments from './experiments/reducer';

export default combineReducers({
  config,
  experiments,
});

