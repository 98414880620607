import React from 'react';

import { IconPlatform } from './';


export default ({ app, project, publisher, name, platform, ...props }) => {
  if (app) {
    name = app.name;
    platform = app.platform;
  }
  else if (project) {
    name = project.name;
    platform = project.platform;
  }
  else if (publisher) {
    name = publisher.name;
    platform = publisher.platform;
  }

  return (
    <span {...props}>
      <IconPlatform platform={platform} /> {name}
    </span>
  );
}
