import _ from 'lodash';
import { handleActions } from 'redux-actions';

import actions from './actions';

const defaultState = {
  projects: null,
  products: null,
  projectNotes: null,
  segmentOptions: null,
  projectHistory: null,
};

export default handleActions(
  {

    [actions.fetchProjectsSuccess]: (state, { payload }) => {
      return {
        ...state,
        projects: payload,
      };
    },
    [actions.clearProjects]: (state) => {
      return {
        ...state,
        projects: null,
      };
    },

    [actions.fetchProductsSuccess]: (state, { payload }) => {
      return {
        ...state,
        products: payload,
      };
    },
    [actions.clearProducts]: (state) => {
      return {
        ...state,
        products: null,
      };
    },

    [actions.fetchProjectNotesSuccess]: (state, { payload }) => {
      return {
        ...state,
        projectNotes: _.orderBy(payload, 'date'),
      };
    },
    [actions.clearProjectNotes]: (state) => {
      return {
        ...state,
        projectNotes: null,
      };
    },

    [actions.fetchSegmentOptionsSuccess]: (state, { payload }) => {
      return {
        ...state,
        segmentOptions: payload,
      };
    },
    [actions.clearSegmentOptions]: (state) => {
      return {
        ...state,
        segmentOptions: null,
      };
    },

    [actions.fetchProjectHistorySuccess]: (state, { payload }) => {
      return {
        ...state,
        projectHistory: payload,
      };
    },
    [actions.clearProjectHistory]: (state) => {
      return {
        ...state,
        projectHistory: null,
      };
    },

  },
  defaultState
)
