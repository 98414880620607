import React from 'react';

import { Button, Tooltip, } from './';

const S = {
  ads: {
    icon: 'A',
    title: 'Ads - based on data from FB, Adwords, Snapchat and other advertising cabinets',
  },
  magnus: {
    icon: 'M',
    title: 'Magnus - based on data from Magnus database',
  },
  store: {
    icon: 'S',
    title: 'Store - based on data from App Store Connect or Google Play ',
  },
  tracker: {
    icon: 'T',
    title: 'Tracker - based on data from Adjust, Appsflyer ',
  },
}

export default ({ sources, size, style, ...props }) => sources && sources.length ? (
  <div style={{ margin: '0 2px', ...style }}>
    {sources.map(s =>
      <Tooltip key={s} placement="bottomLeft" title={S[s].title}>
        <Button
          shape="circle"
          size="small"
          ghost
          style={{
            width: size === 'small' ? 20 : undefined,
            minWidth: size === 'small' ? 20 : undefined,
            height: size === 'small' ? 20 : undefined,
            fontSize: size === 'small' ? 11 : undefined,
            lineHeight: size === 'small' ? '20px' : undefined,
            margin: '0 -2px',
            color: 'rgba(0, 0, 0, 0.65)',
            borderColor: 'rgba(0, 0, 0, 0.65)',
          }}
        >
          <span>{S[s].icon}</span>
        </Button>
      </Tooltip>
    )}
  </div>
) : null;
