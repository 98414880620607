import _ from 'lodash';
import { all, take, takeEvery, call, put, fork, race, select, } from 'redux-saga/effects';
import { push, } from 'connected-react-router';

import API from 'src/service/api';
import { getApiErrorText, getApiErrorStatus, } from 'src/helpers/errors-api';

import { createFetchGenerator, } from '../helpers';

import AppActions from '../app/actions';
import MagnusActions from '../magnus/actions';

import actions from './actions';
import { parseCompany } from './utils';


const tokenStorageKey = 'token';
const companiesStorageKey = user => `companies-${user?.id}`;

function* sagaRefferalLinkFlow() {
  while (true) {
    const { payload = {} } = yield take(actions.fetchInvite);

    let rs = yield call(API.fetchInvite, payload);

    if (rs instanceof Error) {
      yield put(actions.setSandbox({ status: 'error' }));
    } else {
      const company = rs.data;

      const user = yield select(state => state.auth.user);

      if (user) {
        const companies = yield select(state => state.magnus.companies);

        if (companies.some(item => item.id === company.id)) {
          yield put(push('/signin'));

          yield put(AppActions.showNotification({
            type: 'error',
            message: `You are already added to this company.`,
          }));
        } else {
          rs = yield call(API.createUserCompany, { invite_hash: payload.inviteHash });

          yield put(push('/signin'));

          if (rs instanceof Error) {
            yield put(actions.setSandbox({ status: 'error' }));
          } else {
            yield put(AppActions.showNotification({
              type: 'success',
              message: `Your request has been sent. Please wait until administrator approve it.`,
            }));
          }
        }
      } else {
        yield put(actions.setSandbox({ status: 'signup', data: company }));
      }
    }
  }
}

function* sagaSignUpFlow() {
  while (true) {
    const { payload = {} } = yield take(actions.signup)
      , args = _.pick(payload, ['email', 'name', 'password', 'invite_hash']);

    yield put(actions.setSandbox({ lock: true }));

    const rs = yield call(API.signup, args);

    yield put(actions.setSandbox({ lock: false }));

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(actions.setSandbox({ status: 'success', data: args }));
    }
  }
}

function* sagaVerifyInviteFlow() {
  while (true) {
    const { payload = {} } = yield take(actions.verifyInvite);

    let rs = yield call(API.verifyInvite, payload);

    if (rs instanceof Error) {
      yield put(actions.setSandbox({ status: 'error', error: getApiErrorStatus(rs) !== 422 ? getApiErrorText(rs) : '', }));
    } else {
      const data = rs.data
        , user = yield select(state => state.auth.user);

      if (data.user) {
        const args = { key: payload.key };

        if (!user) {
          args.token = data.user.access_token;
        }

        rs = yield call(API.approveInvite, args);

        if (rs instanceof Error) {
          console.warn(getApiErrorText(rs));
          yield put(actions.setSandbox({ status: 'error', error: getApiErrorStatus(rs) !== 422 ? getApiErrorText(rs) : '', }));
        } else {
          if (user && data.user.id === user.id) {
            yield put(push('/signin'));
            yield put(actions.changeCompany({ companies: [data.company.id] }));
          } else {
            yield put(actions.authorize({ token: data.user.access_token, path: '/' }));
          }
        }
      } else {
        yield put(actions.setSandbox({ status: 'signup', key: payload.key, data: data }));
      }
    }
  }
}

function* sagaVerifyResetPasswordFlow() {
  while (true) {
    const { payload = {} } = yield take(actions.verifyResetPassword);

    let rs = yield call(API.verifyResetPassword, payload);

    if (rs instanceof Error) {
      yield put(actions.setSandbox({ status: 'error' }));
    } else {
      yield put(actions.setSandbox({ status: 'success' }));
    }
  }
}

function* sagaResetPasswordFlow() {
  while (true) {
    const { payload = {} } = yield take(actions.resetPassword);

    let rs = yield call(API.resetPassword, payload);

    if (rs instanceof Error) {
      yield put(actions.setSandbox({ status: 'error' }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Password was successfully updated.`,
      }));

      yield put(actions.authorize({ token: rs.data.token, path: '/' }));
    }
  }
}

function* sagaInviteSignUpFlow() {
  while (true) {
    const { payload = {} } = yield take(actions.signupInvite);

    yield put(actions.setSandbox({ lock: true }));

    let rs = yield call(API.signupInvite, payload);

    yield put(actions.setSandbox({ lock: false }));

    if (rs instanceof Error) {
      yield put(actions.setSandbox({ status: 'error' }));
    } else {
      yield put(actions.authorize({ token: rs.data.access_token, path: '/' }));
    }
  }
}

function* sagaConfirmEmailFlow() {
  while (true) {
    const { payload = {} } = yield take(actions.confirmEmail);

    let rs = yield call(API.confirmEmail, payload);

    if (rs instanceof Error) {
      yield put(actions.setSandbox({ status: 'error' }));
    } else {
      yield put(actions.authorize({ token: rs.data.access_token, path: '/' }));
    }
  }
}

function* sagaLoginFlow() {
  while (true) {
    const { payload = {} } = yield take(actions.login);

    yield put(actions.setSandbox({ lock: true }));

    const rs = yield call(API.login, payload);

    yield put(actions.setSandbox({ lock: false }));

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(actions.authorize({
        token: rs.data.access_token,
        path: payload.path,
      }));
      if (payload.invite_hash) {
        yield put(AppActions.showNotification({
          type: 'success',
          message: `Your request has been sent. Please wait until administrator approve it.`,
        }));
      }
    }
  }
}

function* sagaAuthorizeFlow() {
  while (true) {
    const { payload = {} } = yield take(actions.authorize);

    const user = yield select(state => state.auth.user);

    if (payload.token && user) {
      yield put(actions.logout);
    }

    const token = payload.token || localStorage.getItem(tokenStorageKey);

    if (token) {
      yield put(actions.fetchProfile({ token }));

      let [success] = yield race([take(actions.fetchProfileSuccess), take(actions.fetchProfileError)]);

      if (success) {
        const { payload: profile } = success;

        localStorage.setItem(tokenStorageKey, token);

        yield put(MagnusActions.fetchCompanies({ token }));

        [success] = yield race([take(MagnusActions.fetchCompaniesSuccess), take(MagnusActions.fetchCompaniesError)]);

        if (success) {
          let { payload: companies } = success;
          companies = _.filter(companies, { status: 'active', });

          let c;

          if (companies.length > 0) {
            const location = yield select(state => state.router.location);
            const arr = location.pathname.split('/');
            if (
              arr[1]
              && arr[1] !== 'signin'
              && arr[1] !== 'profile'
              && arr[1] !== 'companies'
              && arr[1] !== 'backurl'
              && arr[1] !== 'instructions'
            ) {
              c = parseCompany(arr[1]);
            }
            else {
              c = JSON.parse(localStorage.getItem(companiesStorageKey(profile)));
            }
          }

          c = _.filter(_.map(c, i => companies.find(item => item.id === i)));

          if (!c.length && companies.length === 1) {
            c = companies.slice();
          }

          localStorage.setItem(companiesStorageKey(profile), JSON.stringify(_.map(c, 'id')));

          yield put(actions.authorizeSuccess({
            token,
            user: profile,
            company: c.length === 1 ? c[0] : null,
            companies: c,
          }));

          if (payload.path) {
            yield put(push(payload.path));
          } else if (payload.token) {
            yield put(push('/signin'));
          }
        }
      }
    } else {
      yield put(actions.authorizeSuccess());
    }
  }
}

function* sagaLogout() {
  yield takeEvery(actions.logout, function ({ payload }) {
    localStorage.removeItem(tokenStorageKey);
    window.location.reload();
  });
}

function* sagaUpdateProfileFlow() {
  while (true) {
    const { payload } = yield take(actions.updateProfile);

    const args = payload.old_password && payload.password && payload.password_confirm && payload.password === payload.password_confirm
      ? { old_password: payload.old_password, password: payload.password, password_confirm: payload.password_confirm }
      : { old_password: payload.old_password, password: payload.old_password, password_confirm: payload.old_password };

    let rs = yield call(API.updatePassword, args);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      const formData = new FormData();

      formData.append('name', payload.name);
      formData.append('email', payload.email);

      if (payload.avatar) formData.append('avatar', payload.avatar.file);

      rs = yield call(API.updateProfile, formData);

      if (rs instanceof Error) {
        yield put(AppActions.showNotification({
          type: 'error',
          message: `Error`,
          description: getApiErrorText(rs),
        }));
      } else {
        const user = rs.data;

        yield put(actions.setUser({ user }));

        yield put(push('/profile'));

        yield put(AppActions.showNotification({
          type: 'success',
          message: `Profile was successfully updated.`,
        }));
      }
    }
  }
}

function* sagaChangeCompanyFlow() {
  while (true) {
    const { payload } = yield take(actions.changeCompany);

    yield put(MagnusActions.fetchCompanies());

    let [success] = yield race([take(MagnusActions.fetchCompaniesSuccess), take(MagnusActions.fetchCompaniesError)]);

    if (success) {
      let { payload: companies } = success;
      companies = _.filter(companies, { status: 'active', });

      const user = yield select(state => state.auth.user);

      if (companies.length > 0) {
        const c = _.filter(_.map(payload.companies, c => companies.find(item => item.id === c)));

        if (c.length) {
          localStorage.setItem(companiesStorageKey(user), JSON.stringify(_.map(c, 'id')));
          yield put(actions.setCompany({ company: c.length === 1 ? c[0] : null, companies: c }));
          window.location.reload();
        }
        else {
          localStorage.setItem(companiesStorageKey(user), JSON.stringify([]));
          yield put(actions.unsetCompany());
        }
      }
      else {
        localStorage.setItem(companiesStorageKey(user), JSON.stringify([]));
        yield put(actions.unsetCompany());
      }
    }
  }
}



const sagas = [
  sagaSignUpFlow,
  sagaConfirmEmailFlow,
  sagaLoginFlow,
  sagaAuthorizeFlow,
  sagaLogout,

  sagaVerifyInviteFlow,
  sagaInviteSignUpFlow,
  sagaRefferalLinkFlow,

  sagaVerifyResetPasswordFlow,
  sagaResetPasswordFlow,

  sagaUpdateProfileFlow,

  sagaChangeCompanyFlow,

  createFetchGenerator('fetchProfile', actions),
];


export default function* () {
  yield all(sagas.map(item => fork(item)));
}
