import { all } from 'redux-saga/effects';

import app from './app/saga';
import auth from './auth/saga';
import magnus from './magnus/saga';
import filter from './filter/saga';
import settings from './settings/saga';
import remoteConfig from './remote-config/saga';

import accounts from './accounts/saga';
import revenue from './revenue/saga';
import finance from './finance/saga';

import sdk from './sdk/saga';
import subtruck from './subtruck/saga';
import variator from './variator/saga';
import mutator from './mutator/saga';
import evtruck from './evtruck/saga';

export default function* rootSaga() {
  yield all([
    app(),
    auth(),
    magnus(),
    filter(),
    settings(),
    remoteConfig(),

    accounts(),
    revenue(),
    finance(),

    sdk(),
    subtruck(),
    variator(),
    mutator(),
    evtruck(),
  ]);
}
