import firebase from 'firebase/app';
import 'firebase/remote-config';
import { all, takeEvery, fork, put, select, } from 'redux-saga/effects';

import actions from './actions';

import { parse, onFetched, } from './utils';

export function* sagaFetch() {
  yield takeEvery(actions.fetch, function* () {
    try {
      firebase.remoteConfig().settings = { minimumFetchIntervalMillis: 60000, };
      yield firebase.remoteConfig().fetchAndActivate();
      const all = firebase.remoteConfig().getAll();
      const state = yield select(state => state.remoteConfig);
      const res = parse(state, all);
      yield put(actions.fetchSuccess(res));
      onFetched(yield select(state => state.remoteConfig));
    }
    catch (err) {
      console.warn(err);
      yield put(actions.fetchError(err));
    }
  });
}

export default function* () {
  yield all([
    fork(sagaFetch),
  ]);
}
