import { all, take, call, put, fork, race, select, delay, } from 'redux-saga/effects';

import API from 'src/service/api';
import { getApiErrorText, } from 'src/helpers/errors-api';

import { message, } from 'src/components/ui';

import { createFetchGenerator, processError, } from '../helpers';

import AppActions from '../app/actions';
import AuthActions from '../auth/actions';

import actions from './actions';


function* pollNotifications() {
  while (true) {
    yield delay(10000);

    try {
      const user = yield select(state => state.auth.user);
      if (user) {
        let { data } = yield call(API.notifications);
        if (data.length > 0) {
          data.forEach(item => {
            switch (item.type) {
              case 'publisher_ios_token_expired':
                message.error(`iOS token for ${item.data.name} account expired. Please update the token.`, 10);
                break;
              case 'publisher_android_permission_denied':
                message.error(`${item.data.name} publisher hasn't enough permissions. Please check the role for this publisher in the Google play android developer console.`, 10);
                break;
              case 'publisher_android_credentials_invalid':
                message.error(`${item.data.name} publisher has invalid credentials.`, 10);
                break;
              case 'expenses_fb_token_expired':
                message.error(`Facebook token for ${item.data.name} account expired. Please update the token.`, 10);
                break;
              case 'expenses_fb_token_permission_denied':
                message.error(`Facebook token for ${item.data.name} has invalid credentials. Please update the token.`, 10);
                break;
              case 'expenses_tik_tok_token_expired':
                message.error(`TikTok token for ${item.data.name} account expired. Please update the token.`, 10);
                break;
              case 'monetization_iron_source_key_invalid':
                message.error(`ironSource key for ${item.data.name} is invalid.`, 10);
                break;
              default:
                message.error(item.type, 10);
                break;
            }
          });
        }
      }
    } catch (err) { }

    yield delay(110000);
  }
}

function* watchPollNotifications() {
  while (true) {
    yield take(AuthActions.authorizeSuccess);
    yield race([
      call(pollNotifications),
      take(AuthActions.logout)
    ]);
  }
}

function* sagaCreateCompanyFlow() {
  while (true) {
    const { payload } = yield take(actions.createCompany);

    const rs = yield call(API.createCompany, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      const company = rs.data;

      yield put(AppActions.showNotification({
        type: 'success',
        message: `Company ${company.name} was successfully added.`,
      }));

      yield put(actions.fetchCompanies());

      window.location.reload();
    }
  }
}

function* sagaUpdateCompanyFlow() {
  while (true) {
    const { payload } = yield take(actions.updateCompany);

    payload.name = payload.name.trim();

    const rs = yield call(API.updateCompany, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Company ${payload.name} was successfully updated.`,
      }));

      yield put(actions.fetchCompanies());
    }
  }
}

function* sagaLeaveCompanyFlow() {
  while (true) {
    const { payload } = yield take(actions.leaveCompany);

    const rs = yield call(API.leaveCompany, payload.id);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `You leave ${payload.name} company.`,
      }));

      const { company } = yield select(state => state.auth);

      yield put(actions.fetchCompanies());

      if (payload.id === company?.id) {
        yield put(AuthActions.changeCompany());
      }
    }
  }
}

function* sagaUpdateUserSources() {
  while (true) {
    const { payload } = yield take(actions.updateUserSources);

    payload.allow_all_sources = payload.allow_all_sources ? 1 : 0;

    const rs = yield call(API.updateUserSources, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    }
  }
}

function* sagaCreateAndroidApp() {
  while (true) {
    const { payload } = yield take(actions.createAndroidApp);

    const rs = yield call(API.createAndroidApp, payload);

    if (rs instanceof Error) {
      yield processError(rs);
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `App was added.`,
      }));

      yield put(AppActions.toggleModalForm(null));

      yield put(actions.fetchCompanyApps());
      yield put(actions.fetchApps());
    }
  }
}


const sagas = [
  sagaCreateCompanyFlow,
  sagaUpdateCompanyFlow,
  sagaLeaveCompanyFlow,

  createFetchGenerator('fetchCompanies', actions),
  createFetchGenerator('fetchCompanyUsers', actions),
  createFetchGenerator('fetchApps', actions),
  createFetchGenerator('fetchUserApps', actions),
  createFetchGenerator('fetchCompanyApps', actions),
  createFetchGenerator('fetchRoles', actions),
  createFetchGenerator('fetchCompanyRoles', actions),
  createFetchGenerator('fetchInvites', actions),
  createFetchGenerator('fetchActions', actions),
  createFetchGenerator('fetchCountries', actions),

  createFetchGenerator('fetchCompanySources', actions),
  createFetchGenerator('fetchUserSources', actions),
  sagaUpdateUserSources,
  sagaCreateAndroidApp,
];

if (process.env.NODE_ENV === 'production' || window.location.hostname !== 'localhost') {
  sagas.push(watchPollNotifications);
}

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
