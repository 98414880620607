import _ from 'lodash';
import mem from 'mem';

import { createActions } from 'redux-actions';

import API from 'src/service/api';

import { retrofy } from '../retrofy';


let asyncActions = {
  'FETCH_MARKETING_SOURCES': 'fetchMarketingSources',
  'FETCH_MARKETING_CAMPAIGNS': 'fetchMarketingCampaigns',
  'FETCH_MARKETING_AD_GROUPS': 'fetchMarketingAdGroups',
  'FETCH_MARKETING_CREATIVES': 'fetchMarketingCreatives',
  'FETCH_MARKETING_COHORT_REPORT': 'fetchMarketingCohortReport',
  'FETCH_MARKETING_TIMELINE_REPORT': 'fetchMarketingTimelineReport',
};

asyncActions = _.mapValues(asyncActions, (item) => (
  async (...args) => {
    const rs = await API[item](...args);

    if (rs instanceof Error) throw rs;

    return rs.data;
  }
));

const actions = createActions(
  {
    ...asyncActions,
  },
  'FETCH_MARKETING_CAMPAIGNS_SUCCESS',
);

actions.fetchMarketingDashboard = retrofy('fetchMarketingDashboard');

const memOptions = { maxAge: 60000, cacheKey: (payload) => JSON.stringify(payload) };
actions.fetchMarketingSources = mem(actions.fetchMarketingSources, memOptions);
actions.fetchMarketingCampaigns = mem(actions.fetchMarketingCampaigns, memOptions);
actions.fetchMarketingAdGroups = mem(actions.fetchMarketingAdGroups, memOptions);
actions.fetchMarketingCreatives = mem(actions.fetchMarketingCreatives, memOptions);

export default actions;
