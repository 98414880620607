import _ from 'lodash';
import { createActions } from 'redux-actions';

import API from 'src/service/api';

import { createSuccessErrorActions } from '../helpers';


let asyncActions = [
  'FORGOT_PASSWORD',
];

asyncActions = Object.assign({}, ..._.map(asyncActions, (item) => ({
  [item]: async payload => {
    const rs = await API[_.camelCase(item)](payload);
    if (rs instanceof Error) throw rs;
    return rs.data;
  }
})));


export default createActions(
  { ...asyncActions },

  'LOGIN',
  'LOGOUT',
  'AUTHORIZE',
  'AUTHORIZE_SUCCESS',

  'CONFIRM_EMAIL',
  'UPDATE_PROFILE',
  'UPDATE_PASSWORD',
  'SET_USER',

  'VERIFY_RESET_PASSWORD',
  'RESET_PASSWORD',

  'FETCH_INVITE',
  'VERIFY_INVITE',
  'SIGNUP_INVITE',

  'SET_SANDBOX',
  'CLEAR_SANDBOX',

  'CHANGE_COMPANY',
  'SET_COMPANY',
  'UNSET_COMPANY',

  'SIGNUP',

  ...createSuccessErrorActions('FETCH_PROFILE'),

  { prefix: 'AUTH', }

);
