import React from 'react';
import { connect } from 'react-redux';
import { withRouter, } from 'react-router';
import { Link } from 'react-router-dom';
import { compose, withState, withHandlers, } from 'recompose';
import { SettingFilled } from '@ant-design/icons';

import { SECTIONS, ICONS, } from 'src/constants/sections';

import { hasPermissions, } from 'src/helpers/permissions';

import { Menu, Popover, } from 'src/components/ui';

import TopbarDropdownWrapper from './topbarDropdown.style';


export default compose(
  withRouter,
  connect(
    state => ({
      company: state.auth.company,
      app: state.app,
    }),
  ),
  withState('visible', 'handleVisibleChange', false),
  withHandlers({
    handleNav:
      ({ handleVisibleChange, }) =>
        () => {
          setTimeout(() => {
            handleVisibleChange(false);
          }, 320);
        },
  }),
)(({
  app,
  company,
  visible,
  handleVisibleChange,
  handleNav,
}) => {
  const cname = company?.name || '';

  const content = (
    <TopbarDropdownWrapper
      style={{ width: 280 }}
    >
      <Menu
        mode={'inline'}
        selectable={false}
        openKeys={[`${cname}/${SECTIONS.COMPANY}`, `${cname}/${SECTIONS.SDK_SUMMARY}`, `${cname}/${SECTIONS.ACCOUNTS}`, `${cname}/${SECTIONS.PEOPLE}`]}
        selectedKeys={app.current}
        inlineIndent={28}
      >
        <Menu.SubMenu
          key={`${cname}/${SECTIONS.COMPANY}`}
          title={
            <span className="isoMenuHolder">
              <i className={ICONS[SECTIONS.COMPANY]} style={{ marginRight: 12, fontSize: '20px', }} />
              <span className="nav-text">COMPANY</span>
            </span>
          }
        >
          {hasPermissions(['accounts/view', 'accounts/manage', 'publishers/view', 'publishers/manage']) && (
            <Menu.Item key={`${cname}/${SECTIONS.COMPANY}/applications`}>
              <Link
                to={`/${cname}/${SECTIONS.COMPANY}/applications`}
                onClick={handleNav}
              >
                <span>Applications</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key={`${cname}/${SECTIONS.COMPANY}/notes`}>
            <Link
              to={`/${cname}/${SECTIONS.COMPANY}/notes`}
              onClick={handleNav}
            >
              <span>Notes</span>
            </Link>
          </Menu.Item>
          {hasPermissions(['analytics/overview']) && (
            <Menu.Item key={`${cname}/${SECTIONS.COMPANY}/trackers`}>
              <Link
                to={`/${cname}/${SECTIONS.COMPANY}/trackers`}
                onClick={handleNav}
              >
                <span>Trackers</span>
              </Link>
            </Menu.Item>
          )}
          {hasPermissions(['accounts/view']) && (
            <Menu.Item key={`${cname}/${SECTIONS.COMPANY}/revenue-compare`}>
              <Link
                to={`/${cname}/${SECTIONS.COMPANY}/revenue-compare`}
                onClick={handleNav}
              >
                <span>Revenue Compare</span>
              </Link>
            </Menu.Item>
          )}
        </Menu.SubMenu>
        {hasPermissions(['sdk/mutator/view', 'sdk/mutator/manage', 'sdk/subtruck/view']) && (
          <Menu.SubMenu
            key={`${cname}/${SECTIONS.SDK_SUMMARY}`}
            title={
              <span className="isoMenuHolder">
                <i className={ICONS[SECTIONS.SDK_SUMMARY]} style={{ marginRight: 12, fontSize: '20px', }} />
                <span className="nav-text">SDK</span>
              </span>
            }
          >
            {hasPermissions(['sdk/mutator/view', 'sdk/mutator/manage']) && (
              <Menu.Item key={`${cname}/${SECTIONS.SDK_SUMMARY}/experiments`}>
                <Link
                  to={`/${cname}/${SECTIONS.SDK_SUMMARY}/experiments`}
                  onClick={handleNav}
                >
                  <span>Experiments</span>
                </Link>
              </Menu.Item>
            )}
            {hasPermissions(['sdk/subtruck/view']) && (
              <Menu.Item key={`${cname}/${SECTIONS.SDK_SUMMARY}/subtruck-fails`}>
                <Link
                  to={`/${cname}/${SECTIONS.SDK_SUMMARY}/subtruck-fails`}
                  onClick={handleNav}
                >
                  <span>SubTruck Fails</span>
                </Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}
        {hasPermissions(['accounts/view', 'accounts/manage', 'publishers/view', 'publishers/manage']) && (
          <Menu.SubMenu
            key={`${cname}/${SECTIONS.ACCOUNTS}`}
            title={
              <span className="isoMenuHolder">
                <i className={ICONS[SECTIONS.ACCOUNTS]} style={{ marginRight: 12, fontSize: '20px', }} />
                <span className="nav-text">ACCOUNTS</span>
              </span>
            }
          >
            {hasPermissions(['publishers/view', 'publishers/manage']) && (
              <Menu.Item key={`${cname}/${SECTIONS.ACCOUNTS}/publishers`}>
                <Link
                  to={`/${cname}/${SECTIONS.ACCOUNTS}/publishers`}
                  onClick={handleNav}
                >
                  <span>Publishers</span>
                </Link>
              </Menu.Item>
            )}
            {hasPermissions(['accounts/view', 'accounts/manage']) && (
              <Menu.Item key={`${cname}/${SECTIONS.ACCOUNTS}/linked-accounts`}>
                <Link
                  to={`/${cname}/${SECTIONS.ACCOUNTS}/linked-accounts`}
                  onClick={handleNav}
                >
                  <span>Linked Accounts</span>
                </Link>
              </Menu.Item>
            )}
            {hasPermissions(['accounts/view', 'accounts/manage']) && (
              <Menu.Item key={`${cname}/${SECTIONS.ACCOUNTS}/ads-accounts`}>
                <Link
                  to={`/${cname}/${SECTIONS.ACCOUNTS}/ads-accounts`}
                  onClick={handleNav}
                >
                  <span>Ads Accounts</span>
                </Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}
        {hasPermissions(['users/view', 'users/manage', 'roles/view', 'roles/manage']) && (
          <Menu.SubMenu
            key={`${cname}/${SECTIONS.PEOPLE}`}
            title={
              <span className="isoMenuHolder" >
                <i className={ICONS[SECTIONS.PEOPLE]} style={{ marginRight: 12, fontSize: '20px', }} />
                <span className="nav-text">USER MANAGEMENT</span>
              </span>
            }
          >
            {hasPermissions(['users/view', 'users/manage']) && (
              <Menu.Item key={`${cname}/${SECTIONS.PEOPLE}/users`}>
                <Link
                  to={`/${cname}/${SECTIONS.PEOPLE}/users`}
                  onClick={handleNav}
                >
                  <span>Users</span>
                </Link>
              </Menu.Item>
            )}
            {hasPermissions(['roles/view', 'roles/manage']) && (
              <Menu.Item key={`${cname}/${SECTIONS.PEOPLE}/roles`}>
                <Link
                  to={`/${cname}/${SECTIONS.PEOPLE}/roles`}
                  onClick={handleNav}
                >
                  <span>Roles</span>
                </Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}
      </Menu>
    </TopbarDropdownWrapper>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      arrowPointAtCenter={true}
      placement="bottomLeft"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <SettingFilled
        style={{
          fontSize: '28px',
          lineHeight: '28px',
          height: 28,
          marginTop: 6,
          marginBottom: 6,
          color: '#777',
        }}
      />
    </Popover>
  );
});
