import React from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';
import { Spin } from 'antd';

const Styles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9999;
`;

export default compose(

)(props => (
  <Styles><Spin {...props} /></Styles>
));
