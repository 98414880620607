import React from 'react';
import { AppleFilled, AndroidFilled, ChromeFilled, QuestionCircleFilled, } from '@ant-design/icons';

export default ({ platform, ...props }) => (
  platform === 'ios'
    ? <AppleFilled {...props} />
    : platform === 'android'
      ? <AndroidFilled {...props} />
      : platform === 'web'
        ? <ChromeFilled {...props} />
        : <QuestionCircleFilled {...props} />
);
