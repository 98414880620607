import { all, takeEvery, fork, select } from 'redux-saga/effects';

import { notification, } from 'src/components/ui';

import actions from './actions';

export function* sagaCollapseChange() {
  yield takeEvery(actions.toggleCollapsed, function* () {
    const collapsed = yield select(state => state.app.collapsed);
    localStorage.setItem(`sidebar`, !collapsed);
  });
}

function* sagaShowNotification() {
  /* eslint-disable-next-line require-yield */
  yield takeEvery(actions.showNotification, function* ({ payload = {} }) {
    notification[payload.type]({
      message: { success: 'Done', error: 'Sorry, something went wrong :(' }[payload.type],
      placement: 'topRight',
      duration: 20,
      ...payload
    });
  });
}

const sagas = [
  sagaCollapseChange,
  sagaShowNotification,
];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
