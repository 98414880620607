import _ from 'lodash';
import fp from 'lodash/fp';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, branch, renderNothing, lifecycle, withProps, withStateHandlers, mapProps, } from 'recompose';

import { Row, Col, Button, } from 'src/components/ui';


export const PRESETS = Object.freeze({
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST_7: 'LAST_7',
  LAST_30: 'LAST_30',
  LAST_90: 'LAST_90',
  LAST_180: 'LAST_180',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  ALL_TIME: 'ALL_TIME',
  MONTH_END: 'MONTH_END',
  NEXT_30: 'NEXT_30',
  NEXT_60: 'NEXT_60',
  NEXT_90: 'NEXT_90',
  NEXT_120: 'NEXT_120',
  NEXT_180: 'NEXT_180',
  NEXT_270: 'NEXT_270',
  NEXT_365: 'NEXT_365',
  FULL_YEAR: 'FULL_YEAR',
});


const NEXT_PRESETS = Object.freeze({
  [PRESETS.NEXT_30]: 30,
  [PRESETS.NEXT_60]: 60,
  [PRESETS.NEXT_90]: 90,
  [PRESETS.NEXT_120]: 120,
  [PRESETS.NEXT_180]: 180,
  [PRESETS.NEXT_270]: 270,
  [PRESETS.NEXT_365]: 365,
});


export const PRESET_TITLES = Object.freeze({
  [PRESETS.TODAY]: 'Today',
  [PRESETS.YESTERDAY]: 'Yesterday',
  [PRESETS.LAST_7]: 'Last 7 Days',
  [PRESETS.LAST_30]: 'Last 30 Days',
  [PRESETS.LAST_90]: 'Last 90 Days',
  [PRESETS.LAST_180]: 'Last 180 Days',
  [PRESETS.THIS_MONTH]: 'This Month',
  [PRESETS.LAST_MONTH]: 'Last Month',
  [PRESETS.ALL_TIME]: 'All Time',
  [PRESETS.MONTH_END]: 'This Month',
  [PRESETS.NEXT_30]: '+ 30 Days',
  [PRESETS.NEXT_60]: '+ 60 Days',
  [PRESETS.NEXT_90]: '+ 90 Days',
  [PRESETS.NEXT_120]: '+ 120 Days',
  [PRESETS.NEXT_180]: '+ 180 Days',
  [PRESETS.NEXT_270]: '+ 270 Days',
  [PRESETS.NEXT_365]: '+ 365 Days',
  [PRESETS.FULL_YEAR]: 'Full Year',
});

const getInitialDateFromApps = fp.flow([
  fp.map('installs'),
  fp.compact,
  fp.map('date'),
  fp.sortBy(_.identity),
  fp.first,
]);

export function getPeriodByPreset(preset, { app, apps, fullYearStart, disabledDate, refDate, } = {}) {
  let to = refDate && NEXT_PRESETS[preset] ? moment(refDate) : moment({ hour: 0, })
    , from = to.clone();

  switch (preset) {
    case PRESETS.YESTERDAY: {
      to.add(-1, 'days');
      from.add(-1, 'days');
      break;
    }
    case PRESETS.LAST_7: {
      from.add(-6, 'days');
      break;
    }
    case PRESETS.LAST_30: {
      from.add(-29, 'days');
      break;
    }
    case PRESETS.LAST_90: {
      from.add(-89, 'days');
      break;
    }
    case PRESETS.LAST_180: {
      from.add(-179, 'days');
      break;
    }
    case PRESETS.THIS_MONTH: {
      from.startOf('month');
      break;
    }
    case PRESETS.LAST_MONTH: {
      from.subtract(1, 'months').startOf('month');
      to.subtract(1, 'months').endOf('month');
      break;
    }
    case PRESETS.ALL_TIME: {
      const initialDate = getInitialDateFromApps(app ? [app] : apps);
      if (initialDate) {
        from = moment(initialDate, 'YYYY-MM-DD')
      }
      else {
        from.subtract(3, 'year').startOf('year');
      }
      break;
    }
    case PRESETS.MONTH_END: {
      to.endOf('month');
      break;
    }
    case PRESETS.NEXT_30:
    case PRESETS.NEXT_60:
    case PRESETS.NEXT_90:
    case PRESETS.NEXT_120:
    case PRESETS.NEXT_180:
    case PRESETS.NEXT_270:
    case PRESETS.NEXT_365: {
      to.add(NEXT_PRESETS[preset], 'days');
      break;
    }
    case PRESETS.FULL_YEAR: {
      if (fullYearStart) {
        to = moment(fullYearStart);
      }
      while (to.isSameOrBefore(from)) {
        to.add(1, 'year');
      }
      to.add(-1, 'day');
      break;
    }
    default:
      break;
  }

  while (from.isBefore(to) && disabledDate && disabledDate(from)) {
    from.add(1, 'day');
  }

  while (to.isAfter(from) && disabledDate && disabledDate(to)) {
    to.add(-1, 'day');
  }

  return { from: from.format('YYYY-MM-DD'), to: to.format('YYYY-MM-DD'), };
}

export default compose(
  connect(
    state => ({
      filter: state.filter,
      apps: state.magnus.apps,
    }),
  ),
  branch(({ apps, }) => !apps, renderNothing),
  withProps(({ filter, apps, }) => ({ apps: apps.filter(item => filter.apps.includes(item.id)), })),
  branch(
    ({ onOpenChange, }) => !onOpenChange,
    withStateHandlers(
      {
        open: false,
      },
      {
        onOpenChange: () => (open) => {
          return ({
            open,
          });
        },
      }
    ),
  ),
  withProps(({
    presets,
    disabledDate,
    refDate,
  }) => ({
    presets: _.filter(presets, p => (
      !refDate
      || !NEXT_PRESETS[p]
      || !disabledDate
      || !disabledDate(moment(refDate).add(NEXT_PRESETS[p], 'days'))
    )),
  })),
  withHandlers({
    handlePresetClick:
      ({
        onChange,
        app,
        apps,
        fullYearStart,
        disabledDate,
        refDate,
        onOpenChange,
      }) =>
        (preset) => {
          let { from, to, } = getPeriodByPreset(preset, { app, apps, fullYearStart, disabledDate, refDate, });
          onChange([from, to, preset]);
          onOpenChange(false);
        },
  }),
  withHandlers({
    renderExtraFooter:
      ({ preset, presets, handlePresetClick, }) =>
        () => (
          <Row type="flex" justify="center" gutter={4}>
            {
              presets.map(i => (
                <Col key={i}>
                  <Button
                    size="small" type="link"
                    disabled={i === preset}
                    style={{ fontSize: 12, }}
                    onClick={_ => handlePresetClick(i)}>{PRESET_TITLES[i]}</Button>
                </Col>
              ))
            }
          </Row>
        ),
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.preset) {
        this.props.handlePresetClick(this.props.preset);
      }
    },
    componentDidUpdate(prevProps) {
      let p = ['preset', 'app', 'apps', 'fullYearStart'];
      if (this.props.preset && !_.isEqual(_.pick(prevProps, p), _.pick(this.props, p))) {
        setTimeout(() => {
          this.props.handlePresetClick(this.props.preset);
        }, 500); // in order to prevent change-event while form init
      }
    },
  }),
  mapProps(props => _.omit(
    props,
    'dispatch',
    'app',
    'apps',
    'fullYearStart',
    'filter',
    'handlePresetClick',
    'presets',
    'preset',
  )),
);
