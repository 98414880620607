import { createActions, } from 'redux-actions';

import { createSuccessErrorActions, } from '../../helpers';

export default createActions(

  ...createSuccessErrorActions('FETCH_PROMO_TARGETS'),

  'CREATE_PROMO_TARGET',
  'UPDATE_PROMO_TARGET',
  'DELETE_PROMO_TARGET',

  { prefix: 'VARIATOR/PROMO_TARGETS', }

);
