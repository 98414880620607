import { createActions } from 'redux-actions';

export default createActions(

  'RESTORE_FILTER',
  'UPDATE_FILTER',
  'UPDATE_FILTER_SUCCESS',

  'UPDATE_APPS_FILTER',
  'UPDATE_APPS_FILTER_SUCCESS',

  'UPDATE_DATES_FILTER',
  'UPDATE_DATES_FILTER_SUCCESS',

  'UPDATE_MARKETING_FILTER',
  'UPDATE_MARKETING_FILTER_SUCCESS',

  'SHOW_APPS_FILTER',
  'HIDE_APPS_FILTER',

  'SHOW_DATES_FILTER',
  'HIDE_DATES_FILTER',


  { prefix: 'FILTER', }

);
