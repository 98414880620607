import { handleActions } from 'redux-actions';

import actions from './actions';

const defaultState = {

  apps: [],

  period: {
    from: null,
    to: null,
    preset: null,
  },

  marketing: {},

  restored: false,

  showAppsFilter: 0,
  showDatesFilter: 0,

};

export default handleActions(
  {
    [actions.updateFilterSuccess]: (state, { payload }) => {
      return { ...state, ...payload, };
    },

    [actions.updateAppsFilterSuccess]: (state, { payload }) => {
      return { ...state, ...payload, };
    },
    [actions.updateDatesFilterSuccess]: (state, { payload }) => {
      return { ...state, ...payload, };
    },
    [actions.updateMarketingFilterSuccess]: (state, { payload }) => {
      return { ...state, ...payload, };
    },

    [actions.showAppsFilter]: (state) => {
      return { ...state, showAppsFilter: state.showAppsFilter + 1, };
    },
    [actions.hideAppsFilter]: (state) => {
      return { ...state, showAppsFilter: state.showAppsFilter - 1, };
    },

    [actions.showDatesFilter]: (state) => {
      return { ...state, showDatesFilter: state.showDatesFilter + 1, };
    },
    [actions.hideDatesFilter]: (state) => {
      return { ...state, showDatesFilter: state.showDatesFilter - 1, };
    },
  },
  defaultState
)
