import { combineReducers } from 'redux';

import campaigns from './campaigns/reducer';
import creatives from './creatives/reducer';
import placements from './placements/reducer';
import promoTargets from './promo-targets/reducer';

export default combineReducers({
  campaigns,
  creatives,
  placements,
  promoTargets,
});

