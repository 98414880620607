import _ from 'lodash';

const ERR_TTL_PATH = 'response.data.data.name';
const ERR_MSG_PATH = 'response.data.data.message';
const ERR_STTS_PATH = 'response.data.data.status';
const DEFAULT = 'Something went wrong. :(';

export function getApiErrorTitle(e) {
  return _.get(e, ERR_TTL_PATH, DEFAULT);
}

export function getApiErrorText(e) {
  return _.get(e, ERR_MSG_PATH, DEFAULT);
}

export function getApiErrorStatus(e) {
  return _.get(e, ERR_STTS_PATH);
}
