import _ from 'lodash';

export function sortByField(field) {
  return function (a, b) {
    a = _.get(a, field);
    b = _.get(b, field);

    if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    } else if (typeof a === 'string' || typeof b === 'string') {
      a = ('' + a).toLowerCase();
      b = ('' + b).toLowerCase();

      return a < b ? -1 : a > b ? 1 : 0;
    }
  }
}
