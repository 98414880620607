import _ from 'lodash';

import { createActions } from 'redux-actions';

import API from 'src/service/api';

import { createSuccessErrorActions } from '../helpers';


let asyncActions = [
  'CREATE_INVITE',
  'UPDATE_INVITE',
  'SIGNUP_INVITE',
  'APPROVE_INVITE',
  'DELETE_INVITE',
  'RESEND_INVITE',

  'CREATE_ROLE',
  'DELETE_ROLE',
  'UPDATE_ROLE',
  'ASSIGN_ROLE',

  'DELETE_USERS',

  'UPDATE_USER_COMPANY',
  'UPDATE_APP',
  'UPDATE_AVAILABLE_APPS',
  'FETCH_COMPANY_APPS',

  'FETCH_GOOGLE_ADS_REPORT',
];

asyncActions = Object.assign({}, ..._.map(asyncActions, (item) => ({
  [item]: async payload => {
    const rs = await API[_.camelCase(item)](payload);
    if (rs instanceof Error) throw rs;
    return rs.data;
  }
})));

export default createActions(
  { ...asyncActions },

  'CREATE_COMPANY',
  'LEAVE_COMPANY',
  'UPDATE_COMPANY',

  ...createSuccessErrorActions('FETCH_COMPANY_USERS'),
  ...createSuccessErrorActions('FETCH_COMPANY_ROLES'),
  ...createSuccessErrorActions('FETCH_COMPANIES'),
  ...createSuccessErrorActions('FETCH_INVITES'),
  ...createSuccessErrorActions('FETCH_ACTIONS'),
  ...createSuccessErrorActions('FETCH_ROLES'),
  ...createSuccessErrorActions('FETCH_APPS'),

  ...createSuccessErrorActions('FETCH_USER_APPS'),
  'CLEAR_USER_APPS',

  ...createSuccessErrorActions('FETCH_COMPANY_APPS'),
  'CLEAR_COMPANY_APPS',
  ...createSuccessErrorActions('FETCH_COUNTRIES'),

  ...createSuccessErrorActions('FETCH_COMPANY_SOURCES'),
  'CLEAR_COMPANY_SOURCES',

  ...createSuccessErrorActions('FETCH_USER_SOURCES'),
  'UPDATE_USER_SOURCES',
  'CLEAR_USER_SOURCES',

  'CREATE_ANDROID_APP',

  { prefix: 'MAGNUS', }

);
