import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor, history } from './redux/store';

import theme from './theme';

import Routes from './router';
import DashAppHolder from './style';


const MagnusApp = () => (
  <ThemeProvider theme={theme}>
    <DashAppHolder>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes history={history} />
        </PersistGate>
      </Provider>
    </DashAppHolder>
  </ThemeProvider>
);

export default MagnusApp;

