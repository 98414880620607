import CampaignsActions from './campaigns/actions';
import CreativesActions from './creatives/actions';
import PlacementsActions from './placements/actions';
import PromoTargetsActions from './promo-targets/actions';

export default {
  CampaignsActions,
  CreativesActions,
  PlacementsActions,
  PromoTargetsActions,
}
