import { handleActions, } from 'redux-actions';

import actions from './actions';

const defaultState = {
  promoTargets: null,
};

export default handleActions(
  {
    [actions.fetchPromoTargetsSuccess]: (state, { payload }) => ({ ...state, promoTargets: payload }),
  },
  defaultState
)
