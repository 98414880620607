import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { withRouter, } from 'react-router';
import { connect, } from 'react-redux';
import { compose, withHandlers, branch, withProps, renderNothing, } from 'recompose';
import styled from 'styled-components';

import { store } from 'src/redux/store';
import { mapActions, FilterActions, } from 'src/redux/actions';

import { RangePicker, PRESETS, getPeriodByPreset, } from 'src/components/date-picker';


const Styled = styled.div`
  margin-right: 20px;

  ${({ isMobileView }) => isMobileView && `
    width: 40px;
    height: 40px;
    .ant-calendar-picker {
      width: 40px;
      height: 40px;
    }
    input, .ant-calendar-range-picker-separator {
      display: none;
    }
  `}
`;

export default compose(
  withRouter,
  connect(
    state => ({
      isMobileView: state.app.view === 'MobileView',
      filter: state.filter,
      apps: state.magnus.apps,
    }),
    {
      ...mapActions(['updateDatesFilter'], FilterActions)
    }
  ),
  branch(({ apps, filter }) => !apps || !filter.restored, renderNothing),
  withProps(
    ({ filter, }) => ({ ...filter.period, }),
  ),
  withHandlers({
    handleChange:
      ({ updateDatesFilter }) =>
        ([from = null, to = null, preset = null]) => {
          updateDatesFilter({ period: { from, to, preset, } });
        },
    disabledDate:
      () =>
        (day) => day.isAfter(moment()),
  }),
)(({
  isMobileView,
  from,
  to,
  preset,
  disabledDate,
  handleChange,
}) => (
  <Styled isMobileView={isMobileView}>
    <RangePicker
      value={[from, to, preset]}
      presets={[
        PRESETS.TODAY,
        PRESETS.YESTERDAY,
        PRESETS.LAST_7,
        PRESETS.LAST_30,
        PRESETS.LAST_90,
        PRESETS.LAST_180,
        PRESETS.LAST_MONTH,
        PRESETS.THIS_MONTH,
        PRESETS.ALL_TIME,
      ]}
      disabledDate={disabledDate}
      onChange={handleChange}
      allowClear={!isMobileView}
      getCalendarContainer={trigger => trigger.parentNode}
    />
  </Styled>
));

export function stringifyDates(values) {
  if (values.date_preset) {
    if (values.date_preset === PRESETS.ALL_TIME) {
      return {
        ds: values.date_start || undefined,
        dp: values.date_preset || undefined,
      };
    }
    else {
      return {
        dp: values.date_preset || undefined,
      }
    }
  }

  return {
    ds: values.date_start || undefined,
    de: values.date_end || undefined,
  };
}

export function parseDates(s) {
  let r = {
    date_start: moment(s.ds).isValid() ? s.ds || '' : '',
    date_end: moment(s.de).isValid() ? s.de || '' : '',
    date_preset: s.dp || '',
  };

  if (s.dp) {
    if (s.dp === PRESETS.ALL_TIME) {
      r.date_start = moment(s.ds).isValid() ? s.ds || '' : '';
      r.date_end = moment().format('YYYY-MM-DD');
    }
    else if (PRESETS[s.dp]) {
      let p = getPeriodByPreset(s.dp);
      r.date_start = p.from;
      r.date_end = p.to;
    }
  }

  return r;
}

export const preserveFilterDates = (f) => {
  return _.omit(f, 'date_start', 'date_end', 'date_preset');
}

export const restoreFilterDates = () => {
  const { filter } = store.getState();
  return ({
    date_start: filter?.period?.from,
    date_end: filter?.period?.to,
    date_preset: filter?.period?.preset,
  });
}
